.dashboard {
  // padding: 20px 24px 0px 18px;
  font-family: Roboto;
  font-style: normal;
}
.card {
  border-radius: 0px;
}
.card-col {
  padding-right: 7px;
  padding-left: 7px;
}
.card-container {
  // width: 210px;
  position: relative;
  height: 80px;
  border-radius: 12px;
  text-align: center;
  padding-left: 20px;
  cursor: pointer;
  // border: 3px solid transparent;

  .card-top-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    position: absolute;
    left: 15px;
    top: -10px;
    background: #f0f0f0;
    padding: 3px 7px;
    border-radius: 7px;
    line-height: 15px;
    color: #9c7979;
  }
  .card-top-title-black {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000000;
  }
  .card-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-left {
    text-align: left;
    div,
    span {
      text-align: left;
    }
  }
  .card-content {
    .counter {
      font-weight: bold;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.7);
    }
    .card-title {
      font-size: 15px;
      line-height: 18px;
      text-align: left;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.7);
    }
    // .card-title1{
    //     font-size: 15px;
    //     line-height: 18px;
    //     text-align: left;
    //     letter-spacing: 0.01em;
    //     color: rgba(0, 0, 0, 0.7);
    // }
    .see-analysis {
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.01em;
      // color: rgba(113, 111, 111, 0.7);
    }
  }
}
// .network-card{
//     background: #b8bfc1;
//     background: linear-gradient(75deg, #b8bfc1 29%, #b8bfc1 90.3%, #445059 90.6%, #445059 100%);
//     border-top-right-radius: 40px;
//     width: 105%

// }
.network-card.active {
  // border: 3px solid rgba(13,153,70, 0.5);
  //background-image: url('../../images/dashboard/CardBorderSvg.svg');
}

// .lead-card{
//     background: #b8bfc1;
//     background: linear-gradient(75deg, #b8bfc1 29%, #b8bfc1 90.3%, #445059 90.6%, #445059 100%);
//     border-top-right-radius: 40px;
//     width: 105%

// }
.lead-card.active {
  // border: 3px solid rgba(173,5,5, 0.5);
}
// .customer-card{
//     background: #b8bfc1;
//     background: linear-gradient(75deg, #b8bfc1 29%, #b8bfc1 90.3%, #445059 90.6%, #445059 100%);
//     border-top-right-radius: 40px;
//     width: 105%

// }
.customer-card.active {
  // border: 3px solid rgba(126,76,29,0.5);
}
// .ticket-card{
//     background: #b8bfc1;
//     background: linear-gradient(75deg, #b8bfc1 29%, #b8bfc1 90.3%, #445059 90.6%, #445059 100%);
//     border-top-right-radius: 40px;

//     width: 105%

// }
// .ticket-card.active{
//  border: 3px solid rgba(13,153,70, 0.5);
// }
// .payment-card{
//     background: #b8bfc1;
//     background: linear-gradient(75deg, #b8bfc1 29%, #b8bfc1 90.3%, #445059 90.6%, #445059 100%);
//     border-top-right-radius: 40px;
//     width: 105%

// }
.payment-card.active {
  //border: 3px solid rgba(221,177,255, 0.5);
}

.dashboard-content {
  // margin-top:20px;
  padding-right: 50px;
  padding-left: 30px;
  font-family: Roboto;
  font-style: normal;
  color: #000000;
  .card {
    padding: 10px;
  }
  .card .card-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
  }
  .dashboard-content-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    margin: 19px 0px;
  }
  .bandwidth-allocation {
    .device-allocated {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .bandwidth-usage {
    color: #000000;
    .bandwidth-usage-text {
      height: 22px;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
    }
    .bandwidth-usage-avg {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      opacity: 0.6;
      .count {
        font-weight: bold;
        font-size: 24px;
        line-height: 20px;
        color: #000000;
        margin-top: 5px;
        display: inline-block;
      }
    }

    .card .card-header {
      padding: 10px;
      border-bottom: 0px !important;
    }
    .bandwidth-text {
      font-size: 10px;
      line-height: 20px;
      margin: 0px 10px;
    }
    .month-dropdown {
      float: right;
      font-size: 12px;
      line-height: 20px;
      color: #505d6f;
      position: relative;
      img {
        margin-left: 10px;
      }
      select {
        // border: none;
        width: auto;
        display: inline-block;
        // padding: 0px;
        height: auto;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url("../../images/dashboard/dropdown-icon.svg");
        padding: 0.5em;
        border: none;
        margin-right: 5px;
        padding-right: 12px;
      }
    }
  }
  .network-bandwidth-graph {
    .apexcharts-legend {
      height: 22px;
      .apexcharts-legend-series {
        .apexcharts-legend-text {
          margin-left: 32px;
        }
        .apexcharts-legend-marker {
          background: transparent !important;
          width: 0px !important;
        }
      }
      .apexcharts-legend-series[rel="1"] {
        .custom-marker {
          width: 26px;
          height: 8px;
          background-image: url("../../images/dashboard/download-marker.svg");
          display: inline-block;
          margin-bottom: 4px;
        }
      }
      .apexcharts-legend-series[rel="2"] {
        .custom-marker {
          width: 26px;
          height: 8px;
          background-image: url("../../images/dashboard/upload-marker.svg");
          display: inline-block;
          margin-bottom: 4px;
        }
      }
    }
  }
  .title1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: 0.496657px;
    color: #263238;
    height: 22px;
  }
  .device-allocated {
    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      letter-spacing: 0.496657px;
      color: #263238;
    }
    .subtitle {
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.496657px;
      color: #919293;
    }
    .card-header {
      padding: 20px 40px;
      border-bottom: none;
    }
    .card-body {
      padding: 20px 40px;
    }
    .device-allocated-footer {
      .circle {
        width: 13.55px;
        height: 13.55px;
        border-radius: 111.75px;
      }
      .nas-circle {
        background: #b085ff;
      }
      .olt-circle {
        background: #6340c2;
      }
      .dp-circle {
        background: #e289f2;
      }
      .cpe-circle {
        background: #855cf8;
      }
    }
    .allocated-devices-dropdown {
      width: 105px;
      height: 30px;
      float: right;
      color: #5e5e5e;
      .form-control {
        background: #ffffff;
        border: 1px solid #ede2e2;
        box-sizing: border-box;
        border-radius: 8px;
      }
    }
  }
  .device-by-area-map-container {
    .device-by-area-category {
      width: 100px;
      margin: 10px;
    }
    .device-by-area-map {
      display: table;
      width: 100%;
      height: 500px;
    }
    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: #000000;
      padding: 16px 20px;
    }
  }
}
strong {
  font-weight: bold;
}
.month-dropdown {
  float: right;
}
.select-input-box {
  font-size: 12px;
  line-height: 20px;
  color: #505d6f;
  position: relative;
}
.select-input-box select {
  // border: none;
  width: auto;
  display: inline-block;
  // padding: 0px;
  height: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url("../../images/dashboard/dropdown-icon.svg");
  padding: 0.5em;
  border: none;
  margin-right: 5px;
}
.apexcharts-bar-area:hover {
  fill: #c1d7fe;
}

.lead-converted-total {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  .count {
    font-size: 16px;
    line-height: 16px;
  }
}
.rdt_TableHeadRow * {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #000000;
}
.cl-table {
  color: #000000;
}
.leads-generated {
  margin-bottom: 50px;
}
.leads-generated1 {
  margin-bottom: 10px;
}
.lead-converted-by-month-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  margin-top: 6px;
  .count {
    font-size: 18px;
    line-height: 21px;
    margin-right: 5px;
  }
}
.card-new-background {
  background-color: white;
  border-radius: 4px;
  // border-radius: 15px;
  // height: 100px;
  // border-top-left-radius: 10px;
  // border-style: solid;
  // border-width: 0px 33px 100px 0px;
  // border-color: transparent #445059 transparent transparent;
  /* position: absolute; */
}

.card-new-background:before {
}
.card-new {
  // border: 2px solid transparent;
}

.linto {
  color: black;
}
.linto-active {
  color: #1890ff;
  font-weight: 900;
}
.card-new-active {
  // border: 2px solid #9C7979;
  // background-color: white;
  border-bottom: 0;
  position: relative;
  -moz-border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  // border-top-left-radius: 10px;
  // height: 100px;
  // background: linear-gradient(75deg, #f5f5f5 29%, #f5f5f5 90.3%, #445059 90.6%, #445059 100%);
  // border-top-right-radius: 40px;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
}

.card-new-active:after {
  content: "";
  width: 72vw;
  height: 20px;
  // border: 2px solid #9C7979;
  position: absolute;
  bottom: -15px;
  border-top: 0;
  border-left: 0;
  -moz-border-radius: 0 0 15px 0;
  -webkit-border-radius: 0 0 15px 0;
  right: 100%;
}
.card-new-active:before {
  content: "";
  width: 72vw;
  height: 20px;
  // border: 2px solid #9C7979;
  position: absolute;
  bottom: -15px;
  border-top: 0;
  border-right: 0;
  -moz-border-radius: 0 0 0 15px;
  -webkit-border-radius: 0 0 0 15px;
  left: 100%;
}

@media screen and (min-width: 1367px) {
  .card-new-active:before {
    left: 100.1%;
  }
  .card-new-active:after {
    right: 100.1%;
  }
  .network-card .card-new-active:after {
    right: 100.4%;
  }
  //codes
}

@media screen and (max-width: 1242px) {
  .network-card {
    .card-new-active:before {
      left: 100.1%;
    }
    .card-new-active:after {
      right: 100.1%;
    }
  }
  //codes
}

.customerCount {
  font-size: 52px;
  font-weight: 500;
  line-height: 60px;
  font-family: Roboto;
  color: #000000;
}

.green-color {
  color: #30c030;
}
.ticket-category-sub-text {
  color: #666666;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}
.ticket-category-sub-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-top: 24px;
}
.ticket-category-total-ticket {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  .count {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-left: 5px;
  }
}
.customerCardCount {
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
}
.customerCardTitle {
  color: #ffffff;
}
.billPaymentCount {
  font-family: Roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

.customer-dash {
  .customer-left-col {
    width: 62%;
    max-width: 62%;
  }
  .customer-right-col {
    width: 38%;
    max-width: 38%;
    padding-right: 0px;
  }
}
.billpayment-count,
.new-customer-count {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 61px;
  margin-right: 10px;
  align-items: center;
  color: #000000;
}

.billpayment,
.new-customer {
  .card-header {
    border: none;
    padding-bottom: 0px !important;
  }
  .card-body {
    padding-top: 0px !important;
  }
}

.churnGraph
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  display: none;
}
.customer-subtext {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: 0.005em;
  color: #000000;
}
.custom-range-picker {
  background: #f7f7f7 !important;
  border-radius: 8px !important;
  width: 138px;
  .ant-picker-range-separator {
    padding: 0px;
  }
  .ant-picker-input > input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }
}

.ticket-tab-section {
  .ant-tabs-tab-btn {
    color: #000000;
    opacity: 0.6;
    text-shadow: 0 0 0.25px currentColor;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000;
    text-shadow: 0 0 0.25px currentColor;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    opacity: 1;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    width: 0px;
    background: none;
  }
  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 5px;
  }
  .ant-tabs-tab {
    padding-top: 0px;
  }
}
.ticket-card {
  .card-header {
    border: 0px;
    padding-bottom: 0px;
  }
}
.ticket-branch {
  .apexcharts-legend {
    top: 0px !important;
  }
}

.billpayment-count-new span {
  font-size: 52px;
  font-weight: 500;
  line-height: 60px;
  font-family: Roboto;
  color: #000000;
}
.radio_animated {
  cursor: pointer;
}
.ticket-resolution-time-sub {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.496657px;
  color: #919293;
}

.flex-container {
  display: flex;
  flex-direction: row;
}
.flex-justify-space-between {
  justify-content: space-between;
}
.gross-graph {
  margin-bottom: -20px !important;
}
.payment-grossdata-header {
  margin-bottom: 20px;
  .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    color: #000000;
  }
}

.customer-type-customer {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.customer-type-upi {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}
.customer-type-gross {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
}
.customer-type-payment {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-top: 5px;
}
.payment-type-card {
  background: #ffffff;
  border: 2px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 0px;
  padding: 20px !important;
  margin-top: 10px;
}

.payment-type-cards {
  margin: 22px 36px;
  margin-top: -10px;
}
.weekly-transaction-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.7);
}
.card-total-count {
  white-space: nowrap;
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.customers-online-card {
  width: 407px;
  height: 340px;
}
.tooltip-arrow_box {
  padding: 8px;
  background: #c1d7fe;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
}
.payment-grossdata-graph {
  margin-top: 21px;
  .apexcharts-legend {
    width: 80%;

    .apexcharts-legend-series[rel="3"] {
      display: none;
    }
  }
}
.lead-with-categories {
  padding: 2px 10px;
  // max-width: 200px;
}

.apexcharts-toolbar {
  display: none !important;
}
//style for weekly transaction
#weeklytitle {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #000000 !important;
}

.dashboard-icons {
  position: absolute;
  right: 22px;
  background-color: #f1f2f7;
  border-radius: 30px;
  height: 50px;
  width: 46px;
  padding: 10px;
}
.fa-arrow-right {
  //   position: relative;
  //   left: 147px;
  // text-align: end;
  //   top: -23px;
  //   transform: rotateZ(-120deg);
  float: right;
  position: relative;
  top: 3px;
  font-size: 19px !important;
  left: -3px;
  transform: rotate(319deg) !important;
  color: #a4a4a4;
}
.trendIcon{
  float: right;
  position: relative;
  top: 6px;
  font-size: 28px !important;
  left: -41px;
  color: #a4a4a4;
}
.SwitchIcon{
  float: right;
  position: relative;
  top: -3px;
  font-size: 28px !important;
  left: 24px;
  color: #a4a4a4;
}
.switchIconSize{
  font-size: 33px !important;
}
.switchIconSizeEnable{
  font-size: 33px !important;
  color:#4A79E5 !important;
}

.dashboard_spinner{
  margin-left: 49% !important;
  margin-top: 8% !important;
  color: grey !important;
}
.SwitchIconlabel{
  float: right;
  position: relative;
  top: 5px;
  left: -75px;
  font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #a4a4a4;
}
.CloseIcon{
  float: right;
  position: relative;
  top: 6px;
  font-size: 28px !important;
  left: -13px;
  color: #a4a4a4;
}
.total-count {
  color: #666666;
  font-size: 29px;
  position: relative;
  bottom: 17px;
}
.dashboard-name {
  color: #bdbdbd;
  font-weight: 900;
  font-size: 15px;
}
.chip {
  background-color: #53ae62;
  border-radius: 34px;
}
.chip-1 {
  background-color: #f1a042;
  border-radius: 34px;
}
.doughnut-chart {
  width: 53%;
  height: 52.5%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.row2 {
  padding: 62px;
  margin-left: 1px;
  margin-right: 52px;
}
.dash-col {
  position: relative;
  bottom: 60px;
  right: 107px;
  font-weight: bold;
  font-size: 31px;
}
.dash-text {
  position: relative;
  bottom: 44px;
  right: 102px;
  font-size: 20px;
}
.css-10bzl6s-MuiStack-root {
  height: 45px !important;
}
.css-187mznn-MuiSlider-root {
  width: 53% !important;
}
.slider {
  color: grey !important;
  font-size: large !important;
  height: 13px !important;
  position: relative !important;
  left: 52.5%;
}
.css-1gv0vcd-MuiSlider-track {
  background-color: #a6a6a6 !important;
}
.css-14pt78w-MuiSlider-rail {
  background-color: #d0d0d0 !important;
}
.css-eg0mwd-MuiSlider-thumb::after {
  background-color: #6056e9 !important;
  width: 38px !important;
  height: 38px !important;
}
.view {
  position: relative;
  bottom: 40px;
  left: -31px;
}
.col-days {
  padding-right: 46px;
  position: relative;
  left: 1141px;
}
.content-row {
  position: relative;
  // right: -74%;
  //  left:1151px;
  bottom: 20px;
}
// .recharts-layer .recharts-line .lines {
//     font-size: 90px !important;
//     background-color: #30C030 !important;
// }
.dot {
  height: 22px;
  width: 20px;
  background-color: #1d75ff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 1px;
}
.dot1 {
  height: 22px;
  width: 20px;
  background-color: #2dadff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 1px;
}
.label {
  position: relative;
  left: 36px;
  top: -25px;
  // font-size: 16px;
  // color: grey;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  /* Color/5 */

  color: #1e265e;

  opacity: 0.8;
}
.leads-col {
  position: relative;
  top: 9px;
  left: 13px;
}
.payment-col {
  position: relative;
  left: 35px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
  color: #1e265e;
  opacity: 0.8;
}
.payment-col-media {
  position: relative;
  left: 35px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
  color: #1e265e;
  opacity: 0.8;
}
.progress-col {
  position: relative;
  top: 6px;
  left: -17px;
}

.font-col {
  color: #828386;
  font-size: 20px;
  position: relative;
  left: 19px;
  top: -3px;
}
.payment-font-col {
  color: #828386;
  font-size: 20px;
  position: relative;
  left: 19px;
  top: -3px;
}
// .recharts-cartesian-axis-tick-value {
//   font-size: 13px !important;
//   font-family: "Open Sans" !important;
//   font-weight: 400 !important;
// }
.complaints-dot-1 {
  height: 16px;
  width: 16px;
  // background-color:   #6159e9;
  background-color: #4a79e5;

  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
.complaints-dot-9 {
  height: 16px;
  width: 16px;
  // background-color:   #6159e9;
  background-color: #ff8533;

  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
.complaints-dot {
  height: 20px;
  width: 20px;
  background-color: #ff8b7b;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 0px;
}
.complaints-dot-3 {
  height: 16px;
  width: 16px;
  background-color: #00dab5;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
.complaints-dot-2 {
  height: 16px;
  width: 16px;
  background-color: #fccd3a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
.complaints-dot-4 {
  height: 16px;
  width: 15px;
  // background-color:  #efdadd;
  background-color: #f5afb9;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
//deactive circle in customer chart added by Marieya on 22/8/22
.complaints-dot-13 {
  height: 16px;
  width: 15px;
  // background-color:  #efdadd;
  background-color: #c30420;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
//on hold circle in customer chart added by Marieya on 22/8/22
.complaints-dot-14 {
  height: 16px;
  width: 15px;
  // background-color:  #efdadd;
  background-color: #ffe346;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
.complaints-dot-5 {
  height: 16px;
  width: 16px;
  // background-color:  #ffaa00;
  background-color: rgb(166, 166, 166);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
.complaints-dot-6 {
  height: 16px;
  width: 16px;
  background-color: #ff8b7b;
  // background-color: #ff9c99;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}
.complaints-dot-8 {
  height: 20px;
  width: 20px;
  // background-color:  #efdadd;
  background-color: #f5afb9;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 0px;
}
.complaints-dot-7 {
  height: 20px;
  width: 20px;
  background-color: #b4e3bf;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 0px;
}
// .css-1fkm8gk-MuiPaper-root{
//     position: relative !important;
//     right:210% !important;
// }
.hgroup h6 {
  position: relative;
  left: 30px;
  bottom: 23px;
  font-size: 15px;
  font-weight: 400;
  color: #7c7676;
  top: -23px;
  margin: 0px;
}
.hgroup h5 {
  position: relative;
  left: 30px;
  bottom: 23px;
  font-size: 15px;
  font-weight: 100;
  color: #7c7676;
  top: -27px;
}
.hr_complaints {
  box-sizing: content-box;
  height: 0px;
  overflow: visible;
  margin-left: 43px;
  margin-right: 55px;
  position: relative;
  left: -47px;
  border: 1px solid #cdcdcd !important;
}

#progressbar1 {
  background: #eff4fb !important;
  border-radius: 21px;
}

// .css-1thkv6t-MuiLinearProgress-root .MuiLinearProgress-bar{
//     background-color: green !important;
// }
// .css-vj1n65-MuiGrid-root{
//     position: relative !important;
//     left: 41px !important;
//     bottom: 14px !important;
// }
//Css by Marieya
// .recharts-cartesian-axis-tick-line {
//   display: none;
// }
.complaints-status {
  position: relative;
  left: -67px;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
}
@media screen and (max-width: 1586px) and (min-width: 1279px) {
  .payment-col {
    position: relative;

    font-family: "Open Sans";
    font-weight: 500;
    font-size: 14px;
    color: #5f6068;
    font-size: 12px;
  }
  .payment-col-media {
    position: relative;

    font-family: "Open Sans";
    font-weight: 500;
    font-size: 14px;
    color: #5f6068;
    font-size: 12px;
  }
}
@media screen and (max-width: 1586px) and (min-width: 1279px) {
  .font-col {
    position: relative;

    font-family: "Open Sans";
    font-weight: 500;
    font-size: 12px;
    color: #5f6068;
  }
}

@media screen and (max-width: 1586px) and (min-width: 1279px) {
  .hgroup h6 {
    position: relative;
    left: 30px;
    bottom: 23px;
    font-size: 15px;
    font-weight: 400;
    color: #7c7676;
    top: -23px;
    margin: 0px;
  }
  .hgroup h5 {
    position: relative;
    left: 30px;
    bottom: 23px;
    font-size: 12px;
    font-weight: 100;
    color: #7c7676;
    top: -23px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1180px) {
  .payment-col {
    position: relative;
    left: 17px;
  }
  .payment-col-media {
    position: relative;
    left: 17px;
  }
}
.donut-text {
  border-radius: 50%;
  box-shadow: 0 0 50px #ccc;
}
// .donut-text{
//   box-sizing: border-box;

// position: absolute;
// width: 92.7px;
// height: 92.53px;
// left: 263.45px;
// top: 443.22px;

// transform: matrix(-0.97, 0.26, -0.26, -0.97, 0, 0);
// }
//Global Slider css
// .rc-slider-mark-text-active {
//     left: 28.0909% !important;
// }
// .rc-slider{
//     width: 60% !important;
//     height:0px !important;
//     padding: 0px !important;
//     position: relative !important;
//     top: 8px !important;
// }
//  .rc-slider-rail{
//     position: absolute !important;
//     height: 16px !important;
//     width: 158% !important;
//     background-color:
//     #D0D0D0 !important;
//     border-radius: 5px !important;
//  }
//  .rc-slider-mark {
//     //  width: 158% !important;
//  }
//  .rc-slider-track {
// height: 16px !important;
// background-color: #A6A6A6 !important;
//  }
//  .rc-slider-handle {
//     background-color:  #4472de !important;
//     border-radius: 50% !important;
//     box-shadow: none !important;
//     width: 30px !important;
//     height: 30px !important;
//     // border: none !important;

//     opacity: 4.5 !important;
//  }
//  .rc-slider-step {
//     width: 0px !important;
//   }
//   .rc-slider-dot {
//     // border: none !important;
//     // background-color: transparent !important;
//     border: 2px solid #e9e9e9;

//   }

//   .rc-slider-mark-text {
//     position: relative !important;
//     font-family: 'Open Sans' !important;
//     font-weight:600 !important;
//     font-size: 16px !important;
//     font-style: normal !important;
//     top: 16px !important;
//   }
// end
.logo-icon-wrapper {
  width: 76px;
}
.img-fluid {
  max-width: 79%;
  height: 28px;
  position: relative !important;
  top: 5px;
  left: 8px !important;
}
.dashboard-font {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #a4a4a4;
}

// heading

@media (min-width: 700px) and (max-width: 1400px) {
  .dashboard-font {
    font-size: 12px;
  }
}

@media (min-width: 700px) and (max-width: 1400px) {
  .customer_count {
    font-size: 12px;
  }
}

// new slider
.rc-slider-rail {
  width: 91% !important;
  height: 10px !important;
  border-radius: 7px !important;
  background-color: #d0d0d0 !important;
}
.rc-slider-track {
  background-color: #a6a6a6 !important;
}
.rc-slider-track {
  height: 10px !important;
  border-radius: 7px !important;
  //  width:85% !important;
}
.rc-slider-handle {
  // background-color:  #4472de !important;
  background-color: #4a79e5 !important;
  // margin-top: "-8%";
  // background-color: #5e85de !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  width: 20px !important;
  height: 20px !important;
  border: none !important;
  top: 4px;
  opacity: 4.5 !important;
}
.rc-slider-step {
  width: 0% !important;
  height: 0px !important;
  background: none !important;
}
.rc-slider-dot {
  border: none !important;
  background-color: transparent !important;
}

.rc-slider-mark-text {
  position: absolute;
  color: black !important;
  top: 20px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Open Sans" !important;
}
// end
.Britechart {
  position: relative;
  left: 88px;
  top: -5px;
}

.rc-slider-mark {
  position: absolute;
  top: 11px !important;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.donut-container {
  position: relative;
  left:18%;
  // top: -70px;
}
//new
// .css-vj1n65-MuiGrid-root{
//     margin-top: -40px;
// }

// Media Queries for dashboard by

// network chart media query
.network_recharts{
left:7%;
top: 5%;
}
.stats_recharts{
  left:-5%;
    top: 5%;
  }

  @media screen and (max-width: 3000px) and (min-width: 2500px) {
    .stats_recharts {
      .recharts-surface {
        width: 85%;
        height: 100%;
        // padding-left: 50%;
        padding-bottom: 5%;
      }
    }
  
    .payment-chart {
      position: relative;
      top: -55%;
      left: 5%;
    }
  }
@media screen and (max-width: 3000px) and (min-width: 2500px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      height: 100%;
      // padding-left: 50%;
      padding-bottom: 5%;
    }
  }

  .payment-chart {
    position: relative;
    top: -55%;
    left: 5%;
  }
}
@media screen and (max-width: 2500px) and (min-width: 2000px) {
  .stats_recharts {
    .recharts-surface {
      width: 85%;
      height: 100%;
      // padding-left: 10%;
      padding-bottom: 5%;
    }
  }
  .payment-chart {
    position: relative;
    top: -15%;
    left: 5%;
  }
}

@media screen and (max-width: 2500px) and (min-width: 2000px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      height: 100%;
      // padding-left: 10%;
      padding-bottom: 5%;
    }
  }
  .payment-chart {
    position: relative;
    top: -15%;
    left: 5%;
  }
}
@media screen and (max-width: 2000px) and (min-width: 1700px) {
  .stats_recharts {
    .recharts-surface {
      width: 85%;
      height: 100%;
      // padding-left: 10%;
      padding-bottom: 5%;
    }
  }
  .payment-chart {
    position: relative;
    top: -5%;
    left: 5%;
  }
  .leads-chart {
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 2000px) and (min-width: 1700px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      height: 100%;
      // padding-left: 10%;
      padding-bottom: 5%;
    }
  }
  .payment-chart {
    position: relative;
    top: -5%;
    left: 5%;
  }
  .leads-chart {
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 1700px) and (min-width: 1400px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      // height: 100%;
      // padding-left: 5%;
      padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1700px) and (min-width: 1400px) {
  .stats_recharts {
    .recharts-surface {
      width: 85%;
      // height: 100%;
      // padding-left: 5%;
      padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1400px) and (min-width: 1300px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      // height: 100%;
      // margin: 0px;
      padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1400px) and (min-width: 1300px) {
  .stats_recharts {
    .recharts-surface {
      width: 85%;
      // height: 100%;
      // margin: 0px;
      padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      // height: 90%;
      position: relative;
      // left: -13%;
      // margin-left: -14%;
      // padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .stats_recharts {
    .recharts-surface {
      width: 85%;
      // height: 90%;
      position: relative;
      // left: -13%;
      // margin-left: -14%;
      // padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      // height: 90%;
      position: relative;
      left: -15%;
      // margin-left: -24%;
      // padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .stats_recharts {
    .recharts-surface {
      width: 85%;
      // height: 90%;
      position: relative;
      left: -15%;
      // margin-left: -24%;
      // padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1000px) and (min-width: 900px) {
  .network_recharts {
    .recharts-surface {
      width: 85%;
      // height: 80%;
      margin-left: -34%;
      // padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 1000px) and (min-width: 900px) {
  .stats_recharts {
    .recharts-surface {
      width: 85%;
      // height: 80%;
      margin-left: -34%;
      // padding-bottom: 5%;
    }
  }
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .network_recharts {
    .recharts-surface {
      margin-left: -18%;
      // padding-bottom: 5%;
    }
  }
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .stats_recharts {
    .recharts-surface {
      margin-left: -18%;
      // padding-bottom: 5%;
    }
  }
}
@media screen and (max-width) and (min-width) {
  .progress-col {
    position: relative;
    top: 6px;
    left: -10px;
  }
}

@media screen and (max-width: 2880px) and (min-width: 1920px) {
  .font-col {
    color: #828386;
    font-size: 18px;
    position: relative;
    left: 19px;
    top: -3px;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1800px) {
  .font-col {
    color: #828386;
    font-size: 17px;
    position: relative;
    left: 19px;
    top: -3px;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1745px) {
  .font-col {
    color: #828386;
    font-size: 15px;
    position: relative;
    left: 19px;
    top: -3px;
  }
}
@media screen and (max-width: 1745px) and (min-width: 1536px) {
  .font-col {
    color: #828386;
    font-size: 15px;
    position: relative;
    left: 19px;
    top: -3px;
  }
}
@media screen and (max-width: 2133px) and (min-width: 1920px) {
  .payment-col {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
  .payment-col-media {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
}
@media screen and (max-width: 2049px) and (min-width: 1821px) {
  .payment-col {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
  .payment-col-media {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
}
@media screen and (max-width: 1800px) and (min-width: 1745px) {
  .payment-col {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
  .payment-col-media {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
}
@media screen and (max-width: 1745px) and (min-width: 1536px) {
  .payment-col {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
  .payment-col-media {
    position: relative;
    left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #1e265e;
    opacity: 0.8;
  }
}
//    @media screen and (max-width:2880px) and (min-width:1600px) {
//     .payment-col {
//             position: relative;
//             left: 15px;
//             font-family: 'Open Sans';
//             font-style: normal;
//             font-weight: 400;
//             font-size: 18px;
//             line-height: 19px;
//             color: #1E265E;
//             opacity: 0.8;
//     }
//     }
@media screen and (max-width: 1745px) and (min-width: 1536px) {
  .hr_complaints {
    box-sizing: content-box;
    height: 0px;
    overflow: visible;
    margin-left: 43px;
    margin-right: 0px;
    position: relative;
    left: -18px;
    border: 1px solid #cdcdcd !important;
  }
}

#activation_cards{
  margin-top: 7%;
}
.count_cards_dashboard{
box-shadow: none;
background-color: #e9e8fb;
border-radius: 4px;
}
#today_cust{
  padding-left: 25px;
  margin-top: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #a4a4a4;
}
