/**=====================
     62. Responsive CSS Start
==========================**/

/* ========= min and max scss for 1200 to 1366 screen ========= */
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap");
@media screen and (max-width: 1800px) and (min-width: 1660px) {
  .user-status {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 285px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1660px) {
  .photos {
    ul {
      li {
        width: 26%;
      }
    }
  }
  .bar-chart {
    canvas {
      width: 100% !important;
    }
  }
  .chat-box {
    .chat-history {
      .call-icons {
        ul {
          li {
            border: 1px solid $dark-gray;
          }
        }
      }
      .total-time {
        h2 {
          color: $dark-gray;
        }
      }
      .call-content {
        .btn {
          width: auto;
          margin: 0 auto;
        }
        > div {
          height: 545px;
          z-index: 7;
          background-color: rgba(255, 255, 255, 0.75);
          background-blend-mode: overlay;
          width: 100%;
          padding: 30px;
        }
      }
      .receiver-img {
        margin-top: 25px;
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 520px;
    }
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 440px;
        }
      }
    }
    .people-list {
      ul {
        max-height: 450px;
        overflow: auto;
      }
    }
  }
  .call-chat-body {
    .chat-box {
      .people-list {
        ul {
          max-height: 550px;
          overflow: auto;
        }
      }
      overflow: hidden;
      height: 625px;
    }
  }
  .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
    img {
      opacity: 0.7;
    }
  }
}

@media (max-width: 1600px) {
  .page-wrapper {
    &.horizontal-wrapper {
      &.material-type {
        .page-header {
          max-width: 1320px;
        }
        .page-body-wrapper {
          .page-body,
          .sidebar-wrapper {
            max-width: 1320px;
          }
        }
        .left-header {
          .level-menu,
          .mega-menu {
            .nav-link {
              svg {
                margin: 0;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1660px) and (min-width: 1200px) {
  .xl-none {
    display: none;
  }
  .xl-60 {
    max-width: 60%;
    flex: 0 0 60%;
  }
  .xl-50 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-23 {
    max-width: 23%;
    flex: 0 0 23%;
  }
  .xl-25 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .xl-40 {
    max-width: 40%;
    flex: 0 0 40%;
  }
  .xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
}

@media (max-width: 1280px) {
  .btn-group-showcase {
    .col-xl-4 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .img-gallery-set1,
  .img-gallery-set5 {
    display: none;
  }
  .box-layout.page-wrapper.horizontal-wrapper {
    .page-header {
      padding-left: unset;
      max-width: unset;
      left: unset;
    }
    .page-body-wrapper {
      width: unset;
    }
  }
}

@media screen and (max-width: 1580px) and (min-width: 1200px) {
  .docs-buttons,
  .docs-toggles {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1435px) and (min-width: 1200px) {
  .page-wrapper {
    &.modern-type {
      .page-header {
        .nav-right {
          flex: 0 0 60%;
          max-width: 60%;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .page-wrapper {
    &.horizontal-wrapper {
      &.material-type {
        .page-header {
          max-width: 1140px;
        }
        .page-body-wrapper {
          .page-body,
          .sidebar-wrapper {
            max-width: 1140px;
          }
        }
      }
      &.enterprice-type {
        .left-header {
          .level-menu,
          .mega-menu {
            .nav-link {
              svg {
                margin: 0;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .flot-chart-container-small {
    height: 227px;
  }
  .left-header .mega-menu-container {
    min-width: 920px;
  }
  .call-chat-sidebar {
    max-width: 260px;
  }
  .product-box {
    .product-details {
      padding: 15px;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .flot-chart-container {
    height: 130px;
  }
  .custom-card {
    .card-profile {
      img {
        height: 115px;
      }
    }
    .profile-details {
      h4 {
        font-size: 18px;
      }
      h6 {
        margin-bottom: 24px;
      }
    }
    .card-footer {
      > div {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  .crm-activity {
    height: 291px;
    overflow-y: auto;
    .media {
      .media-body {
        .dates {
          display: flex;
        }
      }
    }
  }
  .calender-widget {
    .cal-date {
      width: 95px;
      height: 95px;
      h5 {
        line-height: 1.4;
        padding: 13px;
      }
    }
  }
  // chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 30px;
      height: 30px;
      margin-top: 3px;
      margin-right: 3px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }
      .user-profile {
        .image {
          .avatar {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 420px;
      ul {
        max-height: 376px;
        overflow-y: auto;
      }
    }
    .status-circle {
      top: 4px;
      left: 36px;
    }
  }
  //blog scss
  .blog-box {
    .blog-details-main {
      .blog-bottom-details {
        margin: 20px 10px;
      }
      .blog-social {
        li {
          padding: 0px 10px;
        }
      }
    }
  }
  .blog-bottom-content {
    display: none;
  }
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
    }
  }
  .browser-widget {
    img {
      height: 50px;
    }
  }
  // email-app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        .media {
          display: block;
          .media-size-email {
            width: 100%;
            text-align: center;
            .mr-3 {
              margin-right: 0 !important;
            }
          }
          .media-body {
            text-align: center;
          }
        }
      }
    }
    .email-right-aside {
      .email-body {
        .attachment {
          ul {
            li {
              img {
                width: 73px;
                height: 73px;
              }
            }
          }
        }
        .email-top {
          line-height: 50px;
          .float-right {
            display: none !important;
          }
          h5 {
            padding: 13px 0;
          }
        }
        .inbox {
          max-height: 668px;
          overflow: auto;
          .media {
            display: block;
            .media-size-email {
              width: 100%;
              text-align: center;
              .mr-3 {
                margin-right: 0 !important;
              }
            }
          }
          .media-body {
            text-align: center;
            h6 {
              font-size: 14px;
            }
          }
        }
        .email-compose {
          .cke_contents.cke_reset {
            max-height: 144px;
            width: 99%;
          }
          .compose-border {
            margin: 0;
          }
        }
      }
    }
  }
  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 181px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
}

@media (max-width: 1366px) {
  .left-header {
    padding: 0;
  }
  .page-wrapper {
    &.modern-type {
      .left-header {
        .mega-menu {
          .nav-link {
            svg {
              margin: 0;
            }
          }
        }
      }
      .page-header {
        .horizontal-menu {
          .mega-menu {
            a {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  // product page
  .grid-options {
    ul {
      li {
        &:last-child {
          display: none;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 25%;
        }
      }
    }
  }
  // cart page
  .cart {
    .qty-box {
      width: 40%;
    }
  }
  // alert page
  .alert-dismissible {
    strong {
      & ~ p {
        display: inline;
      }
    }
  }
  // chat page
  .chat-right-aside {
    flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
    .chat {
      .chat-message {
        .smiley-box {
          width: 45px;
        }
      }
    }
  }
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: 364px;
          .chat-user-img {
            margin-top: -30px;
          }
          .message {
            width: 100%;
            padding: 15px;
          }
        }
      }
    }
    .chat-history {
      .receiver-img {
        img {
          height: 150px;
        }
      }
    }
  }
  .call-chat-body {
    .chat-box {
      overflow: hidden;
      height: 565px;
    }
  }
  .calender-widget {
    .cal-desc {
      p {
        max-height: 88px;
        overflow: auto;
      }
    }
  }
  .equal-height-xl {
    height: 517px;
  }
  .authentication-main {
    .auth-innerright {
      background-size: cover;
    }
  }
  .grp-btns {
    display: inline-block;
  }
  .button-builder {
    .button-generator-bx {
      position: relative;
      width: 100%;
    }
  }
  .page-builder {
    .btn-group {
      display: inline-block;
      margin-bottom: 10px;
    }
    .btn-grid {
      margin-bottom: 15px;
      margin-right: 0px;
      border-right: none;
      display: inline-block;
    }
    .ge-canvas.ge-layout-desktop {
      margin-top: 50px;
    }
  }
  //ecommerce dashboard
  .ecommerce-widget {
    .total-num {
      font-size: 23px;
      letter-spacing: 0px;
    }
  }
  .support-ticket-font {
    .total-num {
      font-size: 22px;
    }
  }
  //default dashboard
  .custom-card {
    padding: 15px;
    .card-social {
      li {
        a {
          padding: 12px;
        }
      }
    }
  }
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .speed-chart {
    .chart-container {
      height: 250px;
      margin-top: -40px;
      margin-bottom: 100px;
    }
  }
  .candidcahrt {
    height: 360px;
  }
  //chart widget
  .status-details {
    h3 {
      font-size: 18px;
    }
  }
  .chart-widget-top {
    .total-value {
      font-size: 20px;
    }
  }
  .status-widget {
    .card-body {
      padding: 10px;
    }
  }
  //general widget
  .user-status {
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: unset;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              min-width: unset;
            }
          }
        }
      }
    }
  }
  .browser-widget {
    .media {
      flex-wrap: wrap;
      .media-body {
        h3 {
          font-size: 20px;
        }
        p {
          margin-bottom: 2px;
        }
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .testimonial {
    i {
      font-size: 46px;
    }
    p {
      margin-top: 20px;
    }
  }
  .cal-date-widget .datepicker {
    margin: 0 auto;
    width: unset;
  }
  .company-card {
    padding: 20px;
  }
  .static-top-widget {
    .media-body {
      h3 {
        font-size: 30px;
      }
      .icon-bg {
        right: -21px;
      }
    }
  }
  .widget-joins {
    .media {
      padding: 20px;
      .media-body {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
  .redial-social-widget {
    width: 125px;
    height: 125px;
    i {
      width: 100px;
      height: 100px;
      font-size: 35px;
    }
  }
  //chat
  .chat-body {
    padding: 10px;
  }
  .chat-box {
    .chat-history {
      .call-content {
        .btn {
          font-size: 15px;
        }
      }
      .total-time h2 {
        font-size: 30px;
      }
      .receiver-img {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .call-icons {
        margin-top: 20px;
        margin-bottom: 20px;
        ul {
          li {
            width: 50px;
            height: 50px;
            padding: 7px;
          }
        }
      }
    }
    .user-image {
      width: 45px;
      height: 45px;
    }
    .about {
      margin-top: 0;
    }
    .border-tab.nav-tabs {
      .nav-item {
        a {
          padding: 13px 4px !important;
          color: $semi-dark !important;
          letter-spacing: 0;
          font-size: 12px !important;
          line-height: 6;
        }
      }
    }
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 376px;
          overflow-y: auto;
        }
      }
      .user-profile {
        margin-top: 0;
        .image {
          .avatar {
            img {
              width: 100px;
              height: 100px;
            }
          }
          .icon-wrapper {
            width: 30px;
            height: 30px;
            font-size: 13px;
          }
        }
        .user-content {
          p {
            font-size: 14px;
            margin-bottom: 10px;
          }
          h5 {
            margin: 20px 0;
          }
          hr {
            margin: 20px 0;
          }
        }
        .follow {
          .follow-num {
            font-size: 20px;
          }
        }
      }
    }
  }
  .chat-left-aside {
    .people-list {
      height: 490px;
      ul {
        max-height: 434px;
        overflow-y: auto;
      }
    }
  }
  .status-circle {
    top: 4px;
    left: 37px;
  }
  // mega-menu
  .mega-menu {
    .onhover-show-div {
      left: 164px;
    }
  }
  // image cropper page
  .img-cropper {
    .docs-toggles {
      margin-top: 8px;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .dropdown-basic {
      .dropdown {
        .dropdown-content {
          left: -21px;
        }
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper {
    .dataTables_paginate {
      margin-bottom: 8px;
    }
  }
  // jsgrid-table page
  #batchDelete {
    .jsgrid-grid-header {
      .jsgrid-table {
        .jsgrid-header-row {
          .jsgrid-header-cell {
            &:first-child {
              width: 67px !important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body {
      .jsgrid-table {
        .jsgrid-row {
          .jsgrid-cell {
            &:first-child {
              width: 67px !important;
            }
          }
        }
      }
    }
  }
  // forget password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper {
    &.horizontal-wrapper {
      &.material-type {
        .page-header {
          max-width: 930px;
        }
        .page-body-wrapper {
          .page-body,
          .sidebar-wrapper {
            max-width: 930px;
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .left-header .mega-menu-container .mega-box .svg-icon ~ div h6 {
    display: none;
  }
  .col-xl-7.b-center.bg-size {
    top: 0;
    height: 100vh;
    opacity: 0.5;
    position: fixed;
  }
  .file-spacing {
    padding-right: 15px !important;
  }
  .login-card {
    background: transparent;
  }
  .knowledgebase-bg {
    height: 400px;
  }
  .knowledgebase-search {
    .form-inline {
      width: 400px;
      padding: 7px 45px;
      svg {
        top: 14px;
      }
    }
  }
  .btn-group {
    .btn {
      padding: 0.375rem 1.25rem;
    }
  }
  .left-header {
    .level-menu,
    .mega-menu {
      .nav-link {
        svg {
          margin-right: 0;
        }
        span {
          display: none;
        }
      }
    }
  }
  .link-section > div {
    &.active {
      > h6 {
        &:before {
          content: "\f107";
          transition: content 0.3s ease;
        }
      }
    }
    > h6 {
      position: relative;
      &:before {
        content: "\f105";
        font-family: fontAwesome;
        position: absolute;
        right: 10px;
        font-size: 18px;
        transition: content 0.3s ease;
      }
    }
  }
  .left-header {
    .mega-menu-container {
      position: fixed;
      width: 300px;
      border: 1px solid rgb(238, 238, 238);
      top: 0px;
      height: 100vh;
      left: unset;
      right: -300px;
      z-index: 99;
      transition: all 0.3s ease;
      overflow: scroll;
      min-width: unset;
      &.d-block {
        right: 0;
        transition: all 0.3s ease;
        animation: fadeInRight 0.3s ease-in-out;
      }
      .mega-box {
        flex-basis: auto;
        & + .mega-box {
          border-left: 0px;
          padding-left: 15px;
        }
        .link-section {
          .submenu-title {
            display: flex;
            justify-content: space-between;
          }
          .according-menu {
            display: inline-block;
          }
        }
      }
    }
  }
  .alert-theme {
    max-width: 400px;
  }
  .mega-menu-header {
    .header-wrapper {
      .vertical-mobile-sidebar {
        display: block;
        margin-right: 15px;
      }
    }
  }
  // sidebar css
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          position: unset;
          padding: 0;
          > ul {
            li {
              .submenu {
                li {
                  &:first-child {
                    padding-left: 15px !important;
                  }
                }
              }
            }
          }
        }
        .mobile-sidebar {
          .switch-sm {
            .switch {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
  // kanban board
  .jkanban-container {
    .kanban-container {
      .kanban-board {
        width: 400px;
        .kanban-drag {
          > div {
            > div {
              display: block;
              div[data-testid="lane"] {
                display: block;
                // position: relative;
                // right:27%;
                // top:0%;

              }
            }
          }
        }
      }
    }
  }
  // social app page
  .photos {
    ul {
      li {
        width: auto;
        &:nth-child(3n) {
          margin-right: 15px;
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          position: absolute;
          right: 45px;
          top: -22px;
          li {
            .social-icon {
              width: 40px;
              height: 40px;
              i {
                font-size: 18px;
              }
            }
            color: #fff;
            font-size: 25px;
          }
        }
      }
    }
  }
  .lg-mt-col {
    margin-top: 30px;
  }
  .xl-none {
    display: none;
  }
  .user-status {
    table {
      tbody {
        tr {
          td {
            .d-inline-block {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .lg-mt {
    margin-top: 30px;
  }
  .product-page-details {
    margin-top: 30px;
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-left: 0;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 32%;
        }
      }
    }
  }
  .debit-card {
    order: 1;
  }
  .typography {
    .row {
      div {
        & + div {
          margin-top: 30px;
        }
      }
    }
  }
  .listing {
    .card-body {
      .row {
        > div {
          & + div {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .flot-chart-container {
    height: 200px;
  }
  .img-cropper {
    #putData {
      margin-bottom: 10px;
    }
    .docs-toggles {
      > .dropdown {
        margin-bottom: 0;
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 40px;
        }
        &:nth-child(2) {
          i {
            font-size: 80px;
            margin-left: 90px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 120px;
            margin-top: -75px;
            margin-right: 110px;
          }
        }
      }
    }
  }
  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_paginate {
          & ~ .dataTables_filter {
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .jvector-map-height {
    height: 280px;
  }
  .blog-list {
    .blog-details {
      p {
        margin-bottom: 5px;
      }
    }
  }
  .custom-card {
    .card-profile {
      .card-footer {
        > div {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
  .equal-height-lg {
    min-height: unset !important;
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 435px;
      overflow-x: scroll;
      left: 215px;
    }
    .mega-bg {
      display: none;
    }
    .lg-mt {
      margin-top: 25px;
    }
  }
  .button-builder {
    .button-generator-bx {
      position: relative;
      width: 100%;
    }
  }
  //blog
  .blog-single {
    .comment-box {
      ul {
        .comment-social {
          margin-left: 0;
        }
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    .maintenance-heading {
      margin-top: 30px;
      .headline {
        font-size: 50px;
        margin-top: -15%;
        margin-bottom: 0px;
      }
      .cloud-second {
        margin-top: -25px;
      }
    }
  }
  // general widget
  .user-status {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 278px;
            }
          }
        }
      }
    }
  }
  .testimonial {
    p {
      margin-top: 17px;
    }
  }
  .contact-form {
    padding: 10px;
    .theme-form {
      .form-icon {
        margin-top: -57px;
      }
    }
  }
  .calender-widget {
    .cal-date {
      width: 90px;
      height: 90px;
      h5 {
        font-size: 16px;
        padding: 18px;
      }
    }
    .cal-desc {
      padding: 14px;
      p {
        max-height: 114px;
        overflow-y: auto;
      }
    }
  }
  .browser-widget {
    padding: 0px;
    .media {
      .media-body {
        h4 {
          font-size: 17px;
        }
      }
      .media-img {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  //ecommerce dashboard
  .crm-overall {
    .overall-chart {
      height: 200px;
    }
  }
  //chat
  .chat-menu-icons {
    display: block;
    clear: both;
    width: 100%;
    text-align: right;
  }
  .chat-menu {
    right: 0;
    border-top: 1px solid $light-semi-gray;
    opacity: 0;
    transform: translateY(-30px);
    visibility: hidden;
    top: 120px;
    position: absolute;
    z-index: 9;
    background-color: $white;
    transition: all linear 0.3s;
    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
    }
  }
  .chat-history {
    height: 447px;
  }
  .chat-box {
    .chat-menu {
      .people-list {
        ul.list {
          max-height: 444px;
          overflow-y: auto;
        }
      }
    }
    .btn-lg {
      font-size: 12px;
    }
    .people-list {
      ul {
        height: auto;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-header {
          height: auto;
          .chat-menu-icons li a i {
            font-size: 19px;
          }
        }
        .chat-message {
          margin: 0;
        }
      }
    }
    .chat-history {
      .total-time h2 {
        font-size: 25px;
      }
      .row {
        .col-sm-7 {
          padding-right: 0;
        }
      }
    }
    .border-tab.nav-tabs {
      width: 100%;
      .nav-item {
        margin-right: 5px;
      }
    }
    .chat-left-aside {
      .people-list {
        height: 493px;
      }
    }
    .chat-history {
      .call-content {
        > div {
          padding-top: 70px;
        }
      }
    }
    .toogle-bar {
      display: inline-block;
      margin-right: 0 !important;
    }
  }
  .chat-right-aside {
    flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .call-chat-body {
    .chat-box {
      overflow: auto;
      height: auto;
    }
  }
  // Email app
  .email-wrap {
    .email-right-aside {
      .email-body {
        .b-r-light {
          border-right: none !important;
        }
      }
      .radius-left {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
    .row {
      .col-xl-3 {
        & + .col-xl-3 {
          padding-right: 15px;
        }
      }
      .col-xl-6 {
        padding-left: 15px;
      }
    }
  }
  // icons
  .icon-lists {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 209px;
    }
  }
  // tour page
  .like-comment-lg-mb {
    margin-bottom: 12px;
  }
  // ribbons page
  .ribbon-space-bottom {
    top: 9px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 98.5% !important;
      }
    }
  }
  // jsgrid-table page
  #batchDelete {
    .jsgrid-grid-header {
      .jsgrid-table {
        .jsgrid-header-row {
          .jsgrid-header-cell {
            &:first-child {
              width: 95px !important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body {
      .jsgrid-table {
        .jsgrid-row {
          .jsgrid-cell {
            &:first-child {
              width: 95px !important;
            }
          }
        }
      }
    }
  }
  // user-profile page
  .profile-img-style {
    #aniimated-thumbnials-3 {
      text-align: center;
    }
  }
  // vertical page
  .vertical-menu-mt {
    margin-top: 80px !important;
    .xl-none {
      display: block;
    }
  }
  // calender page
  .calendar-wrap {
    .fc-day-grid-event {
      width: 87%;
    }
  }
}

@media (max-width: 991.98px) {
  // .bg-overlay {
  //   position: fixed;
  //   content: '';
  //   background-color: rgba($black, 0.5);
  //   height: 100%;
  //   width: 100%;
  //   z-index: 1;
  //   right: 0;
  //   left: 0;
  //   top: 0;
  // }
  .search-form {
    .form-group {
      &:before {
        top: 33px;
      }
      &:after {
        top: 35px;
      }
    }
  }
  .mobile-title {
    &.d-none {
      padding: 11px 30px 10px;
    }
  }
  .tab-content .row .col-xl-4 + .col-xl-4 .project-box {
    margin-top: 1.5rem !important;
  }
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        top: 20px !important;
        right: 20px !important;
      }
    }
  }
  .page-wrapper {
    &.modern-type {
      .page-header {
        z-index: 8;
      }
      .simplebar-mask {
        top: 70px;
      }
    }
    &.compact-sidebar {
      .simplebar-mask {
        top: 87px;
      }
      .page-header {
        margin-left: 0;
        width: calc(100% - 0px);
      }
      .page-body-wrapper {
        div.sidebar-wrapper {
          &.close_icon {
            .sidebar-main {
              .sidebar-links {
                li {
                  .sidebar-link {
                    &.active {
                      ~ .sidebar-submenu {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .page-body {
          margin-top: 60px;
        }
      }
    }
    &.only-body {
      .page-header {
        .header-wrapper {
          .search-full {
            .Typeahead-menu {
              top: 65px;
            }
          }
        }
      }
    }
    &.advance-layout {
      .page-body-wrapper {
        .sidebar-wrapper {
          position: fixed !important;
          top: 0 !important;
        }
      }
    }
    .page-header {
      .header-wrapper {
        .nav-right {
          .profile-dropdown {
            top: 50px;
          }
        }
        .search-full {
          input {
            line-height: 50px;
          }
          .form-group {
            .close-search {
              top: 20px;
            }
            &:before {
              top: 18px;
            }
          }
        }
      }
    }
    .current_lang {
      .lang {
        span {
          &.lang-txt {
            display: none;
          }
        }
      }
    }
  }
  .customizer-contain {
    top: 62px;
  }
  .mobile-clock-widget {
    #date {
      margin-top: 20px;
    }
  }
  .m-r-30 {
    margin-right: 20px;
  }
  .card.full-card {
    top: 60px;
    width: calc(100vw - 0px);
  }
  .document-content {
    .page-body {
      margin-top: 90px !important;
    }
    ul {
      &.nav-pills {
        max-height: calc(100vh - 84px);
      }
    }
  }
  .vertical-mobile-sidebar {
    right: 75px;
    top: 18px;
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery {
    figure {
      margin-bottom: 20px;
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 20px;
      }
    }
  }
  .gallery {
    margin-bottom: -20px;
  }
  .my-gallery {
    margin-bottom: -20px;
    figure {
      margin-bottom: 20px;
    }
  }
  .photoswipe-pb-responsive {
    .grid {
      margin-bottom: -20px;
      .grid-item {
        > div,
        li {
          margin-bottom: 20px;
        }
      }
    }
  }
  // timeline page
  .vertical-timeline--one-column {
    .vertical-timeline-element-icon {
      width: 40px;
      height: 40px;
    }
    .vertical-timeline-element-content {
      margin-left: 60px;
    }
    &:before {
      left: 18px !important;
    }
  }
  .vertical-timeline-element-icon {
    svg {
      width: 20px;
      height: 20px;
      left: 57%;
      top: 55%;
    }
  }
  // dropzone
  .dzu-previewContainer {
    padding: 20px !important;
  }
  // datatable css
  table {
    &.dataTable {
      &.fixedHeader-locked,
      &.fixedHeader-floating {
        left: 0 !important;
        width: 100% !important;
      }
    }
  }
  // main header css
  .page-wrapper {
    .section-space {
      padding: 60px 0;
    }
    .card {
      margin-bottom: 30px;
      .card-header,
      .card-body,
      .card-footer {
        padding: 20px;
        .tab-content {
          .m-t-30 {
            margin-top: 20px !important;
          }
          .m-b-30 {
            margin-bottom: 20px !important;
          }
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .footer-fix {
        margin-left: 0px;
        padding-right: 15px;
        width: calc(100% - 0px) !important;
      }
    }
    &.compact-wrapper {
      .page-header {
        margin-left: 0 !important;
        width: calc(100% - 0px) !important;
        .header-wrapper {
          .maximize {
            display: none;
          }
          .header-logo-wrapper {
            display: flex;
          }
          .toggle-sidebar {
            position: unset;
            margin-right: 15px;
            border-right: 1px solid #ddd;
            padding-right: 15px;
            display: block;
            &:before {
              display: none;
            }
          }
          .toggle-nav {
            display: none;
          }
        }
      }
      .page-body-wrapper {
        div.sidebar-wrapper {
          top: 0;
          &.close_icon {
            &:hover {
              transform: translate(-285px);
            }
          }
          .logo-wrapper {
            padding: 17px 30px;
            .back-btn {
              display: inline-block;
              // float: right;
              font-size: 20px;
            }
            .toggle-sidebar {
              display: none;
            }
          }
          .sidebar-main {
            .sidebar-links {
              height: calc(100vh - 95px);
            }
          }
          &.close_icon {
            transform: translate(-285px);
            .sidebar-main {
              .sidebar-links {
                height: unset;
              }
            }
          }
        }
        .page-body {
          margin-top: 63px;
          padding: 0 15px;
        }
      }
    }
    &.compact-sidebar {
      .page-header {
        .header-wrapper {
          .maximize {
            display: none;
          }
        }
      }
    }
    &.modern-type {
      .page-header {
        .header-wrapper {
          .logo-wrapper {
            display: none;
            margin-right: 0;
          }
        }
      }
      .page-body-wrapper {
        div.sidebar-wrapper {
          margin: 0 !important;
          .logo-wrapper {
            display: block;
          }
        }
      }
    }
    &.material-icon {
      .page-header {
        padding-left: 0;
      }
      .page-body-wrapper {
        .page-body {
          padding-left: 15px;
        }
      }
    }
    .page-header {
      .header-wrapper {
        margin: 0;
        padding: 12px 30px;
        .nav-right {
          position: unset;
          padding: 0;
          .notification {
            top: 10px;
          }
        }
        > .mobile-toggle {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .header-logo-wrapper {
          margin-right: 0;
        }
      }
      .header-logo-wrapper {
        .logo-wrapper {
          img {
            margin-top: 0;
          }
        }
      }
    }
  }
  .page-body-wrapper {
    .page-body,
    footer {
      margin-left: 0 !important;
      #customer-review {
        .owl-stage-outer {
          width: 100% !important;
        }
      }
      .calender-widget {
        .cal-desc {
          p {
            max-height: 101px;
          }
        }
      }
    }
  }
  .default-according {
    .card {
      margin-bottom: 10px !important;
      .card-header {
        padding: 12px 20px;
      }
    }
  }
  // general widget page
  .crm-activity {
    > {
      li {
        + li {
          margin-top: 10px;
          padding-top: 10px;
        }
      }
    }
  }
  // login page
  .login-card {
    .login-main {
      &.login-tab {
        margin-top: 90px;
        .nav-tabs {
          flex-direction: unset !important;
          left: 0;
          top: -43px;
          .nav-item {
            .nav-link {
              span {
                padding: 8px;
                right: -16px;
                top: -30px;
                font-size: 13px;
              }
              &:after {
                top: -1px;
                left: 0;
                right: 0;
                margin: 0 auto;
              }
              &:hover {
                span {
                  right: 0;
                  top: -33px;
                }
                &:after {
                  top: -1px;
                  transform: scale(1) rotate(90deg);
                }
              }
            }
          }
        }
        .border-tab {
          &.nav-tabs {
            .nav-item {
              .nav-link {
                border-bottom: 2px solid transparent;
                border-right: none;
                border-top-right-radius: 0;
                &.active,
                &.show,
                &:focus {
                  border-right: none;
                  border-bottom: 2px solid var(--theme-deafult);
                }
              }
            }
          }
        }
      }
    }
  }
  // add post page
  .add-post {
    form {
      .form-group {
        margin-bottom: 20px;
      }
    }
    .dropzone {
      margin-bottom: 20px;
    }
  }
  // avatar page
  .customers {
    &.avatar-group {
      margin-right: 20px;
    }
  }
  //social app page css
  .socialprofile {
    .social-btngroup {
      margin: 20px 0;
    }
    .social-group {
      margin-top: 20px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 20px;
        }
      }
    }
    .media {
      margin-bottom: 20px;
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 20px;
    }
  }
  .social-chat {
    margin-top: 20px;
    .media-body {
      padding: 20px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 20px;
    }
  }
  .social-network {
    span {
      margin-bottom: 20px;
    }
  }
  .details-about {
    + .details-about {
      margin-top: 20px;
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 20px;
      }
    }
  }
  .new-users-social {
    margin-bottom: 20px;
  }
  // helper classes page start
  .helper-classes {
    padding: 20px;
  }
  // helper classes page ends
  .floated-customizer-btn,
  .floated-customizer-panel {
    display: none;
  }
  .page-builder {
    .ge-canvas {
      &.ge-editing {
        .row {
          padding: 20px;
        }
      }
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 24px;
      }
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 20px;
      margin-bottom: 20px;
    }
  }
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 20px;
    }
  }
  .crm-overall {
    margin: 0 -20px -20px;
  }
  .product-page-main {
    padding: 20px;
  }
  .grid-options {
    ul {
      li {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
  .feature-products {
    form {
      .form-group {
        i {
          right: 20px;
        }
      }
    }
  }
  .tabbed-card ul {
    padding: 18px 15px;
  }
  .tabbed-card {
    ul {
      top: -7px;
      right: 0;
      &.border-tab {
        &.nav-tabs {
          padding: 0;
          .nav-item {
            .nav-link {
              padding: 10px 15px;
            }
          }
        }
      }
    }
  }
  .tilt-showcase {
    .tilt-image {
      text-align: center;
    }
    .mt-4 {
      margin-top: 20px !important;
    }
  }
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        top: 20px;
        right: 20px;
      }
    }
  }
  .custom-card {
    padding-bottom: 0;
    .card-footer {
      padding: 0 15px 15px 15px !important;
    }
    .card-header {
      border: none;
    }
    .dashboard-card {
      padding: 0;
    }
    .card-profile {
      img {
        height: 155px;
      }
    }
  }
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.5rem;
  }
  .display-3 {
    font-size: 2.5rem;
  }
  .display-4 {
    font-size: 1.5rem;
  }
  .typography {
    .h1 {
      font-size: 34px;
    }
    .h2 {
      font-size: 30px;
    }
    .h3 {
      font-size: 22px;
    }
    .h4 {
      font-size: 20px;
    }
    .h5 {
      font-size: 18px;
    }
    .h6 {
      font-size: 15px;
    }
  }
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 15px;
  }
  .card-absolute {
    .card-header {
      padding: 10px 15px !important;
    }
  }
  .comingsoon {
    .comingsoon-inner {
      .countdown {
        .time {
          width: 80px;
          height: 80px;
          font-size: 26px;
        }
      }
    }
  }
  .calendar-wrap {
    .basic-calendar {
      .external-events {
        .fc-event {
          & + .fc-event {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .owl-theme {
    .owl-nav {
      &.disabled {
        & + .owl-dots {
          margin-bottom: -10px;
          margin-top: 15px;
        }
      }
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 18px;
      }
      .card-header-right {
        top: 11px;
        right: 15px;
      }
    }
  }
  .page-builder {
    .ge-addRowGroup {
      width: 100%;
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .info {
        .user-designation {
          margin-bottom: 20px;
        }
      }
    }
  }
  //blog
  .blog-single {
    .comment-box {
      .comment-social {
        li {
          padding-left: 15px;
        }
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    padding: 35px 0;
    .sub-content {
      font-size: 14px;
      line-height: 25px;
      margin-top: 0px;
    }
    .error-heading {
      margin-top: 30px;
      .headline {
        font-size: 180px;
        margin-top: 0;
      }
    }
    .maintenance-heading {
      margin-top: 30px;
    }
  }
  //search
  .search-page {
    .info-block {
      flex-wrap: wrap;
    }
    .tab-content {
      .pb-4 {
        padding-bottom: 20px !important;
      }
    }
  }
  //coming soon
  .comingsoon {
    .comingsoon-inner {
      img {
        width: 150px;
      }
    }
  }
  //business dashboard
  .calender-widget {
    .cal-desc {
      padding: 10px 20px 20px !important;
      p {
        max-height: 100px;
      }
    }
  }
  .contact-form {
    padding-top: 10px !important;
    .theme-form {
      padding: 20px;
      margin-top: 27px;
      .form-icon {
        margin-top: -47px;
      }
    }
  }
  //crm dashboard
  .user-status {
    &.product-chart {
      max-height: 518px;
      overflow: auto;
    }
  }
  // general widget
  .widget-joins {
    .media {
      .media-body {
        i {
          float: none !important;
        }
      }
    }
  }
  .xl-none {
    display: block;
  }
  //blog
  .top-radius-blog {
    width: 100%;
  }
  .sm-100-w {
    width: 100%;
    height: 100%;
  }
  /*Form Wizard One start*/
  .form-wizard {
    .btn-mb {
      margin-bottom: 20px;
    }
  }
  /*Form Wizard One ends*/
  /*Form Wizard Two Start*/
  .stepwizard {
    margin-bottom: 20px;
  }
  /*Form Wizard Two Ends*/
  /*Form Wizard Three Start*/
  .f1 {
    .f1-steps {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  /*Form Wizard Three ends*/
  /*Form Wizard Four Start*/
  .wizard-4 {
    ul.anchor {
      padding-right: 20px;
    }
    .msg-box {
      top: 25px !important;
    }
  }
  /*Form Wizard Four ends*/
  // tab material
  .border-tab.nav-tabs {
    margin-bottom: 0px !important;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 20px;
      }
    }
  }
  // support ticket
  .dataTables_wrapper {
    .dataTables_length {
      margin-bottom: 20px;
    }
    table.dataTable {
      margin-bottom: 20px !important;
    }
  }
  // search page
  .search-page {
    .info-block {
      + .info-block {
        padding-top: 20px;
        margin-top: 20px;
      }
    }
    #video-links {
      .embed-responsive + .embed-responsive {
        margin-top: 20px;
      }
    }
  }
  .lg-mt {
    margin-top: 20px;
  }
  .gallery-with-description {
    margin-bottom: -20px;
    a {
      > div {
        margin-bottom: 20px;
      }
    }
  }
  // clipboard
  .clipboaard-container {
    p {
      margin-bottom: 8px;
    }
  }
  // summer-note
  .m-b-30 {
    margin-bottom: 20px !important;
  }
  // email app
  .email-wrap {
    .email-left-aside {
      .email-app-sidebar {
        ul {
          li {
            hr {
              margin: 13px 0;
            }
          }
        }
      }
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 20px;
  }
  .navs-icon {
    padding: 20px;
    .main-section {
      padding-top: 20px;
    }
    .separator {
      margin: 20px 0;
    }
  }
  .nav-list {
    padding: 20px;
  }
  .navs-dropdown {
    button {
      margin-top: 20px;
    }
    .onhover-show-div {
      top: 68px;
    }
  }
  // animated modal page
  .animated-modal {
    .form-group {
      width: 100%;
      display: block;
    }
    .mr-4 {
      margin-right: 0 !important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 20px;
  }
  // button group page
  .btn-group-wrapper {
    .m-b-30 {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  // datatable advance page
  #advance-5_wrapper {
    .dataTables_paginate {
      margin-bottom: 20px;
    }
  }
  // summer-note page
  .click2edit {
    & ~ .note-editor {
      &.note-frame {
        margin-bottom: 20px;
      }
    }
  }
  // call-chat page
  .chat-box {
    .chat-left-aside {
      .people-list {
        height: 483px;
      }
    }
  }
  .card {
    .card-body {
      form {
        &.animated-modal {
          display: flex;
          .animated-modal-md-mb {
            margin-bottom: 20px !important;
          }
        }
      }
    }
  }
  .wizard-4 {
    .msg-box {
      top: 40px;
      right: 0%;
      bottom: unset;
      left: unset;
    }
  }
  .theme-form {
    &.form-inline {
      display: flex;
    }
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .page-wrapper {
    &.compact-wrapper {
      &.material-type {
        .page-header {
          width: calc(100% - 60px) !important;
          margin: 50px auto 0 !important;
        }
        .page-body-wrapper {
          margin: 0 30px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .page-wrapper {
    &.compact-wrapper,
    &.compact-sidebar {
      .left-header {
        position: unset;
        .mega-menu {
          position: absolute !important;
          right: 30px;
          top: 13px;
          .nav-link {
            min-width: auto;
            background-color: transparent;
            border-left: 1px solid #ddd;
            padding-right: 0;
            border-radius: 0;
          }
          svg {
            margin-right: 0;
            height: 20px;
            stroke: $dark-body-background;
          }
          span {
            display: none;
          }
        }
      }
    }
    &.compact-wrapper {
      .nav-right {
        .nav-menus {
          margin-right: 47px;
        }
      }
    }
    &.material-type {
      &::before {
        display: none;
      }
      .page-header {
        margin-top: 0;
        border-radius: 0;
      }
      .page-body-wrapper {
        .page-body {
          margin-top: 0 !important;
        }
      }
    }
    &.only-body {
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul {
              > li {
                margin-right: -2px;
              }
            }
            .nav-menus {
              margin-right: -10px;
            }
          }
        }
      }
    }
    .nav-right {
      .nav-menus {
        margin-right: 60px;
      }
    }
  }
  .profile-media {
    .media-body {
      display: none;
    }
  }
  .form-inline {
    .form-group:not(.form-control-search) {
      width: 100%;
      + .form-group {
        margin-top: 10px;
      }
      input {
        width: 100%;
      }
    }
  }
  .credit-card {
    .text-center {
      img {
        margin-bottom: 20px;
      }
    }
  }
  .starter-kit-fix {
    .page-body {
      margin-bottom: 78px;
    }
  }
  .page-header {
    .header-wrapper {
      .left-header {
        ul {
          li {
            .search-form {
              &.form-inline {
                display: flex;
              }
              input {
                width: 77% !important;
              }
            }
          }
        }
      }
    }
  }
  .blog-box {
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 12px;
        }
      }
    }
    .blog-details {
      .blog-social {
        li {
          &:first-child {
            padding-right: 25px;
          }
          + li {
            padding-left: 25px;
          }
        }
      }
    }
  }
  .footer-copyright {
    ~ div {
      text-align: center;
      p {
        float: none;
        margin-top: 5px;
      }
    }
  }
  // social app page css
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 25px;
          top: -19px;
          li {
            .social-icon {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
    }
  }
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
  }
  // timepicker
  .time_picker_wrapper2 {
    display: block;
  }
  // icon-compact layout page
  .my-gallery {
    figure {
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 20px;
      }
    }
  }
  // chat
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-message {
          .text-box {
            .btn {
              font-size: 0;
              &:before {
                position: absolute;
                content: "\e661";
                font-family: $font-themify;
                font-size: 18px;
                top: 11px;
                left: 20px;
              }
            }
          }
        }
      }
    }
    .border-tab {
      &.nav-tabs {
        display: flex;
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          a {
            height: 50px;
          }
        }
      }
    }
  }
  .clockpicker-align-top {
    left: 55px !important;
    top: 470px !important;
    .arrow {
      display: none;
    }
  }
  .form-builder-column {
    .form-body {
      min-height: 250px;
    }
  }
  .form-builder-2-header {
    flex-wrap: wrap;
    > div {
      width: 100%;
      margin-top: 10px;
      nav {
        float: none;
      }
    }
  }
  // form-builder
  .lg-mt-col {
    margin-top: 20px;
  }
  .form-builder {
    .drag-box {
      fieldset {
        padding: 20px;
        margin-top: 20px;
      }
    }
    #components {
      margin-top: 20px;
    }
    .component {
      label {
        padding-top: 0px;
      }
    }
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 415px;
      left: 0;
      top: 61px;
      padding: 20px;
    }
    .lg-mt {
      margin-top: 15px;
    }
  }
  .page-builder {
    .ge-canvas {
      &.ge-editing {
        .ge-tools-drawer {
          margin: 10px 0 0 10px;
          > a {
            padding: 0 5px;
          }
        }
      }
    }
  }
  .reader {
    margin-top: 15px;
    height: auto !important;
  }
  .range-slider {
    &.theme-form {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .note-editor {
    .btn-group {
      .btn {
        font-size: 11px;
      }
    }
  }
  .user-status {
    tbody {
      tr {
        td {
          min-width: 284px;
          & + td {
            min-width: unset;
            & + td {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
  .text-md-right {
    text-align: right;
  }
  .star-ratings {
    .stars {
      padding: 0;
    }
  }
  .form-inline {
    &.d-inline-block {
      display: block !important;
      width: 100%;
      .form-control {
        margin-bottom: 10px;
      }
    }
  }
  .reset-password-box {
    width: 500px;
    margin: 0 auto;
    .card {
      padding: 20px;
    }
  }
  .custom-card {
    .card-profile {
      img {
        height: 136px;
        bottom: 0;
      }
    }
  }
  .error-wrapper {
    .maintenance-icons {
      li {
        i {
          color: $light-color;
          font-size: 30px;
        }
        &:nth-child(2) {
          i {
            font-size: 60px;
            margin-top: -10px;
            margin-left: 70px;
          }
        }
        &:nth-child(3) {
          i {
            font-size: 90px;
            margin-right: 80px;
          }
        }
      }
    }
  }
  .authentication-main {
    .auth-innerright {
      .social-media {
        li {
          font-size: 14px;
        }
      }
      min-height: unset;
      padding: 0;
    }
  }
  .loader-box {
    justify-content: center;
  }
  .dataTables_wrapper {
    .dataTables_length {
      margin-bottom: 20px;
    }
    .dataTables_filter {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  div {
    &.dataTables_wrapper {
      div {
        &.dataTables_info {
          padding-top: 0;
        }
        &.dataTables_length,
        &.dataTables_filter {
          label {
            float: none;
          }
        }
        &.dataTables_paginate {
          float: none;
          margin-top: 20px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
  #external-events {
    position: relative;
    margin-bottom: 20px;
  }
  .rbc-toolbar {
    display: block !important;
    margin: 0 auto;
    text-align: center;
    .rbc-btn-group {
      display: block;
      white-space: unset;
      &:first-child {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-top: 10px;
      }
    }
  }
  .fc .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-center {
      padding: 10px 0;
    }
    .fc-toolbar-title {
      margin-bottom: 5px;
    }
  }
  .calendar-wrap {
    .fc-basic-view {
      .fc-day-number {
        padding: 0 0px !important;
      }
    }
    .fc-events-container {
      margin-top: 0px;
    }
    .fc-events-container {
      h6,
      .checkbox {
        text-align: right;
      }
    }
    .checkbox {
      label {
        margin-top: 0;
      }
    }
    .fc {
      .fc-toolbar {
        display: block !important;
      }
      button {
        font-size: 14px !important;
        padding: 0 5px !important;
      }
      .fc-left {
        margin-bottom: 10px;
      }
      .fc-toolbar {
        > div {
          display: block !important;
          float: none !important;
        }
        > * {
          :first-child {
            font-size: 18px;
          }
          > * {
            float: none !important;
          }
        }
      }
      margin-top: 0px;
    }
  }
  .btn-group-wrapper {
    text-align: center;
    .m-b-30 {
      margin-bottom: 15px !important;
    }
  }
  .btn-group-showcase {
    text-align: center;
    .btn-group {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  .btn-group {
    .btn {
      font-size: 14px;
      padding: 0.375rem 1.3rem;
    }
  }
  .animate-widget {
    .text-center {
      display: none;
    }
  }
  //general widget page
  .calender-widget {
    .cal-desc {
      p {
        max-height: 100%;
      }
    }
  }
  .user-status {
    &.product-chart {
      max-height: 100%;
    }
    table {
      thead {
        tr {
          th {
            &:nth-child(3) {
              min-width: 124px;
            }
          }
        }
      }
    }
  }
  //mega menu
  .mega-menu {
    .onhover-show-div {
      height: 350px;
    }
  }
  .page-builder {
    .ge-canvas {
      .column {
        padding: 0 !important;
      }
    }
    .ge-content-type-ckeditor {
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
    }
    .ge-row-icon {
      display: none !important;
    }
  }
  /* font-awesom icon page */
  .icon-hover-bottom {
    .form-group {
      input {
        min-width: 270px;
      }
    }
  }
  //user profile
  .user-profile {
    .hovercard {
      .cardheader {
        height: 350px;
      }
      .info {
        padding: 35px;
        .ttl-info {
          margin-bottom: 20px;
        }
        .ttl-sm-mb-0 {
          margin-bottom: 0;
        }
      }
    }
  }
  //calendar
  .calendar-wrap {
    .fc {
      button {
        padding: 5px;
      }
    }
    .fc-day-grid-event {
      margin: 0;
      padding: 5px;
      width: inherit;
    }
    .fc-toolbar {
      h2 {
        font-size: 20px;
        line-height: 35px;
        font-weight: bold;
      }
    }
    .basic-calendar {
      .external-events {
        margin-top: 0px;
      }
    }
  }
  //blog
  .blog-single {
    .comment-box {
      .media {
        h6 {
          margin-bottom: 10px;
        }
        img {
          margin-right: 30px;
        }
      }
      .comment-social {
        margin-bottom: 5px;
        li:first-child {
          padding-left: 0;
          padding-right: 15px;
        }
      }
    }
  }
  //material tab
  .tabbed-card {
    ul {
      &.border-tab {
        margin-bottom: 15px;
        &.nav-tabs {
          top: unset;
          .nav-item {
            .nav-link {
              padding: 5px 15px 10px;
            }
          }
        }
      }
      padding: 10px 0 5px 0;
      position: relative;
      width: 100%;
      left: 0;
    }
  }
  //search
  .search-page {
    #image-links {
      .info-block {
        &.m-t-30 {
          margin-top: 0 !important;
        }
      }
    }
  }
  //authentication
  .authentication-main {
    .authentication-box {
      width: 100%;
    }
    .auth-innerright {
      display: inherit;
    }
  }
  //default dashboard
  .map-chart {
    .chart-container {
      height: 300px;
    }
  }
  .candidcahrt {
    height: 250px;
  }
  //chart widget
  .bar-chart-widget {
    .earning-details {
      i {
        right: 0;
      }
    }
  }
  .serial-chart .chart-container {
    height: 300px;
  }
  .bottom-content {
    padding: 10px;
    p {
      font-size: 12px;
    }
  }
  // from wizard two
  .stepwizard {
    .stepwizard-row {
      &:before {
        top: 17px;
      }
    }
  }
  // icons
  .icon-hover-bottom {
    .icon-popup {
      display: block;
      > .icon-last {
        display: block;
        margin-top: 15px;
      }
      div {
        .flag-icon {
          width: 30px;
          height: auto;
        }
      }
    }
    .form-group {
      input {
        display: block;
        width: unset;
      }
    }
    .btn {
      margin-top: 15px;
    }
  }
  //footer
  .footer-links {
    text-align: center;
  }
  .footer-copyright {
    text-align: center !important;
  }
  .jvector-map-height {
    height: 150px;
  }
  .sm-left-text {
    text-align: left !important;
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-top {
          .d-flex {
            padding-bottom: 0;
          }
          .float-right {
            float: left !important;
          }
        }
        .email-wrapper {
          .attachment {
            text-align: center;
            ul {
              li {
                &:last-child {
                  padding-right: 0;
                }
                img {
                  width: 140px;
                  height: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
  // alert page
  .alert-dismissible {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 294px;
    }
  }
  // button group page
  .button-group-mb-sm {
    margin-bottom: 5px;
  }
  // table components page
  .card-block {
    .table-responsive {
      .table {
        tbody {
          tr {
            td {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100px;
              }
            }
          }
        }
      }
      .progress-content {
        tbody {
          tr {
            td {
              span {
                text-overflow: ellipsis;
                white-space: normal;
                max-width: 50px;
              }
            }
          }
        }
      }
      .checkbox-td-width,
      .radio-first-col-width {
        tbody {
          tr {
            td {
              min-width: 200px !important;
            }
          }
        }
      }
    }
  }
  // jsgrid-table page
  #batchDelete {
    .jsgrid-grid-header {
      .jsgrid-table {
        .jsgrid-header-row {
          .jsgrid-header-cell {
            &:first-child {
              width: 119px !important;
            }
          }
        }
      }
    }
    .jsgrid-grid-body {
      .jsgrid-table {
        .jsgrid-row {
          .jsgrid-cell {
            &:first-child {
              width: 119px !important;
            }
          }
        }
      }
    }
  }
  .jsgrid {
    .jsgrid-pager-container {
      text-align: center;
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      .chat {
        .chat-msg-box {
          height: auto;
        }
      }
    }
    .chat-menu {
      .nav-tabs {
        .nav-item {
          width: 32.33%;
        }
      }
      .people-list {
        ul.list {
          max-height: 433px;
          margin-bottom: 15px;
        }
      }
      #info-profile,
      #info-contact {
        margin-bottom: 15px;
      }
    }
  }
  // user-profile page
  .hovercard {
    .info {
      .follow {
        .text-md-right {
          text-align: center;
        }
      }
    }
  }
  .profile-img-style {
    .like-comment-sm-mb {
      margin-bottom: 20px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 15px !important;
        }
      }
    }
  }
  // gallery page
  .lg-outer {
    .lg-actions .lg-next,
    .lg-actions .lg-prev {
      top: 48%;
    }
  }
  // product page
  .feature-products {
    .filter-toggle {
      padding: 8px 15px;
      background-color: $white;
      margin-left: 10px;
      border-radius: 5px;
    }
    .select2-drpdwn-product {
      float: left;
      margin-right: 10px;
      margin-top: 8px;
    }
    span {
      &.f-w-600 {
        padding-top: 15px;
        display: inline-block;
      }
    }
    .text-right {
      text-align: left !important;
    }
  }
  .d-none-productlist {
    display: block;
    margin-right: 10px;
  }
  .grid-options {
    ul {
      li {
        display: none;
      }
    }
  }
  .product-sidebar {
    transform: translateX(-300px) scaleX(0);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 0;
    position: absolute;
    z-index: 1;
    width: 300px;
    &.open {
      transform: translateX(0px) scaleX(1);
      box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
      visibility: visible;
      opacity: 1;
      height: auto;
      top: -45px;
      // width: 500px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid $transparent-color;
        border-right: 7px solid $transparent-color;
        border-bottom: 7px solid $white;
        right: 120px;
        top: -7px;
      }
      .filter-section {
        .card {
          .left-filter {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .filter-section {
      .card-header {
        display: none;
      }
    }
  }
  .product-wrapper {
    &.sidebaron {
      .product-grid {
        .product-wrapper-grid {
          margin-left: 0;
        }
      }
      .product-sidebar {
        .filter-section {
          .card {
            .left-filter {
              top: unset;
            }
          }
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-img {
          width: 42%;
        }
      }
    }
  }
  .product-filter {
    .product-box {
      flex-wrap: nowrap;
      .product-img {
        width: 100px;
      }
      .product-details {
        padding: 0 !important;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .knowledgebase-bg {
    height: 250px;
  }
  .login-card {
    .login-main {
      .theme-form {
        .link {
          position: relative;
          top: unset;
          right: unset;
        }
        .or {
          &:before {
            width: 50%;
          }
        }
      }
    }
  }
  .knowledgebase-search {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba($white, 0.7);
    .form-inline {
      width: 90%;
      padding: 3px 45px;
      svg {
        top: 10px;
      }
    }
    > div {
      width: 100%;
      text-align: center;
      padding: 15px;
      border-right: 10px;
      h3 {
        font-size: 20px;
        margin-bottom: 0;
      }
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  // dropzone
  .dzu-previewContainer {
    padding: 15px !important;
  }
  // apex chart
  #donutchart,
  #piechart {
    .apexcharts-legend {
      display: none;
    }
  }
  // datatable
  .rdt_TableHeader {
    > div {
      &:last-child {
        > div {
          font-size: 14px;
        }
      }
    }
  }
  // upload page
  .fileContainer {
    .uploadPictureContainer {
      width: 100% !important;
      margin: 3% !important;
    }
    .deleteImage {
      font-size: 13px !important;
      line-height: 20px !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
  // list css
  .tab-javascript {
    .nav-pills {
      text-align: left;
    }
    margin-bottom: 15px;
  }
  // todo css
  .todo {
    .action-box {
      &.large {
        height: 25px !important;
        width: 25px !important;
        .icon {
          font-size: 14px !important;
          vertical-align: -3px !important;
        }
      }
    }
    .todo-list-wrapper {
      #todo-list {
        li {
          .task-container {
            .task-label {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }
  // Login page
  .login-card {
    padding-top: 40px;
    padding-bottom: 40px;
    .logo {
      margin-bottom: 10px;
    }
    .login-main {
      width: 280px;
      padding: 20px;
      .theme-form {
        .form-group {
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 5px;
        }
      }
      &.login-tab {
        .tab-content {
          height: 495px;
        }
        .auth-content {
          img {
            height: 210px;
          }
        }
      }
    }
    .btn-showcase {
      .btn {
        margin-right: 7px;
        padding: 10px 13px;
        + .btn {
          margin-left: 0;
        }
      }
    }
  }
  .bookmark {
    ul {
      li {
        a {
          svg {
            height: 16px;
            width: 16px;
          }
        }
        & + li {
          margin-left: 5px;
        }
      }
    }
  }
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 4px;
  }
  .left-header {
    .level-menu {
      .nav-link {
        background-color: transparent;
        padding: 0.5rem 0;
        margin: 0;
      }
    }
  }
  .page-header {
    .header-wrapper {
      .nav-right {
        .cart-box,
        .notification-box {
          .badge {
            font-size: 10px;
          }
        }
      }
      .left-header {
        ul {
          li {
            .search-form {
              .form-control-plaintext {
                top: 40px;
                position: absolute;
                transition: all linear 0.3s;
                left: -14px;
                background-color: $white;
                border: 1px solid var(--theme-deafult);
                transform: translateY(-35px) scaleY(0);
                opacity: 0;
                visibility: hidden;
                width: 180px !important;
                padding: 10px 10px 10px 15px;
                &.open {
                  transform: translateY(0px) scaleY(1);
                  opacity: 1;
                  visibility: visible;
                  transition: all linear 0.3s;
                  z-index: 2;
                }
              }
            }
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-title > .row {
        .col-6 {
          &:first-child {
            display: block;
            h3 {
              padding-right: 0;
            }
          }
        }
      }
    }
    .page-header {
      .header-wrapper .nav-right ul {
        li {
          i {
            font-size: 14px !important;
          }
          svg {
            width: 14px;
          }
        }
      }
    }
    .profile-nav {
      // display: none !important;
      margin-top: 8px !important;
      width: 31px !important;
      height: 25px !important;
      
    }
    &.compact-wrapper,
    &.compact-sidebar {
      .left-header .mega-menu {
        top: 17px;
        .nav-link {
          padding-left: 10px;
          padding-top: 3px;
          padding-bottom: 3px;
        }
        right: 15px;
        padding-top: 0;
      }
      .page-header {
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
          .toggle-sidebar {
            margin-left: 0;
            padding-left: 0;
            border-left: none;
            border-right: 1px solid #ddd;
            padding-right: 8px;
            margin-right: 8px;
            svg {
              width: 18px;
            }
          }
        }
      }
    }
    &.compact-sidebar {
      .sidebar-wrapper {
        .back-btn {
          display: none;
        }
        .sidebar-main {
          .sidebar-links {
            > li {
              margin-right: 0;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        .breadcrumb {
          margin-top: 0;
        }
      }
    }
    .nav-right .nav-menus {
      margin-right: 45px;
    }
    &.compact-wrapper {
      .left-header .mega-menu {
        top: 17px;
        .nav-link {
          padding-left: 10px;
          padding-top: 3px;
          padding-bottom: 3px;
        }
        right: 15px;
        padding-top: 0;
      }
      .page-header {
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
          .toggle-sidebar {
            margin-left: 0;
            padding-left: 0;
            border-left: none;
            border-right: 1px solid #ddd;
            padding-right: 8px;
            margin-right: 8px;
            svg {
              width: 18px;
            }
          }
        }
      }
    }
    .page-header {
      .header-wrapper {
        padding: 12px 15px !important;
        .nav-right {
          > ul {
            top: 58px;
            .search-form {
              i {
                display: none;
              }
            }
          }
          &.right-header {
            ul {
              li {
                &.px-0 {
                  padding: 6px !important;
                }
              }
            }
          }
          > ul {
            li {
              margin-right: 1px;
              .flag-icon {
                font-size: 14px !important;
              }
              .profile-media {
                img {
                  width: 25px;
                  height: 25px;
                }
              }
              &.onhover-dropdown {
                ul {
                  li {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
          .notification-dropdown {
            &.onhover-show-div {
              &:before,
              &:after {
                left: 24px !important;
                right: unset !important;
              }
            }
          }
          .chat-dropdown {
            left: -52px;
            width: 300px;
            &:after,
            &:before {
              left: 58px !important;
            }
          }
        }
      }
    }
    .page-body-wrapper {
      .page-title {
        .btn-group {
          .btn {
            padding: 8px 30px;
          }
        }
      }
    }
    &.compact-wrapper {
      .page-body-wrapper {
        .page-body {
          margin-top: 60px;
        }
        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              height: calc(100vh - 79px);
            }
          }
        }
      }
    }
  }
  .alert-dismissible {
    p {
      max-width: 209px;
    }
  }
  .customizer-links {
    display: none;
  }
  .m-r-30 {
    margin-right: 15px;
  }
  // general widget page css
  .mobile-clock-widget {
    #date {
      margin-top: 15px;
    }
  }
  .social-widget-card {
    .b-b-light {
      padding: 15px;
      margin-bottom: 15px;
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        top: 34px;
      }
    }
  }
  .cal-date-widget {
    .cal-datepicker {
      margin-top: 15px;
    }
  }
  .static-top-widget {
    .media-body {
      .icon-bg {
        width: 70px;
        height: 70px;
      }
    }
  }
  // Add post page css
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-right: 15px;
        }
      }
      .form-group {
        margin-bottom: 15px;
      }
    }
    .dropzone {
      margin-bottom: 15px;
    }
  }
  //form-wizard-four page css
  .wizard-4 {
    .msg-box {
      top: 10px !important;
    }
  }
  // avatar page css
  .customers {
    &.avatar-group {
      margin-right: 15px;
    }
  }
  .vertical-mobile-sidebar {
    top: 19px;
  }
  //notify index page
  .alert-theme {
    i {
      margin-right: 10px !important;
    }
    button {
      top: 2px !important;
    }
  }
  .chart-widgets-small {
    .chart-container {
      height: 282px !important;
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -30px;
      }
    }
  }
  .widget-joins {
    &:before {
      display: none;
    }
    .pl-0 {
      padding-left: 15px !important;
    }
    .border-after-xs {
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 94%;
        background-color: $light-semi-gray;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .clockpicker-align-top {
    left: 45px !important;
    top: 406px !important;
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery {
    figure {
      margin-bottom: 15px;
      &:nth-child(9),
      &:nth-child(10) {
        margin-bottom: 15px;
      }
    }
    &.gallery-with-description {
      figure {
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  #aniimated-thumbnials {
    figure {
      &:nth-child(12),
      &:nth-child(11),
      &:nth-child(10),
      &:nth-child(9) {
        margin-bottom: 15px;
      }
    }
  }
  .gallery {
    margin-bottom: -15px;
  }
  .my-gallery {
    margin-bottom: -15px;
    figure {
      margin-bottom: 15px;
    }
  }
  .photoswipe-pb-responsive {
    .grid {
      margin-bottom: -15px;
      .grid-item {
        > div,
        li {
          margin-bottom: 15px;
        }
      }
    }
  }
  // social app page css
  .avatar-showcase {
    .pepole-knows {
      text-align: center;
      ul {
        li {
          margin-right: 14px;
        }
      }
    }
  }
  .social-app-profile {
    .hovercard {
      .user-image {
        .avatar {
          margin-top: -56px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .icon-wrapper {
          left: 52%;
          top: 10px;
          height: 30px;
          width: 30px;
          font-size: 15px;
          &:hover {
            font-size: 12px;
          }
        }
        .share-icons {
          right: 15px;
          li {
            margin-right: 0px;
          }
        }
      }
      .info {
        .tabs-scoial {
          .user-designation {
            border-top: none;
            border-bottom: none;
            margin-bottom: 0;
            padding: 0px 0px;
          }
        }
      }
    }
    .tabs-scoial {
      margin-bottom: 0 !important;
    }
  }
  .socialprofile {
    .social-btngroup {
      margin: 15px 0;
    }
    .social-group {
      margin-top: 15px;
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          margin-bottom: 15px;
        }
      }
    }
    .media {
      margin-bottom: 15px;
    }
  }
  .new-users-social {
    margin-bottom: 15px;
  }
  .tabs-scoial {
    position: relative;
    padding-top: 80px;
    li {
      &:nth-child(3) {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px;
            height: 60px;
          }
        }
      }
    }
  }
  .timeline-content {
    p,
    .comments-box {
      margin-top: 15px;
    }
    .comments-box {
      .media {
        .m-r-20 {
          margin-right: 10px;
        }
        .media-body {
          .input-group {
            .input-group-append {
              .btn {
                padding: 6px;
              }
            }
          }
        }
      }
    }
    .comment-number {
      i {
        margin-right: 15px;
      }
    }
  }
  #cd-timeline {
    margin-right: 0;
  }
  .social-chat {
    margin-top: 15px;
    .media-body {
      padding: 15px;
    }
    .your-msg,
    .other-msg {
      margin-bottom: 15px;
    }
  }
  .social-network {
    span {
      margin-bottom: 15px;
    }
  }
  .social-list {
    .media {
      margin-bottom: 15px;
    }
  }
  .details-about + .details-about {
    margin-top: 15px;
  }
  .your-details-xs {
    margin-top: 15px;
  }
  .social-header {
    h5 {
      span.pull-right {
        float: right !important;
        margin-top: 0 !important;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .activity-log {
    .my-activity {
      + .my-activity {
        margin-top: 15px;
      }
    }
  }
  .photos {
    ul {
      li {
        width: 26%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  // helper classes page start
  .helper-classes {
    padding: 15px;
  }
  // helper classes page ends
  .form-builder-2-header {
    ul {
      li {
        text-align: left;
      }
    }
    .form-inline {
      display: block;
      width: 100%;
      .form-group {
        width: 100%;
        margin-right: 0;
      }
      button {
        width: 100%;
        text-align: left;
      }
    }
  }
  .page-builder {
    .btn-grid {
      margin-bottom: 7px;
      padding-right: 5px;
    }
    .ge-canvas {
      &.ge-editing {
        .row {
          padding: 15px;
        }
        .ge-tools-drawer {
          margin: 10px;
        }
      }
    }
  }
  .wizard-4 {
    .step-container {
      div {
        &.content {
          margin-top: 10px;
        }
      }
    }
  }
  .nav-tabs {
    .nav-item {
      &.show {
        .nav-link {
          border-top: 0;
          border-bottom: 0;
        }
      }
    }
    .nav-link {
      border-top: 0;
      border-bottom: 0;
      &:hover,
      &.active,
      &:focus {
        border-top: 0;
        border-bottom: 0;
      }
    }
  }
  .border-tab {
    &.nav-tabs {
      .nav-item {
        width: 100% !important;
      }
    }
    .weather-widget-two {
      .bottom-whetherinfo {
        .whether-content {
          top: 35px;
        }
      }
    }
    .social-widget-card {
      .b-b-light {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
    .ecommerce-widget {
      .progress-showcase {
        margin-top: 15px;
      }
    }
  }
  .nav {
    display: inherit;
    text-align: center;
    border-bottom: none;
  }
  // tab material start
  .border-tab.nav-left {
    .nav-link {
      text-align: center;
      border-left: none;
    }
  }
  .border-tab.nav-right {
    .nav-link {
      text-align: center;
      border-right: none;
    }
  }
  .border-tab.nav-tabs {
    margin-bottom: 10px;
  }
  .tab-content {
    ~ .nav-tabs {
      &.border-tab {
        margin-top: 10px;
      }
    }
  }
  // tab material end
  .nav-pills {
    text-align: center;
  }
  .crm-overall {
    margin: 0 -15px -15px;
  }
  .product-page-main {
    padding: 15px;
    .m-t-15 {
      margin-top: 0 !important;
      .btn {
        margin-top: 10px;
      }
    }
  }
  .datetime-picker {
    label {
      text-align: left !important;
    }
  }
  .redial-chart-block {
    text-align: center;
  }
  #nav-tabContent {
    margin-top: 15px;
  }
  .reset-password-box {
    width: 430px;
  }
  .auth-bg {
    padding: 25px 15px;
  }
  .auth-bg-effect {
    display: none;
  }
  .date-picker {
    .text-right {
      text-align: left !important;
    }
  }
  .dataTables_wrapper {
    table {
      &.dataTable {
        margin-bottom: 15px !important;
      }
    }
  }
  ul {
    &.pagination {
      li {
        &:before {
          line-height: 3;
        }
      }
    }
  }
  div {
    &.table-responsive {
      > div {
        &.dataTables_wrapper {
          > div {
            &.row {
              > div {
                &[class^="col-"] {
                  &:last-child {
                    padding-left: 0 !important;
                  }
                  &:first-child {
                    padding-right: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.dataTables_wrapper {
      div {
        &.dataTables_length {
          margin-bottom: 15px;
          select {
            min-height: 30px;
          }
        }
      }
      table {
        &.dataTable {
          th,
          td {
            padding: 0.5rem;
          }
        }
      }
      div {
        &.dataTables_paginate {
          margin-top: 15px !important;
          .paginate_button {
            padding: 1px 7px;
            &.previous {
              font-size: 0;
              padding: 0;
              a {
                border: none !important;
              }
              &:before {
                content: "\e64a";
                font-family: $font-themify;
                font-size: 11px;
                padding: 0 5px;
              }
            }
            &.next {
              font-size: 0;
              padding: 0;
              a {
                border: none !important;
              }
              &:before {
                content: "\e649";
                font-family: $font-themify;
                font-size: 11px;
                padding: 0 5px;
              }
            }
          }
        }
      }
      .dataTables_filter {
        margin-bottom: 15px;
        input {
          &[type="search"] {
            height: 30px;
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .user-profile {
        .profile-img-style {
          padding: 15px;
          .img-container {
            margin-top: 15px;
          }
        }
        hr {
          margin: 15px 0;
        }
        .like-comment {
          margin-top: 15px;
        }
      }
    }
  }
  .jvector-map-height {
    height: 250px;
  }
  .user-profile {
    .order-sm-0 {
      order: -1;
    }
    .hovercard {
      .info {
        .ttl-info {
          text-align: center !important;
        }
        .ttl-xs-mt {
          margin-top: 20px;
        }
      }
    }
  }
  .icon-hover-bottom {
    .form-group {
      display: block;
      margin-bottom: 0;
    }
    .icon-popup {
      padding: 15px;
    }
    svg {
      &.climacon {
        height: 50px;
        width: 50px;
      }
    }
  }
  .blog-box {
    &.blog-shadow {
      &:before {
        box-shadow: inset 0px -100px 100px -13px rgba(0, 0, 0, 0.6);
      }
    }
    .blog-details {
      p,
      .single-blog-content-top {
        margin-top: 15px;
      }
      h4 {
        margin-top: 15px;
      }
      .blog-social {
        margin-top: 15px;
        li {
          & + li {
            padding-left: 20px;
            > span {
              display: none;
            }
          }
          &:first-child {
            margin-right: 10px;
            padding-right: 20px;
          }
        }
      }
    }
  }
  .comment-box {
    padding-top: 40px;
    padding-bottom: 40px;
    ul {
      ul {
        margin-left: 30px;
      }
    }
    li {
      .media {
        display: block;
        img {
          height: 50px;
          width: 50px;
          padding: 3px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .ace-editor {
    height: 320px;
  }
  .gallery {
    > a {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  .card {
    .card-header {
      h5 {
        font-size: 17px;
      }
    }
  }
  .theme-form {
    .form-group {
      margin-bottom: 15px;
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .default-according {
        .card {
          .card-header,
          .card-body,
          .card-footer {
            padding: 0.75rem 1.25rem;
          }
        }
      }
      .card {
        margin-bottom: 20px;
        .card-header,
        .card-body,
        .card-footer {
          .tab-content {
            .m-t-30 {
              margin-top: 20px !important;
            }
            .m-b-30 {
              margin-bottom: 20px !important;
            }
          }
        }
      }
      .page-body {
        padding: 0 !important;
      }
      .page-title {
        padding-top: 15px;
        padding-bottom: 15px;
        .row {
          h3 {
            font-size: 20px;
          }
        }
      }
    }
  }
  /* alert responsive css start here */
  $alert-name: primary, secondary, success, danger, warning, info, light, dark;
  $alert-color: var(--theme-deafult), $secondary-color, $success-color,
    $danger-color, $warning-color, $info-color, $light-color, $dark-color;
  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      background-color: transparent;
    }
  }
  .alert {
    .close {
      height: 100%;
    }
  }
  //business card
  .calender-widget {
    .cal-desc {
      padding: 10px 15px 15px !important;
      p {
        max-height: 100%;
      }
    }
  }
  //user profile
  .user-profile {
    hr {
      margin: 15px 0;
    }
    .hovercard {
      .cardheader {
        height: 300px;
      }
      .info {
        padding: 20px;
        .user-designation {
          border-top: 1px solid $light-color;
          border-bottom: 1px solid $light-color;
          margin-bottom: 15px;
          padding: 15px 0px;
        }
      }
      .social-media {
        a {
          margin-right: 0px;
          font-size: 16px;
        }
      }
      .follow {
        .follow-num {
          font-size: 20px;
        }
      }
    }
    .profile-img-style {
      .pictures {
        img {
          margin-bottom: 10px;
        }
      }
    }
  }
  //user card
  .custom-card {
    .card-footer {
      > div {
        h6 {
          font-size: 14px;
          font-weight: 600;
        }
        h3 {
          font-size: 24px;
        }
      }
    }
  }
  //calendar
  .calendar-wrap {
    .fc-toolbar {
      .fc-center {
        margin-top: 10px;
      }
    }
    a.fc-more {
      font-size: 0.7em;
    }
  }
  // megaoption page responsive scss
  .mega-inline {
    display: block;
  }
  .megaoptions-border-space-sm {
    padding-bottom: 30px !important;
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 15px;
      p {
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 10px;
      }
    }
  }
  .blog-box {
    &.blog-list {
      .blog-details {
        padding-top: 0;
      }
    }
    .blog-details {
      padding: 20px;
    }
    .blog-date {
      span {
        font-size: 25px;
      }
    }
  }
  .blog-single {
    .blog-box {
      .blog-details {
        padding: 0;
      }
    }
  }
  //error and maintenace
  .error-wrapper {
    padding: 20px 0;
    .btn {
      margin-top: 15px;
    }
    .img-100 {
      width: 50px !important;
    }
    .error-heading {
      margin-top: 20px;
      .cloud-second {
        margin-top: -60px;
      }
      .headline {
        font-size: 150px;
      }
    }
    .maintenance-heading {
      .cloud-second {
        display: none;
      }
      .headline {
        font-size: 25px;
        margin-top: -10%;
        letter-spacing: 2px;
      }
    }
  }
  //coming soon
  .comingsoon {
    .comingsoon-inner {
      .coming-soon-bottom-link {
        margin-top: 20px;
        line-height: 1;
      }
      h5 {
        margin-bottom: 20px;
        margin-top: 20px;
      }
      .countdown {
        padding: 20px 0px;
        ul li {
          margin: 0 7px;
        }
        .title {
          font-size: 12px;
        }
        .time {
          width: 45px;
          height: 45px;
          line-height: 45px;
          font-size: 16px;
          margin: 0 auto;
        }
      }
    }
  }
  // Form Wizard One start
  .form-wizard {
    .btn-mb {
      margin-bottom: 15px;
    }
  }
  // Form Wizard One ends
  // Form Wizard Two Start
  .stepwizard {
    margin-bottom: 15px;
  }
  // Form Wizard Two Ends
  // Form Wizard Three Start
  .f1 {
    .f1-steps {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  //Form Wizard Three ends
  // form wizard--4 scss
  .wizard-4 {
    ul.anchor {
      width: 100%;
      padding-bottom: 15px;
      padding-right: 0;
    }
    .step-container {
      width: 100%;
    }
  }
  //chart
  .small-chart-widget {
    .chart-container {
      height: 134px;
    }
  }
  .status-widget {
    .text-sm-right {
      text-align: right;
    }
  }
  //mega menu
  .mega-menu {
    padding-left: 15px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px;
    }
    .lg-mt {
      margin-top: 10px;
    }
    .xs-mt {
      margin-top: 15px;
    }
  }
  // navs page
  .nav-md-mt {
    margin-top: 15px;
  }
  .navs-icon {
    padding: 15px;
    .main-section {
      padding-top: 15px;
    }
    .separator {
      margin: 15px 0;
    }
  }
  .nav-list {
    padding: 15px;
  }
  .navs-dropdown {
    button {
      margin-top: 15px;
    }
    .onhover-show-div {
      top: 64px;
    }
  }
  .lg-mt {
    margin-top: 15px;
  }
  // scroll reval
  #aniimated-thumbnials {
    a {
      &:last-child {
        img {
          margin-bottom: 15px;
        }
      }
    }
  }
  // tilt
  .tilt-showcase {
    .mt-4 {
      margin-top: 15px !important;
    }
    ol {
      .m-b-20 {
        margin-bottom: 15px !important;
      }
    }
  }
  // state color page
  .xs-mt {
    margin-top: 15px;
  }
  // tag-pills page
  span:last-child.tag-pills-sm-mb {
    margin-top: 3px;
  }
  // alert page
  // animated modal page
  .animated-modal {
    .form-group {
      .form-control {
        width: 50%;
        margin: 0 auto;
      }
    }
    .animated-modal-md-mb {
      margin-bottom: 15px !important;
    }
  }
  // steps page
  .steps-md-mt {
    margin-top: 15px;
  }
  .steps-sizing-sm-mb {
    margin-bottom: 15px;
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 96% !important;
      }
    }
  }
  // datatable API page
  #API-chield-row_wrapper {
    #API-chield-row {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 20px;
            }
          }
        }
      }
    }
  }
  // chart-flot page
  .chart-block {
    .flot-chart-container {
      p#choices {
        width: 100%;
      }
      #toggling-series-flot {
        width: 100% !important;
      }
    }
  }
  // summer-note page
  .click2edit {
    & ~ .note-editor {
      &.note-frame {
        margin-bottom: 15px;
      }
    }
  }
  // chat page
  .chat-box {
    .chat-history {
      .call-content {
        > div {
          padding-top: 60px;
        }
      }
    }
    .chat-left-aside {
      .people-list {
        height: auto;
      }
    }
  }
  .call-chat-sidebar {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
    .people-list {
      height: auto;
    }
  }
  .chat-left-aside {
    .people-list {
      ul {
        max-height: 100px;
      }
    }
  }
  // support ticket page
  .ecommerce-widget {
    .progress-showcase {
      margin-top: 15px;
    }
  }
  // forget-password page
  .reset-password-box {
    .theme-form {
      .form-group {
        .btn {
          margin-top: 13px !important;
        }
      }
    }
  }
  // gallery-with-description page
  .gallery-with-description {
    a {
      > div {
        margin-bottom: 15px;
      }
    }
  }
  // gallery hover page
  .gallery-img-mb-sm {
    margin-bottom: 15px;
  }
  .page-wrapper {
    &.document {
      .landing-main {
        .navbar-expand-lg {
          .navbar-brand {
            width: 150px;
            img {
              width: unset;
            }
          }
        }
      }
      .page-body-wrapper {
        &.document-content {
          .page-sidebar {
            .nav-pills {
              text-align: left;
              .nav {
                text-align: left;
              }
            }
          }
          .page-body {
            margin-top: 75px !important;
          }
        }
      }
    }
  }
  // peity chart page
  .xm-mb-peity {
    margin-bottom: 8px;
  }
  // login page
  .authentication-main {
    padding: 30px 15px;
  }
  .translate_wrapper.active {
    .more_lang {
      &:before,
      &:after {
        display: none;
      }
      &.active {
        position: fixed;
        width: calc(100vw - 30px);
        left: 15px;
        top: 60px;
      }
    }
  }
  .onhover-dropdown {
    &:hover {
      .onhover-show-div {
        opacity: 0;
        transform: none;
        visibility: hidden;
        &.active {
          opacity: 1;
          transform: translateY(0px);
          visibility: visible;
          border-radius: 5px;
          overflow: hidden;
        }
      }
    }
  }
  .page-header {
    .header-wrapper {
      .nav-right {
        &.right-header {
          ul {
            &.profile-dropdown,
            &.chat-dropdown,
            &.notification-dropdown {
              width: calc(100vw - 30px);
              position: fixed;
              top: 60px;
              left: 15px !important;
            }
          }
        }
        .profile-dropdown {
          top: 43px;
        }
      }
    }
  }
  .wizard-4 {
    .action-bar {
      .btn {
        margin: 0 2px;
      }
    }
  }
  .tabbed-card {
    ul {
      right: 0px;
      left: unset;
    }
  }
}

@media (max-width: 480px) {
  .pagination-lg {
    .page-link {
      font-size: 1rem;
    }
  }
  .page-wrapper {
    .page-header {
      .header-wrapper {
        .nav-right {
          .onhover-show-div {
            right: 20px;
            width: 280px;
            position: fixed;
          }
        }
      }
    }
    &.only-body {
      .page-header {
        .header-wrapper {
          .nav-right {
            display: none;
          }
        }
      }
    }
  }
  h6 {
    font-size: 14px;
  }
  .prooduct-details-box {
    .media-body {
      margin-left: 0rem !important;
    }
  }
  .left-header {
    .level-menu {
      .header-level-menu {
        width: 140px;
        padding: 0.7rem 0;
        > li > .header-level-sub-menu {
          padding: 0.7rem 0;
          width: 120px;
          left: 140px;
        }
        svg {
          display: none;
        }
      }
    }
  }
  .box-layout {
    &.page-wrapper {
      .set-col-6,
      .set-col-3,
      .set-col-7,
      .set-col-5 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .knob-block {
    .chart-clock-main {
      margin: auto;
      width: auto;
    }
  }
  .wizard-4 {
    .action-bar {
      .btn {
        width: 100%;
        & + .btn {
          margin-top: 10px;
        }
      }
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        width: 260px;
        right: -80px;
        &:before,
        &:after {
          right: 89px !important;
        }
      }
    }
  }
  .inline-block-sm {
    display: inline-block;
  }
  .pagination-lg {
    .page-link {
      padding: 0.3rem 0.65rem;
    }
  }
  .nav-pills {
    li {
      width: 100%;
    }
  }
  .reset-password-box {
    width: 290px;
  }
  .icon-hover-bottom {
    .icon-first {
      margin-right: 10px;
    }
    .icon-popup {
      .icon-class {
        display: none;
      }
    }
  }
  .error-wrapper {
    .maintenance-heading {
      margin-top: 0;
    }
  }
  .custom-card {
    padding: 0;
    .card-footer {
      > div {
        h3 {
          font-size: 20px;
        }
      }
    }
    .card-social {
      li {
        padding: 5px 0;
      }
    }
    .profile-details {
      h6 {
        margin-bottom: 15px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .card-profile {
      img {
        height: 93px;
      }
    }
  }
  .card {
    .card-header {
      .card-header-right {
        i {
          margin: 0 3px;
          font-size: 14px;
        }
      }
      > span {
        & + span {
          display: none;
        }
      }
    }
  }
  // kanban board
  .jkanban-container {
    .kanban-container {
      .kanban-board {
        width: 100%;
      }
    }
  }
  //blog
  .blog-box.blog-shadow {
    .blog-details {
      padding: 10px;
      h4 {
        font-size: 14px;
      }
    }
  }
  .cal-date-widget {
    padding: 30px;
  }
  .calender-widget {
    .cal-img {
      height: 225px;
    }
  }
  .company-card {
    .details {
      .media {
        flex-wrap: wrap;
      }
    }
  }
  // from wizard two
  .stepwizard {
    .stepwizard-row {
      &:before {
        top: 17px;
      }
      .stepwizard-step {
        .btn {
          padding: 6px 22px;
        }
      }
    }
  }
  // email app
  .email-wrap {
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 83px;
                  height: 83px;
                }
              }
            }
          }
        }
      }
    }
  }
  //mega menu
  .mega-menu {
    padding-left: 20px;
    padding-top: 3px;
    .onhover-show-div {
      height: 405px;
      left: 0;
      top: 61px;
      padding: 15px;
    }
  }
  //  tag-pills
  .badge {
    padding: 0.42em 0.7em;
  }
  // alert page
  .alert-dismissible {
    .close {
      top: -1px;
      span {
        font-size: 19px;
      }
    }
  }
  // bootstrap notify page
  .notify-alert {
    width: 90%;
  }
  // invoice template page
  .invoice {
    .text-md-right {
      margin-top: 10px;
    }
  }
}

@media (max-width: 420px) {
  .and-many-more {
    font-size: 37px;
  }
  // tab css
  .tabbed-card {
    ul {
      &.border-tab {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 5px 15px 8px;
            }
          }
        }
      }
    }
  }
  .border-tab {
    &.nav-tabs {
      display: block;
    }
  }
  // compact sidebar css
  .page-wrapper {
    &.compact-sidebar {
      .page-body-wrapper {
        div.sidebar-wrapper {
          > div {
            width: 112px;
          }
          .sidebar-main {
            .sidebar-links {
              li {
                .sidebar-submenu,
                .mega-menu-container {
                  left: 112px;
                  width: 190px;
                }
              }
            }
          }
        }
      }
    }
    &.compact-small {
      .page-body-wrapper {
        div.sidebar-wrapper {
          > div {
            width: 80px;
          }
          .sidebar-main {
            .sidebar-links {
              li {
                .sidebar-submenu,
                .mega-menu-container {
                  left: 80px;
                  width: 210px;
                }
              }
            }
          }
        }
      }
    }
  }
  // login css
  .login-card {
    .login-main {
      &.login-tab {
        .border-tab {
          &.nav-tabs {
            display: flex;
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .datepicker--nav-action {
    width: 20px;
    margin-left: -10px;
    background-color: transparent;
    path {
      stroke: var(--theme-deafult);
    }
  }
  .clockpicker-align-top {
    top: 426px !important;
  }
  .note {
    min-width: 250px;
  }
  .datepicker {
    width: 239px;
  }
  .add-post {
    form {
      .m-checkbox-inline {
        label {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .alert-theme {
    font-size: 12px;
    button {
      right: 30px !important;
    }
    i {
      display: none;
    }
    span {
      + span {
        + span {
          padding: 15px;
        }
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        right: -73px;
      }
    }
  }
  .widget-joins {
    .border-after-xs {
      &:after {
        width: 92%;
      }
    }
  }
  // timepicker {
  .time_picker_wrapper {
    width: 250px;
  }
  .time_picker_modal_container {
    width: 278px !important;
  }
  .picker_container {
    margin: 0 10px 10px !important;
  }
  .time_picker_wrapper2 {
    .time_picker_container {
      width: 254px !important;
    }
  }
  // chart widget page css
  .status-widget {
    svg {
      width: 18px;
      height: 18px;
    }
  }
  // social app page css
  .tabs-scoial {
    &.border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 10px 5px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .user-profile {
    .hovercard {
      .user-image {
        .share-icons {
          right: 6px;
          top: -15px;
          li {
            .social-icon {
              width: 25px;
              height: 25px;
              i {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .social-chat {
    .other-msg {
      margin-left: 15px;
    }
  }
  .social-header {
    h5 {
      span.pull-right {
        display: none;
      }
    }
  }
  .custom-card {
    .dashboard-card {
      padding-top: 0;
    }
    .card-profile {
      img {
        bottom: 25px;
      }
    }
    .card-footer {
      > div {
        h6 {
          font-size: 12px;
        }
      }
    }
  }
  .flot-chart-container {
    height: 230px;
  }
  .img-cropper {
    .docs-tooltip {
      font-size: 11px;
    }
    .docs-toggles {
      .btn-group {
        .btn {
          padding: 0.375rem 0.2rem;
        }
      }
    }
  }
  .blog-box {
    .blog-details {
      p {
        line-height: 1.5;
      }
      .blog-social {
        li:last-child {
          padding-left: 0;
        }
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          padding: 0 10px;
        }
      }
    }
  }
  .button-builder-wrap {
    .btn-lg {
      font-size: 18px;
    }
  }
  .tabs-responsive-side {
    max-width: 100%;
  }
  //default dashboard
  .browser-widget {
    .media {
      i {
        font-size: 52px;
      }
      .media-body {
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  .bar-chart-widget {
    .bottom-content {
      .num {
        font-size: 20px;
      }
    }
  }
  // from wizard two
  .stepwizard {
    .stepwizard-row {
      .stepwizard-step {
        .btn {
          padding: 6px 20px;
        }
      }
    }
  }
  .user-status {
    table {
      tbody {
        img {
          height: 40px;
        }
      }
    }
  }
  //from wizard four
  .wizard-4 {
    ul {
      &.anchor {
        padding-top: 15px;
      }
    }
    .msg-box {
      top: 40px !important;
    }
  }
  .xs-width-100 {
    min-width: 100%;
  }
  // email app
  .email-wrap {
    .action-wrapper {
      .actions {
        li {
          margin-right: 13px;
        }
      }
    }
    .email-right-aside {
      .email-content {
        .email-wrapper {
          .attachment {
            ul {
              li {
                img {
                  width: 65px;
                  height: 65px;
                }
              }
            }
          }
        }
      }
      .email-top {
        .dropdown-menu {
          left: -58px;
        }
      }
    }
  }
  // form-builder
  .form-builder {
    #components {
      .component {
        .form-group {
          #button1id {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  // according
  .default-according {
    .card {
      .card-header {
        i {
          font-size: 14px;
          top: 24px;
        }
      }
      .btn-link {
        display: block;
        width: 100%;
        padding-left: 17px;
      }
    }
  }
  //rating
  .star-ratings {
    .stars {
      .title {
        line-height: 1;
      }
    }
  }
  // tour page
  .user-profile {
    .hovercard {
      .social-media {
        a {
          padding: 0 12px;
        }
      }
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    .touchspin-vertical-tab {
      .input-group {
        width: 93% !important;
      }
    }
  }
  // form-wizard-four page
  .step-container {
    height: 280px !important;
  }
  // jsgrid-table page
  .sort-panel {
    .d-inline {
      .btn {
        margin-left: 87px;
      }
    }
  }
  // jsgrid-table css
  .jsgrid {
    .jsgrid-pager-container {
      .jsgrid-pager {
        .jsgrid-pager-page {
          padding: 0.5em 0.62em;
        }
      }
    }
  }
  // chat page
  .chat-box {
    .chat-right-aside {
      flex: 0 0 96%;
      max-width: 96%;
      .chat {
        .chat-message {
          width: calc(100% - 20px);
        }
      }
    }
  }
  // error 400 page
  .error-wrapper {
    .error-heading {
      .headline {
        font-size: 100px;
      }
    }
  }
  // gallery page
  .lg-toolbar {
    .lg-icon {
      width: 40px;
    }
  }
  // calender page
  .calendar-wrap {
    a.fc-more {
      font-size: 0.59em;
    }
    .fc {
      th {
        font-size: 10px;
      }
    }
  }
  //modal footer
  .modal-footer {
    display: block;
    text-align: center;
    button:first-child {
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 576px) {
  .product-wrapper-grid {
    &.list-view {
      .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .sidebar-wrapper {
    .back-btn {
      display: none;
    }
  }
}

/**=====================
     62. Responsive CSS Ends
==========================**/

//css
.selectstatusBox {
  position: relative;
}

.selectstatusBox select {
  width: 100%;
  color: #495057;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#selectstatus {
  display: none;
  border: 1px #fd7e14 solid;
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.175) !important;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  z-index: 1;
}

#selectstatus label {
  display: block;
  padding-left: 10px;
}

#selectstatus label:hover {
  background-color: #fd7e14;
}

.selectfranchiseBox {
  position: relative;
}

.selectfranchiseBox select {
  width: 100%;
  color: #495057;
}

.overSelect1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#selectfranchise {
  display: none;
  border: 1px #fd7e14 solid;
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.175) !important;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  z-index: 1;
}

#selectfranchise label {
  display: block;
  padding-left: 10px;
}

#selectfranchise label:hover {
  background-color: #fd7e14;
}

.customizer-contain.open {
  width: 35% !important;
  // background-color: #ccccff;
}

//switch
.franchise-switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 25px;
  background-color: darkgray;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  border-radius: 34px;
  top: 2px;
}

// .franchise-switch.off,
// .franchise-switch.on {
//   // border-radius: 25px;
//   padding: 5px;
//   cursor: pointer;
// }
.franchise-switch.off:before,
.franchise-switch.off:after {
  -webkit-transition: 0.6s;
  transition: all 0.6s;
}

.franchise-switch.on:before,
.franchise-switch.on:after {
  -webkit-transition: 0.6s;
  transition: all 0.6s;
}

.franchise-switch.off:before,
.franchise-switch.on:before {
  display: block;
  position: absolute;
  content: "";
  height: 21px;
  width: 20px;
  left: 4px;
  top: 2px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: 0.6s;
  transition: all 0.6s;
  -webkit-transition-delay: 0.01s;
  transition-delay: 0.01s;
}

.franchise-switch.off:after {
  display: block;
  position: absolute;
  // content: ": (";
  left: 17px;
  top: 15px;
}

.franchise-switch.on:before {
  -webkit-transform: translateX(32px);
  -ms-transform: translateX(32px);
  transform: translateX(32px);
  // background-color: #7366ff !important;
}

.franchise-switch.intermediate:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #dc631f !important;
}

.franchise-switch.intermediate {
  background-color: yellow !important;
}

.franchise-switch.on:after {
  position: absolute;
  left: 56px;
  top: 15px;
}

.franchise-switch.on {
  background-color: #285295;
}

//label styling
Input:focus+.placeholder_styling,
select:focus+.placeholder_styling,
textarea:focus+.placeholder_styling,
// tel:focus + .placeholder_styling,
// email:focus + .placeholder_styling,
input.not-empty+.placeholder_styling,
select.not-empty+.placeholder_styling,
textarea.not-empty+.placeholder_styling // tel.not-empty + .placeholder_styling
// email.not-empty + .placeholder_styling
{
  font-size: 12px;
  // color: #7366ff;
  top: -10px;
  left: 10px;
  background: #ffffff;
  padding: 0px 5px 0px 5px;
}

Input:invalid {
  border: 1px solid red;
}

.input_wrap {
  width: auto;
  height: auto;
  position: relative;
}

.input_wrap .placeholder_styling {
  color: #2b2b2b;
  padding: 10px;
  position: absolute;
  // text-align: center;
  top: 0;
  // left: 12px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  pointer-events: none;
}

Input[type="text"]:focus {
  outline: none !important;
  border: none !important;
}

//select field
// .floating-label {
//   position:relative;
//   margin-bottom:20px;
// }
// .floating-input , #floating-select {
//   font-size:14px;
//   padding:4px 4px;
//   display:block;
//   width:100%;
//   height:30px;
//   background-color: transparent;
//   border:none;
//   border-bottom:1px solid #757575;
// }
// .floating-input:focus , #floating-select:focus {
//      outline:none;
//      border-bottom:2px solid #5264AE;
// }
// #options_label {
//   color:#999;
//   font-size:14px;
//   font-weight:normal;
//   position:absolute;
//   pointer-events:none;
//   left:5px;
//   top:5px;
//   transition:0.2s ease all;
//   -moz-transition:0.2s ease all;
//   -webkit-transition:0.2s ease all;
// }
// #floating-select:focus ~ #options_label , #floating-select:not([value=""]):valid ~ #options_label {
//   top:-18px;
//   font-size:14px;
//   color:#5264AE;
// }
// .highlight {
//   position:absolute;
//   height:50%;
//   width:100%;
//   top:15%;
//   left:0;
//   pointer-events:none;
//   opacity:0.5;
// }
.selectleadsourcesBox {
  position: relative;
}

.selectleadsourcesBox select {
  width: 100%;
  color: #495057;
}

.overSelect2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#selectleadsource {
  display: none;
  border: 1px #fd7e14 solid;
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.175) !important;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  z-index: 1;
}

#selectleadsource label {
  display: block;
  padding-left: 10px;
}

#selectleadsource label:hover {
  background-color: #fd7e14;
}

.sselectbranchBox {
  position: relative;
}

.selectbranchBox select {
  width: 100%;
  color: #495057;
}

.overSelect3 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#selectbranch {
  display: none;
  border: 1px #fd7e14 solid;
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.175) !important;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  z-index: 1;
}

#selectbranch label {
  display: block;
  padding-left: 10px;
}

#selectbranch label:hover {
  background-color: #fd7e14;
}

.customizer-contain.open {
  width: 80% !important;
  // z-index: 9999;
  // background-color: #ccccff;
}

// .sc-hBMUJo{
//   display: none !important;
// }
.ighyUr > div {
  display: none !important;
}

// .kplEDj{
//   display:content !important;
// }
// .kPXkKA{
//   justify-content:flex-start !important;
// }
// .rdt_TableCol_Sortable>span{
// opacity:1 ;
// }
.rdt_TableHeadRow {
  // background-color: #f9f6fb !important;
  // border-bottom-color: #f1f1f1 !important;
  border-bottom: 1px solid #cccccc !important;
}
.acc_status {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  top: -21px;
  left: 22px;
  color: black;
}
.curr_status {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  top: -21px;
  left: 22px;
  color: #000000;
}
// .customer-list {
//   .rdt_TableHeadRow {
//     .rdt_TableCol:nth-child(-n+4) {
//       position: sticky;
//       background-color: white !important;
//       z-index: 1;

//     }

//     .rdt_TableCol:first-child {
//       left: 0;
//     }
//     .rdt_TableCol:nth-child(2) {
//       left: 80px;
//     }
//     .rdt_TableCol:nth-child(3) {
//       left: 210px;
//     }
//     .rdt_TableCol:nth-child(4) {
//       left: 398px;
//       border-right: 1px solid #CECCCC;
//     }
//   }
// }
//css added by Marieya for cust-list search bar border-bottom
.customer-list{
.css-ercmji::before{
  border-bottom:none;
}
}
//end
.customer-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.service-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}

.customer-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 5) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 48px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 128px;
    }
    .rdt_TableCol:nth-child(4) {
      left: 265px;
    }
    .rdt_TableCol:nth-child(5) {
      left: 375px;
      // left: 265px;
      border-right: 1px solid #cecccc;
    }
  }
}

.service-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 1) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
  }
}
.franchise-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 4) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 48px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 165px;
    }
    .rdt_TableCol:nth-child(4) {
      left: 275px;
      border-right: 1px solid #cecccc;
    }
    // .rdt_TableCol:nth-child(5) {
    //   left: 400px;
    // }
  }
}



.nastable-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 5) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 48px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 165px;
    }
    .rdt_TableCol:nth-child(4) {
      left: 275px;
      
    }
    .rdt_TableCol:nth-child(5) {
      left: 370px;
      border-right: 1px solid #cecccc;
    }
  }
}
.cust-reports-list {
  .ekieV {
    max-width: 150px !important;
  }
}
.reports-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 4) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }

    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 47px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 126px;
      // border-right: 1px solid #CECCCC;
    }
    .rdt_TableCol:nth-child(4) {
      left: 256px;
      border-right: 1px solid #cecccc;
    }
  }
}

.leadTable {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}

.leadTable {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 1) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    // .rdt_TableCol:nth-child(2) {
    //   left: 48px;
    // }
  }
}

// .roles-list {
//   .rdt_TableHeadRow {
//     .rdt_TableCol:nth-child(-n+4) {
//       position: sticky;
//       background-color: white !important;
//       z-index: 1;

//     }

//     .rdt_TableCol:first-child {
//       left: 0;
//     }
//     .rdt_TableCol:nth-child(2) {
//       left: 47px;
//     }
//     .rdt_TableCol:nth-child(3) {
//       left: 126px;
//       // border-right: 1px solid #CECCCC;

//     }
//     .rdt_TableCol:nth-child(4) {
//       left: 256px;
//       border-right: 1px solid #CECCCC;
//     }
//   }
// }
.roles-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 3) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 48px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 205px;
      border-right: 1px solid #cecccc;
    }
    // .rdt_TableCol:nth-child(4) {
    //   left: 207px;

    // }
  }
}
// .franchise-list {
//   .rdt_TableHeadRow {
//     .rdt_TableCol:nth-child(-n+3) {
//       position: sticky;
//       background-color: white !important;
//       z-index: 1;

//     }

//     .rdt_TableCol:first-child {
//       left: 0;
//     }
//     .rdt_TableCol:nth-child(2) {
//       left: 150px;
//     }
//     .rdt_TableCol:nth-child(3) {
//       left: 300px;
//       border-right: 1px solid #CECCCC;

//     }
//   }
// }

// css done by Marieya for dataTable columns
#table_top {
  margin-top: -2%;
}
.sms_gateway {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.franchise-type-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.payment-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.roles-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.franchise-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.nastable-list{
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.billing-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
#billing_customer_col_right {
  position: relative;
  right: 40px;
}

#billing_mob_col_right {
  position: relative;
  right: 30px;
}
#payment_method_col_right {
  position: relative;
  right: 10px;
}
#installation_col_right {
  position: relative;
  right: 30px;
}
#amount_col_left {
  position: relative;
  left: 20px;
}
#type_col_left {
  position: relative;
  left: 10px;
}
.wallet-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
#wallet_columns_right {
  position: relative;
  right: 80px;
}

#code_columns_right {
  position: relative;
  right: 20px;
}
.billing-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 5) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 48px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 155px;
    }
    .rdt_TableCol:nth-child(4) {
      left: 325px;
    }
    .rdt_TableCol:nth-child(5) {
      left: 425px;
      border-right: 1px solid #cecccc;
    }
    // .rdt_TableCol:nth-child(6) {
    //   left: 427px;

    // }
  }
}
.billing-list1 {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 5) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 48px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 187px;
    }
    .rdt_TableCol:nth-child(4) {
      left: 296px;
    }
    .rdt_TableCol:nth-child(5) {
      left: 403px;
      border-right: 1px solid #cecccc;
    }
    // .rdt_TableCol:nth-child(6) {
    //   left: 427px;

    // }
  }
}

.branch-lists {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 1) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
  }
}
// .helpdesk-list {
//   .rdt_TableHeadRow {
//     .rdt_TableCol:nth-child(-n+4) {
//       position: sticky;
//       background-color: white !important;
//       z-index: 1;

//     }

//     .rdt_TableCol:first-child {
//       left: 0px;
//     }
//     .rdt_TableCol:nth-child(2) {
//       left: 80px;
//     }
//     .rdt_TableCol:nth-child(3) {
//       left: 210px;
//     }
//     .rdt_TableCol:nth-child(4) {
//       left: 300px;
//       border-right: 1px solid #CECCCC;
//     }
//   }
// }
.helpdesk-list {
  .rdt_TableCell {
    font-size: 12px !important;
  }
}
.helpdesk-list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 5) {
      position: sticky;
      background-color: white !important;
      z-index: 1;
    }
    .rdt_TableCol:first-child {
      left: 0;
    }
    .rdt_TableCol:nth-child(2) {
      left: 48px;
    }
    .rdt_TableCol:nth-child(3) {
      left: 128px;
    }
    .rdt_TableCol:nth-child(4) {
      // left: 255px;
      left: 255px
    }
    .rdt_TableCol:nth-child(5) {
      // left: 565px;
      left: 360px;
      // left: 255px;
      border-right: 1px solid #cecccc;
    }
    // .rdt_TableCol:nth-child(6) {
    //   // left: 565px;
    //   left: 410px;

    // }
  }
}

.rdt_Table {
  border: none;
}

.rdt_TableRow {
  border-bottom-width: 0.1px;
  // border-bottom-color: #f1f1f1 !important;
  // box-shadow: 0 0 2px;
  border-bottom: 1px solid #cccccc !important;
}

.rdt_TableCol_Sortable {
  color: #968f99;
  font-weight: 200;
}

#fn:hover {
  //  cursor:  <i className="fa fa-pencil"></i>;
  cursor: e;
}

//csv
.download {
  display: none;
}

// .dropdown :first-child {
//     display: none;
// }

// .dropdown :nth-child(2) {
//     background-color: #7366ff;
//     border: none;
//     outline: none;
//     color: white;
//     // opacity: 0;
// }

.data-table-extensions
  > .data-table-extensions-action
  > button.download:hover::after {
  content: "" !important;
}
.dropdown-bg {
  background-color: $transparent-color !important;
  color: white !important;
}

.data-table-extensions
  > .data-table-extensions-action
  > button.download:hover::after {
  content: "" !important;
}

.data-table-extensions > .data-table-extensions-action > button.download {
  background-image: none !important;
}

.data-table-extensions > .data-table-extensions-action > button.drop,
.data-table-extensions > .data-table-extensions-action > button:hover {
  background-color: transparent !important;
  opacity: 0;
}

.data-table-extensions > .data-table-extensions-action > button {
  float: right;
  display: block;
  width: 115px !important;
  height: 40px !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 0.3rem;
  outline: none;
  margin-right: 0.3rem;
  margin-left: -40px;
}

.data-table-extensions > .data-table-extensions-action > .dropdown.drop {
  animation: fadeIn 0.3s;
  display: block;
  /* box-shadow: 0 0 20px rgba(8, 21, 66, 0.05); */
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
  background-color: #fff;
}

.data-table-extensions > .data-table-extensions-action > .dropdown {
  margin: 1.125rem 0 0 !important;
  border-radius: 5px !important;
}

.data-table-extensions > .data-table-extensions-action > .dropdown button {
  padding: 10px 25px !important;
  color: black !important;
}

.data-table-extensions
  > .data-table-extensions-action
  > .dropdown
  button:hover {
  background-color: rgba(115, 102, 255, 0.1) !important;
  color: var(--theme-deafult) !important;
}

//csv end
// input[type="file"] {
//   display: none;
// }
// .dropdown{
//   top: -49px;
//   left: 29%;
// }
// #afterfocus{
//         position: relative;
//     /* margin-bottom: -11px; */
//     margin-top: -20px;
// }
#afterfocus:disabled {
  background-color: #e5e5e5 !important;

  // background-color: #f9f6fb !important;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#afterfocus:enabled {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.afterfocus:disabled {
  // background-color: #f9f6fb !important;
  background-color: #e5e5e5 !important;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.afterfocus:enabled {
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// .show-hide span.show {
//   margin: 100px !important;
//   width: 50px !important;
//   height: 13px !important;
//   border: solid 1px #000 !important;
//   border-radius:  75% 15% !important;
//   position: relative !important;
//   transform: rotate(45deg) !important;
// }
// .show-hide span.show:before{
//   content: '';
//   display: block !important;
//   position: absolute !important;
//   width: 5px !important;
//   height: 5px !important;
//   border: solid 1px #000;
//   border-radius: 50% !important;
//   left: 3px !important;
//   top: 3px !important;
// }
// .activitydropdown{
//   border:1px solid #ced4da !important;
//   border-top-left-radius: 0px !important;
//   border-top-right-radius: 0px !important;
//   border-radius: 0.25rem !important;
// }
// .digits{
//   width: 165% !important;
// }

.rbt-input-multi.form-control[disabled] {
  background-color: white !important;
}

.rbt-input-multi.form-control[disabled] {
  background-color: white !important;
}

// .default-according.style-1 button:before{
//   left:40px !important;
// }
// .default-according.style-1 button{
//   text-align: left !important;
// }
.firstletter {
  font-size: 0;
  background-color: rgba(115, 102, 255, 0.06);
  padding: 10px 20px 12px;
  border-radius: 50px;
  font-weight: bold;
  text-transform: uppercase;
}

.default-according.style-1 button {
  text-align: left !important;
}

#grey {
  color: gray !important;
}

.firstletter:first-letter {
  font-size: 12px;
}

#checkboxes_cardheader {
  background-color: #faf5fb;
  border-radius: 10px 10px 0px 0px;
  height: 50px;
}

.border-tab {
  border-bottom: none;
  background-color: "transparent";
  margin-bottom: "3rem";
  margin-top: "2rem";
}

// input[type="file"]{
//   width:100%;
//   height:100%;
//   position:absolute;
//   top:0;
//   left:0;
//   opacity:0;
//   cursor:pointer;
// }
.imageupload:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 110px;
}

.uploadstyle {
  // width: 200px;
  height: 140px;
  position: relative;
}

.header-level-menuexport {
  width: 100%;
  text-align: center;
}

.header-level-menuexport li {
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  border-bottom: 1px solid #d0d0d0;
}

.header-level-menuexport li span {
  color: #2c323f;
  padding: 6px;
}

.header-level-menuexport li:hover {
  background-color: transparent;
}

input::-webkit-input-placeholder {
  color: #404040 !important;
  font-size: 14px !important;
}

.padding-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.errortext {
  color: red !important;
  font-weight: 400 !important;
  padding-left: 2px;
}

.input_error_state {
  border: 1px solid red;
}

.search_iconbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2rem;
  left: 2rem;
  z-index: 1;
  width: 35rem;
  border: 2px solid #e7eaea;
}

.search_iconbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px !important;
  margin-left: -25px !important;
}

.badge-groups {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.utility-badge-numbers {
  margin-left: 5px !important;
  color: var(--theme-deafult) !important;
}

.utility-badge-numbers-active {
  margin-left: 5px !important;
  color: #dc631f !important;
}

.utility-badge-text {
  color: #6e6e6ede !important;
  display: inline;
}

.utility-badge-text-active {
  color: $black !important;
  display: inline;
}

.badge-active {
  background-color: transparent !important;
}

// .rd3t-svg {
// margin-left: 150px !important;
// }
// .rd3t-grabbable{
//   width: 400px !important;
//   transform-origin: 63% 50% !important;
// }
//address dot

.customizer-contain.openblade.open {
  width: 80% !important;
  z-index: 9999;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  width: 100% !important;
}

// .edit-delete-button-wrapper{
//     position: absolute;
//     right: 0;
// }

.feebacks .pull-left {
  font-size: 16px !important;
  border-radius: 30px !important;
  // color of id in table
}
#idcolor {
  cursor: pointer;
  // color: #dc631f !important;
  font-weight: 600;
}
.react-flow__node-output.selected,
.react-flow__node-output.selected:hover {
  background-color: green !important;
}
// .react-flow__node-output .react-flow__handle {
//     display: none !important;
// }
// to make text center in button
#bringcenter {
  text-align: center !important;
}
.center1 {
  text-align: center !important;
}
.react-flow__pane,
.react-flow__renderer,
.react-flow__selectionpane {
  left: 102px !important;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #1565c0 !important;
}
.radio-primary input[type="radio"]:checked + label::after {
  border-color: #1565c0 !important;
}
.btn-light.disabled,
.btn-light:disabled {
  background-color: #51bb25 !important;
}
.sc-bdnxRM .ecDzaP .rdt_Table {
  margin-top: 38px !important;
}
.m-t-15 .m-checkbox-inline .custom-radio-ml .form-group {
  z-index: 999 !important;
}
// .ecDzaP{
// margin-top: 45px !important;
// }
// .sc-bdnxRM .rdt_Table{
// margin-top: 38px !important;
// }
.formContent {
  display: flex;
  width: 100%;
  height: fit-content;
  // background-color: $white;
  // padding-left: 2rem;
  // padding-top: 2rem;
  // border-radius: 15px;
}

.left-form-sections {
  width: 19%;
  border-right: 1px solid #a6a6a6;
  /* margin-left: 7px; */
  background-color: white;
  height: 100%;
  display: none;
}

.right-form-sections {
  width: 100%;
  position: relative;
  background-color: #fff;
  // border-bottom-right-radius: 15px;
  // border-top-right-radius: 15px;
  min-height: 750px;
  padding: 30px;
  margin-bottom: 30px;
}
.service_plan {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* Theme/Accent */
  cursor: pointer;
  color: #377df6;
}
.KYC_togglename {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  font-feature-settings: "rclt" off;

  /* Light/Fill Color/Text/Primary */

  color: rgba(0, 0, 0, 0.8956);
}
.KYC_heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  color: #000000;
}
.dividing_line {
  border: 1px solid #c4c4c4;
}

.left-form-tabs {
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 1.5rem;
  text-align: left;
  border-bottom: 1px solid $border-gray;
  font-size: large;
  cursor: pointer;
}

.left-form-tabs.active {
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 1.5rem;
  text-align: left;
  border-bottom: 1px solid $border-gray;
  font-size: large;
  cursor: pointer;
  font-weight: bold;
  color: var(--theme-deafult);
}

.image-upload-form-group {
  text-align: center;
  align-content: center;
  justify-content: center;
  display: flex;
  background: lightgray;
  width: 250px;
  height: 250px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5%;
}

.breadcrumb-styles {
  font-size: large;
  color: $dark-gray;
}

.breadcrumb-styles.breadcrumb-active {
  font-size: large;
  color: var(--theme-deafult);
}

.progress-stepper-wrapper {
  margin-top: 6rem;
  height: 100vh;
}

.upload-profile-pic-container {
  position: relative;
}

.form-heading-style {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.kyc-form-header {
  color: var(--theme-deafult);
}
.payment_method {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  font-feature-settings: "rclt" off;

  color: #000000;
}
.breadcrumb-wrapper {
  margin-top: 1rem;
  font-size: 20px;
  padding-left: 10%;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #d6d6d6 !important;
  border-color: #d6d6d6 !important;
  color: black;
}
.form-submit-btn {
  // position: absolute;
  bottom: 0;
  // left: 50%;
  // transform: translate(-50%);
}
// .customizer-contain .customizer-body li.custlist{
//     margin-left: 50px;
// }
// #custinfo{
//     margin-left: 50px;
// }
.icofont-edit {
  // color:#dc631f;
  font-size: 22px;
  cursor: pointer;
  // margin-left: 15px;
  // right: 70px;
  margin-left: 85%;
  position: absolute;
  top: 10px;
  color: #404040;
}
.customizer-contain {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.customizer-header {
  padding: 0px;
  padding: 20px 0 15px 0;
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-bottom: 1px solid #c4c4c4 !important;
  margin-left: 39px;
  margin-right: 38px;
  // border: none;
}

#headerheading {
  margin-top: -56px;
  position: absolute;
  padding-left: 1.5rem;
  // font-size: large;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 27px;
  // color: #000000;
}
.icofont-ui-delete {
  color: black;
  font-size: 21px;
  cursor: pointer;
  margin-left: -11px;
}
.image-clear-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: large;
  color: $black;
}
// Sailaja changers 7683 line from -10px to -36px ref num cust
.image-clear-icon-container {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid $black;
  background: var(--theme-deafult);
}
.rolestatus {
  display: flex;
  float: right;
  position: absolute;
  z-index: 1;
  // margin-left: 50%;
  // top: -2rem;
  right: 20px;
  top: 15px;
}

.form-image-upload,
.form-image-upload-idproof {
  // display: flex;
  flex-direction: column;
  text-align: center;
  background: #ecf0f2;
  border: 1px dashed #b9c2cb;
  // width: 113%;
  // margin-left: -7%;
}
.form-image-upload-idproof {
  width: 288px;
  height: 150px;
  margin-top: 30px;
}
// table header
.rdt_TableHeader {
  width: 20% !important;
  background: transparent;
  background-color: transparent !important;
  position: absolute !important;
  top: -45px;
  right: 20%;
}

.rdt_TableHeader > div:last-child {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px;
}
#tree-style {
  background-color: rgb(252, 190, 123) !important ;
  border-radius: 3px;
  // width: fit-content;
}
#styleofnasoltdpcpe {
  background-color: rgb(255, 217, 179);
  margin-right: -10px;
  font-size: medium;
  border-radius: 0.25rem;
  height: 39px;
  width: 164px;
  white-space: nowrap;
  text-align: center;
}

.radio_animated:after {
  border: 1px solid #1565c0;
}
#random {
  margin-left: -33px !important;
}
#activebutton {
  white-space: nowrap;
  padding: 5px !important;
}
.customizer-contain h6 {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
#moveup {
  position: relative;
  margin-top: -20px;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: grey !important;
  // border-color: 1px solid grey !important;
  border-color: gray !important;
}
#editmoveup {
  margin-top: -19px !important;
}
// .iizuaN {
//     font-weight: 400;
//     white-space: pre;
// }
.form-group {
  margin-bottom: 1.8rem !important;
}

#reaarangeaddress {
  padding-top: 38px;
}
// .hZSLrf {
//     width: max-content !important;
//     white-space: nowrap !important;
// }
// .sc-kfYoZR .bIRQQN{
//     white-space: nowrap !important;

//     width: max-content !important;
// }
// .sc-bdnxRM .ecDzaP .rdt_Table{
//     white-space: nowrap !important;

//     width: max-content !important;
//     max-width: max-content !important;
// }
// .sc-gtsrHT .kofSid .rdt_TableHead{
//     white-space: nowrap !important;

//     width: max-content !important;
//     max-width: max-content !important;
// }
// .ezEBKV{
//     white-space: nowrap !important;

//     width: auto !important;
// }
// .coacqG{
//     white-space: nowrap !important;

//     max-width: fit-content !important;
//     min-width:auto !important;
// }
// .ecDzaP{
//     white-space: nowrap !important;

//     width: 100% !important;
//     max-width: 100% !important;
// }
// .sc-crzoAE .iOlZKJ .rdt_TableCol_Sortable{
//     width: max-content !important;
//     white-space: nowrap !important;

// }
// .table-address{
//     width: auto !important;
//     white-space: nowrap !important;
// }
// .bIRQQN {
//     position: relative;
//     //width: 2000px !important;
//     display: table;
// }

// .dropdown :first-child{
// display:initial;
// }

// .dropdown :nth-child(2){
//     background-color:white;
//     color:black;
// }
.ant-select-selection-placeholder {
  color: #495057 !important;
}
.fileContainer .uploadPictureContainer {
  width: 100% !important;
  white-space: nowrap !important;
}
.table-border-vertical tr,
.table-border-vertical th,
.table-border-vertical td {
  border-top: 6px !important ;
  border-right: 1px solid #f2f4ff;
}

.ongoing {
  border-bottom: 2px solid #de6c2d;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #dc631f;
  width: fit-content;
}

.customerlist-search {
  width: 28rem;
}

.border-tab.nav-tabs .nav-item .nav-link.active {
  color: #1976d2;
}

.rightsideline {
  color: #1976d2;
}

.customer-lists-header-btns-column {
  border-radius: 10%;
  padding-bottom: 10px;
}
.helpdesk-lists-header-btns-column {
  border-radius: 10%;
  padding-bottom: 10px;
}

.renew-change-plan-modal {
  max-width: 1200px;
}

.renew-change-plan-modal-body {
  padding: "35px";
  max-height: "600px";
  overflow: "auto";
}

// switch-modal

.switch-modal {
  max-width: 1000px;
}
// .react-datepicker-wrapper{
//   margin-top: -24px !important;
// }
.date-picker input {
  height: 35px;
  font-size: medium;
  width: 180px;
  border-radius: 5%;
  border: 1px solid #e7eaea !important;
}
.date-picker input:focus {
  border: 1px solid #e7eaea !important;
}

.tab-bottom-margin {
  margin-bottom: 10px !important;
  white-space: nowrap;
}

.loginimg {
  // position: absolute;
  // top: 72%;
  // left: 50%;
  width: 90%;
  // transform: translate(-50%, -50%);
}

.logininput {
  padding: 1.375rem 0.75rem;
}

.css-v84d5j-MuiSvgIcon-root {
  font-family: Font Awesome 5 Free;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "rclt" off;

  color: #0052ac;
}

.customerdetailsheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #0052ac;
}

// below css classes are used for dashboard analytics and created by Marieya
.field-styles {
  margin-top: 25px;
  margin-bottom: 34px;
  margin-left: 2px;
}

.Main-grid {
  padding-right: 80px;
  padding-top: 10px;
}
// .doughnut{
//   padding-bottom: 10px;
// }
.Sub-grid {
  padding-top: 25px;
}
.Grid-checkbox {
  display: flex;
}
.Active-checkbox-color {
  background-color: #344cab;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.Deactive-checkbox-color {
  background-color: #7593ca;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.Expired-checkbox-color {
  background-color: #b9d0f8;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.Offline-checkbox-color {
  background-color: #cddffe;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.Online-checkbox-color {
  background-color: #99ff99;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.Arrow-icon {
  float: right;
  color: #344cab;
  font-size: 35px;
}
.bandwidth-usage-text {
  font-size: 30px;
}
.Non-Feasible-lead-checkbox-color {
  background-color: #ffc107;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.Closed-and-Converted-checkbox-color {
  background-color: #6610f2;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.lead-checkbox-color {
  background-color: #007bff;
  top: 5px;
  position: relative;
  right: 7px;
  height: 10px;
  width: 10px;
}
.dashboard-content-title {
  font-size: 26px;
}
.lead-arrow-icon {
  float: right;
  color: #344cab;
  font-size: 35px;
}

.dashboardstatus {
  position: relative;
  font-size: 15px;
  font-weight: 400;
  color: #7c7676;
}
// dashboard styles responsive  sudha

// @media screen and (min-width: 481px) and (max-width: 1024px)
// {
//   .css-1k7sk4d-MuiGrid-root{
// font-size: 10px !important;
//   }
// }
// @media screen and (min-width: 1283px ){
//   .Deactive-checkbox-color{
//     top: 5px;
//     position: relative;
//     right: 7px;
//     height: 10px;
//     width: 10px;
//   }
// }

// .Activity-card{
//   display: flex !important;
//   padding: 10px;
//  height: 46%
// }

// .Activity-cardheader{
//    padding: 12px;
// }
// .Activity-cardbody{
//   padding: 15px;
//   margin-left: 10px;
//   }

.Overall-customer-card {
  display: flex !important;
  padding: 10px;
  height: 264px;
}
.Overall-customer-cardheader {
  padding: 8px;
}
//  .Overall-customer-cardbody{
//   padding: 5px;
//  height:  28%;
//  margin-left: 10px;
//  margin-top: -29px;

//  }
.Overall-customer-cardbody {
  padding: 5px;
  margin-left: 10px;
  margin-top: -27px;
}

// @media only screen
// and (min-device-width : 400px)
// and (max-device-width : 3000px)
// {
// .main-grid{
//   padding-right: 10px;
//   padding-top: 9px;
// }
.Activity-card {
  display: flex !important;
  padding: 10px;
  min-height: 46%;
  //  height:269px;
}
.Activity-cardheader {
  padding: 12px;
}
.Activity-cardbody {
  padding: 19px;
  // margin-left: 10px;
}
//   .dashboardstatus{
//  font-size: 11px !important;
//   }

.card .card-body {
  padding: 24px;
  background-color: transparent;
}
.Activity-cardbody .card-body {
  padding-top: "30px";
}

@media only screen and(min-device-width: 1920) and (max-device-width: 1745) {
  .Activity-card {
    display: flex !important;
    padding: 10px;
    min-height: 44%;
    //  height:269px;
  }
  .Activity-cardheader {
    padding: 12px;
  }
  //  .Activity-cardbody{
  //   padding: 19px;
  //   // margin-left: 10px;
  //   }
  .dashboardstatus {
    font-size: 11px !important;
  }

  .card .card-body {
    padding: 15px;
    height: 1px;
    background-color: transparent;
  }
  // .Activity-cardbody .card-body{
  //   // padding-top : "30px"
  //   padding:0px;
  //   padding-top: 26px;

  // }
  .Activity-cardbody .card-body {
    padding: 0px;
    padding-top: 26px;
  }
  // .MuiGrid-root .MuiGrid-container .MuiGrid-spacing-xs-1 .Main-grid .css-a5rdam-MuiGrid-root{
  //   padding: 0px;
  //   padding-top: 0px;
  // }
}
// }
// @media only screen
// and (min-device-width : 700px)
// and (max-device-width : 2080px)
// {

// }
// @media only screen
// and (min-device-width : 700px)
// and (max-device-width : 2080px)
// {

// }
// .Overall-customer-cardbody{
//   padding: 5px;
// //  height:  28%;
//  margin-left: 10px;
//  margin-top: -43px;

//  }
.Overall-customer-card {
  display: flex !important;
  padding: 10px;
  height: 269px;
}
.Overall-customer-cardheader {
  padding: 8px;
}

.helpdesk {
  margin-top: 25px;
}
// @media only screen
// and (min-device-width : 700px)
// and (max-device-width : 900px)
// {

// }
// @media only screen and (min-device-width: 1097px){
//   .Overall-customer-card{
//    display: flex !important;
//    padding: 0px;
//    height: 212px;

//   }
//   .Overall-customer-cardbody{
//   padding: 0px;

//   }
//   .card .card-body{
//     padding: 0px;
//   }
//   .css-a5rdam-MuiGrid-root>.MuiGrid-item{
//     padding-left: 4px;
//     margin-top: 2px;
//   }
//   .Customer-main-grid{
//     padding-right: 35px;
//     padding-top: 10px;
//   }
//   .dashboardstatus{
//     font-size: 7px !important;
//   }
//   // .Customer-bandwidth-usage-text{
//   //   font-size: 9px !important;
//   // }
// }
// @media only screen and (min-device-width: 1097px){
//   .Activity-card{
//     display: flex !important;
//     padding: 0px;
//   //  min-height: 46%
//    height:212px;
//   }
//   .css-a5rdam-MuiGrid-root>.MuiGrid-item {
//     padding-left: 0px;
//   }
//   .activity-dashboardstatus{
//     font-size: 7px !important;
//   }
//   .Activity-cardbody{
//     // padding: 15px;
//     margin: 2px;
//     margin-left: 13px;
//     margin-right: 11px;
//     }
//     .activity-dashboardstatus-grid{
//       font-size: 7px !important;
//       text-align: end;
//     }
//     // .activation{
//     //   position: relative;
//     //   top:9px;
//     //   left: 97px;
//     // }
//     // .expiry{
//     //   text-align: end;
//     // }
// }
// // @media only screen
// and (min-device-width : 700px)
// and (max-device-width : 3000px)
// {

// }

// @media only screen
// and (min-device-width : 700px)
// and (max-device-width : 1500px)
// {
//   .dashboardstatus{
//     font-size: 11px !important;

//       }
// }
// @media only screen
// and (min-device-width : 700px)
// and (max-device-width : 2000px)
// {
//   .activity-dashboardstatus{
//     font-size: 9px !important;
//     border: 2px solid red;
//     // height:20px;
//       }
// }
// @media only screen
// and (min-device-width : 1460px)
// and (max-device-width : 1600px)
// {
//   .activity-dashboardstatus{
//     font-size: 5px !important;
//     color: green;
//     // border: 2px solid red;
//     // height:20px;
//       }
// }
// @media only screen
// and (min-width : 1200px)
// and (max-width : 1350px)
// {
//   .activity-dashboardstatus{
//     font-size: 5px !important;
//   color: blue;
//     // height:20px;
//       }
// }

// @media screen and (min-width: 900px){
//   .dashboardstatus{
//     font-size: 11px;
//       }
// }

// @media only screen and (max-width: 1669){
// .
// }
// @media (min-width: 1200px){

// }
@media only screen and (min-device-width: 700px) and (max-device-width: 1500px) {
  .bandwidth-usage-text {
    font-size: 15px !important;
    // border: 2px solid black;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .dashboardstatus {
    font-size: 7px !important;
  }
}

@media screen and (min-width: 700px) {
  .bandwidth-usage-text {
    font-size: 15px !important;
  }
}

// @media screen and (min-width: 700px) and (max-width: 1500px){
// .bandwidth-usage-text{
//   font-size: 15px;
//   border: 2px solid black;
// }}

@media (max-width: 1281px) {
  .dashboardstatus {
    font-size: 7px !important;
  }
}

@media (max-width: 1281px) {
  .bandwidth-usage-text {
    font-size: 9px !important;
  }
}

// .rbt .rbt-input-main .form-control .rbt-input{
//   font-size:30px;
//   border: none;

// }
// .css-a5rdam-MuiGrid-root>.MuiGrid-item {
//   text-align: center !important;
// }
// .css-a5rdam-MuiGrid-root>.MuiGrid-item

// customer search

// Media Queries by Marieya
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #f5f5f5 !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: "";
}
.adminUser {
  margin-top: 56px;
}
.wallet {
  margin-top: 44px !important;
}
.billing {
  margin-top: 114px !important;
}
.data-table-size {
  margin-top: 50px !important;
}

@media screen and (max-width: 1566px) and(min-width: 700px) {
  .column12 {
    position: relative;
    top: 4em !important;
    right: 169%;
  }
}
@media screen and (max-width: 1566px) and(min-width: 700px) {
  .column1 {
    position: relative;
    top: 4em !important;
    right: 147%;
  }
}
@media screen and (max-width: 1566px) and(min-width: 700px) {
  .column2 {
    position: relative;
    top: 4em !important;
    right: 146%;
  }
}

@media screen and (max-width: 700px) and(min-width: 541px) {
  .column12 {
    position: relative;
    top: -8px !important;
    right: 169%;
    //  background-color: #0052AC !important;
  }
}
@media screen and (max-width: 700px) and(min-width: 541px) {
  .column2 {
    position: relative;
    top: -8px !important;
    right: 145%;
    //  background-color: #0052AC !important;
  }
}
@media screen and (max-width: 700px) and(min-width: 541px) {
  .column1 {
    position: relative;
    top: -13px !important;
    right: 159%;
    //  background-color: #0052AC !important;
  }
}
@media screen and (max-width: 541px) and(min-width: 400px) {
  .column12 {
    position: relative;
    top: -8px !important;
    right: 167%;
    //  background-color: #0052AC !important;
  }
}
@media screen and (max-width: 541px) and(min-width: 400px) {
  .column1 {
    position: relative;
    top: -8px !important;
    right: 147%;
    //  background-color: #0052AC !important;
  }
}
@media screen and (max-width: 541px) and(min-width: 400px) {
  .column2 {
    position: relative;
    top: -8px !important;
    right: 145%;
    //  background-color: #0052AC !important;
  }
}
// @media screen and (max-width: 541px)and(min-width: 400px) {
//   .column12{
//    position: relative;
//    top: -47px !important;
//    right: 169%;
//   //  background-color: #0052AC !important;
// }
//   }
@media screen and (max-width: 986px) and (min-width: 300px) {
  .css-e53awj-MuiStack-root {
    padding-top: 16px;
  }
}
// }
@media screen and (max-width: 1250px) and (min-width: 400px) {
  .css-e53awj-MuiStack-root > :not(style) + :not(style) {
    display: inline-flex;
    margin: 1%;
    height: auto;
    width: auto;
    margin-right: -2px !important;
    padding: 0px !important;
  }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
  .css-e53awj-MuiStack-root > :not(style) + :not(style) {
    display: inline-flex;
    margin: 1%;
    height: auto;
    width: auto;
    margin-right: -2px !important;
    padding: 0px !important;
  }
}
@media screen and (max-width: 500px) and (min-width: 450px) {
  .css-e53awj-MuiStack-root > :not(style) + :not(style) {
    display: inline-flex;
    height: auto;
    width: auto;
    padding: 0px !important;
  }
}
@media screen and (max-width: 430px) and (min-width: 300px) {
  .css-e53awj-MuiStack-root > :not(style) + :not(style) {
    display: inline-flex;
    height: auto;
    width: auto;
    padding: 0px !important;
  }
}
// @media screen and (max-width: 1000px) and (min-width: 500px){
//   // @media all and (max-width:30em){
//   .css-e53awj-MuiStack-root>:not(style)+:not(style){
//   display: inline-flex;
//   margin: 1%;
//   // font-size: 7px;
//   // height: 4.5em;
//   // max-width: 311px;
//   width: auto;
//   margin-right: -7px !important;
//   padding: 0px !important;
//   // display: block;
//   //   margin:0.2em auto;
// }
// }
// @media screen and (max-width: 700px){
//   // @media all and (max-width:30em){
//   .css-e53awj-MuiStack-root>:not(style)+:not(style){
//   display: inline-flex;
//   margin: 1%;
//   font-size: 8px;
//   height: 4.5em;
//   max-width: 336px;
//   width: 33%;
//   margin-right: -7px !important;
//   padding: 0px !important;
//   // display: block;
//   //   margin:0.2em auto;
// }
// }
@media screen and (max-width: 1250px) and (min-width: 700px) {
  .css-1vm0r4s-MuiPaper-root {
    width: 86% !important;
  }
}
@media screen and (max-width: 700px) and (min-width: 440px) {
  .css-1vm0r4s-MuiPaper-root {
    position: absolute;
    top: 13%;
    left: 5%;
  }
  .icofont-plus {
    position: relative;
    right: 7px;
    margin: -10px;
  }
  .helpdesk {
    margin-top: 18%;
  }
  .css-1aonq97 {
    margin-top: 11px !important;
  }
  .adminUser {
    margin-top: 105px !important;
  }
  .css-heg063-MuiTabs-flexContainer {
    margin-top: 80px;
  }
  .wallet {
    margin-top: 145px !important;
  }
  .billing {
    margin-top: 150px !important;
  }
  .data-table-size {
    margin-top: 96px !important;
  }
  .rolestatus {
    position: relative !important;
    top: 81px !important;
    right: 40px;
  }
}
@media screen and (max-width: 440px) and (min-width: 300px) {
  .css-1vm0r4s-MuiPaper-root {
    position: absolute;
    top: 13%;
    left: 4%;
    width: 91% !important;
  }
  .icofont-plus {
    position: relative;
    right: 7px;
    margin: -10px;
  }
  .helpdesk {
    margin-top: 27% !important;
  }
  .adminUser {
    margin-top: 105px !important;
  }
  .css-heg063-MuiTabs-flexContainer {
    margin-top: 80px;
  }
  .wallet {
    margin-top: 145px !important;
  }
  .billing {
    margin-top: 100px !important;
  }
  .data-table-size {
    margin-top: 96px !important;
  }
  .rolestatus {
    position: relative !important;
    top: 81px !important;
    right: 40px;
  }
}

@media screen and (max-width: 1250px) and (min-width: 700px) {
  input::-webkit-input-placeholder {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 700px) and (min-width: 400px) {
  input::-webkit-input-placeholder {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 630px) and (min-width: 400px) {
  .css-8b4vl3-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    left: 1% !important;
  }
}

@media screen and (max-width: 1250px) and(min-width: 430px) {
  .btn-primary {
    height: 50px !important;
    font-size: 15px !important;
  }
  .css-gcc2o7-MuiButton-startIcon > *:nth-of-type(1) {
    position: relative;
    left: 25%;
  }
}
@media screen and (max-width: 430px) and(min-width: 300px) {
  .btn-primary {
    margin: 3px !important;
  }
}
@media (max-width: 656px) {
  .button-text {
    display: none;
  }
  .css-gcc2o7-MuiButton-startIcon > *:nth-of-type(1) {
    position: relative !important;
    left: 6px !important;
  }
}

@media (max-width: 700px) and (min-width: 600px) {
  .css-1fkm8gk-MuiPaper-root {
    width: 77% !important;
    position: relative;
    left: 114px;
  }
}
@media (max-width: 600px) and (min-width: 521px) {
  .css-1fkm8gk-MuiPaper-root {
    width: 96% !important;
    margin-right: -82px;
  }
}
@media (max-width: 521px) and (min-width: 490px) {
  .css-1fkm8gk-MuiPaper-root {
    width: 100% !important;
    margin-right: -109px;
  }
}
@media (max-width: 489px) and (min-width: 440px) {
  .css-1fkm8gk-MuiPaper-root {
    width: 92% !important;
    margin-right: -128px;
    margin-left: 9%;
  }
}
@media (max-width: 440px) and (min-width: 300px) {
  .css-1fkm8gk-MuiPaper-root {
    width: 77% !important;
    margin-right: -128px;
    margin-left: 24%;
  }
  .css-1480iag-MuiInputBase-root-MuiInput-root {
    margin-right: 35px !important;
    position: relative !important;
    left: 44px !important;
  }
}

// sudha new design

.muibuttons {
  border: none !important;
  color: #000000 !important;
  margin-left: 0px !important;
}

.all_cust {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}
/* Sailaja Changed Style (Line Number 8755) on 13th July */

.data_table {
  background: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
  // padding-top: 5px;
}

.css-70ui05-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: #e4edf7;
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 12px;
}
// .css-70ui05-MuiButtonBase-root-MuiTab-root{
//   min-height: 30px !important;
//   border-radius: 20px !important;
//   border: 1px solid rgba(40, 82, 149, 0.5) !important;
//   font-weight: 600 !important;
//   font-size: 12px !important;
// }

.customer_tabslist {
  min-height: 30px !important;
  border-radius: 20px !important;
  border: 1px solid rgba(40, 82, 149, 0.5) !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
//Marieya
@media screen and (max-width: 1700px) and (min-width: 960px) {
  .pl-0 {
    width: 50px;
  }
  //   .horizontal-menu{
  //     // width: 50px;
  //     background-color: blue !important;
  //   }
  //   .mega-menu .outside{
  //     width: 50px;
  //   }
}
@media screen and (max-width: 1920px) and (min-width: 1745px) {
  .pl-0 {
    width: 50px;
  }
}
.cust_details_new {
  font-weight: 600;
  font-size: 15px;
  font-family: "Open Sans";

}
.childBlock {
  min-height: 35px;
  border: 1px solid #a79e9e;
  padding: 8px 5px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
}

.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-bordered .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
.cust_details_new_1 {
  // font-weight: 600;
  font-size: 15px;
  font-family: "Open Sans";
}
.cust_details {
  font-weight: 600;
  font-size: 12px;
  font-family: "Open Sans";
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.ant-divider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum","tnum";
  background: #e8e8e8;
}

.ant-card-bordered {
    border: 1px solid #e8e8e8;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(232, 232, 232);
    border-right-color: rgb(232, 232, 232);
    border-bottom-color: rgb(232, 232, 232);
    border-left-color: rgb(232, 232, 232);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}
.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}


.ant-row {
    position: relative;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    zoom: 1;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.paddingTop10 {
  padding-top: 10px;
}
// customer360 action buttons styles
.cust_action {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
  border-radius: 8px !important;
  border: 2px solid #285295 !important;
}
.device_font{
  font-size: 13px;
  padding: 5px 0;
  text-transform: capitalize;
  height: 30px;
  font-weight: 500;
}
//  complaint_recharts

/// new working media queries

// @media screen and (max-width: 1536px) and (min-width: 1280px) {
//   .complaint_recharts {
//     width: 560px;
//     height: 400px;
//     padding-bottom: 25%;
//   }
// }
// @media screen and (max-width: 1280px) and (min-width: 1097px) {
//   .complaint_recharts {
//     width: 450px;
//     height: 350px;
//     padding-bottom: 25%;
//   }
// }

// @media screen and (max-width: 1800px) and (min-width: 1700px) {
//   .complaints_recharts {
//     .recharts-surface {
//       width: 811px;
//       height: 402px;
//     }
//   }
// }

// @media screen and (max-width:1800px) and (min-width:1536px) {
//   .recharts-surface{
//     width: 811px;
//     height: 402px;
//   }
// }
.complaint_deatils {
  margin-left: 20px;
  margin-top: 5px;
}

@media screen and (max-width: 1800px) and (min-width: 1700px) {
  .complaint_deatils {
    margin-left: 20px;
    margin-top: 5px;
    margin-left: 17%;
    margin-top: 5%;
  }
}

@media screen and (max-width: 1745px) and (min-width: 1536px) {
  .complaint_deatils {
    margin-left: 20px;
    margin-top: 5px;
    margin-left: 17%;
    margin-top: 5%;
  }
}
//complaints bar chart
@media screen and (max-width: 2000px) and (min-width: 1800px) {
  .complaint_recharts {
    .recharts-surface {
      width: 100%;
      height: 100%;
      // margin-left: -10%;
      // margin-top: -8%;
      // background-color: pink;
    }
  }
}

// .complaint_recharts {
//   width: 650px !important;
// }
@media screen and (max-width: 1800px) and (min-width: 1600px) {
  .complaint_recharts {
    .recharts-surface {
      width: 100%;
      height: 100%;
      margin-left: -10%;
      // margin-top: -14%;
      // background-color: #0019d2;
    }
  }
}
@media screen and (max-width: 1600px) and (min-width: 1500px) {
  .complaint_recharts {
    .recharts-surface {
      width: 100%;
      height: 100%;
      margin-left: -25%;
      margin-top: -2%;
    }
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .complaint_recharts {
    .recharts-surface {
      width: 100%;
      height: 100%;
      margin-left: -20%;
      margin-top: -2%;
    }
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .complaint_recharts {
    .recharts-surface {
      width: 100%;
      height: 90%;
      margin-left: -28%;
      // background-color: yellow;
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .complaint_recharts {
    .recharts-surface {
      width: 100%;
      height: 90%;
      margin-left: -35%;
    }
  }
}

// @media screen and (max-width: 1536px) and (min-width: 1280px) {
//   .recharts-surface {
//     width: 570px !important;
//     height: 415px !important;
//     margin-left: -2% !important;
//   }
// }
// @media screen and (max-width: 1280px) and (min-width: 1097px) {
//   .recharts-surface {
//     width: 486px !important;
//     height: 415px !important;
//     margin-left: -2% !important;
//   }
// }
@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .complaints_status_font {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    color: #1e265e;
    position: relative;
    top: -2px;
    left: 10px;
    opacity: 0.8;
  }
  .complaints_status_font_count {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    color: #1e265e;
    position: relative;
    top: -2px;
    left: 32px;
    opacity: 0.8;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .complaints_status_font {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    color: #1e265e;
    position: relative;
    top: -2px;
    left: 10px;
    opacity: 0.8;
  }
  .complaints_status_font_count {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    color: #1e265e;
    position: relative;
    top: -2px;
    left: 32px;
    opacity: 0.8;
  }
}

@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .hr_complaints {
    box-sizing: content-box;
    height: 0px;
    overflow: visible;
    margin-left: 43px;
    margin-right: 0px;
    position: relative;
    left: -47px;
    border: 1px solid #cdcdcd !important;
    margin-top: 0px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .hr_complaints {
    box-sizing: content-box;
    height: 0px;
    overflow: visible;
    margin-left: 43px;
    margin-right: -40px;
    position: relative;
    left: -47px;
    border: 1px solid #cdcdcd !important;
    margin-top: 0px;
  }
}
@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .complaint_deatils {
    margin-left: 29px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .complaint_deatils {
    margin-left: 29px;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .Britechart {
    position: relative;
    left: -24px !important;
    top: 26px !important;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .Britechart {
    position: relative;
    left: -40px !important;
    top: 26px !important;
  }
}

// @media screen and (max-width: 1536px) and (min-width: 1280px) {
//   .network_recharts {
//     .recharts-surface {
//       width: 328px !important;
//       height: 245px !important;
//       margin-top: -3% !important;
//       margin-left: -1% !important;
//     }
//   }
// }

// @media screen and (max-width: 1280px) and (min-width: 1097px) {
//   .network_recharts {
//     .recharts-surface {
//       width: 261px !important;
//       height: 245px !important;
//       margin-top: -3% !important;
//       margin-left: -1% !important;
//     }
//   }
// }
@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .complaints_status_font {
    font-family: "Open Sans" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #1e265e !important;
    position: relative;
    top: -2px;
    left: 10px;
    opacity: 0.8;
  }
  .complaints_status_font_count {
    font-family: "Open Sans" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #1e265e !important;
    position: relative;
    top: -2px;
    left: 32px;
    opacity: 0.8;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .complaints_status_font {
    font-family: "Open Sans" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #1e265e !important;
    position: relative;
    top: -2px;
    left: 10px;
    opacity: 0.8;
  }
  .complaints_status_font_count {
    font-family: "Open Sans" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #1e265e !important;
    position: relative;
    top: -2px;
    left: 32px;
    opacity: 0.8;
  }
}

@media screen and (max-width: 1536px) and (min-width: 1373px) {
  .payment-col-media {
    position: relative;
    font-family: "Open Sans";
    font-weight: 500;
    // left:-17px;
    color: #5f6068;
    font-size: 14px;
  }
  .payment-col {
    position: relative;
    font-family: "Open Sans";
    font-weight: 500;
    // left:-17px;
    color: #5f6068;
    font-size: 14px;
  }
  .font-col {
    color: #828386;
    font-size: 15px;
    position: relative;
    left: 19px;
    top: -3px;
  }
}
@media screen and (max-width: 1373px) and (min-width: 1280px) {
  .payment-col-media {
    position: relative;
    font-family: "Open Sans";
    font-weight: 500;
    // left:-17px;
    color: #5f6068;
    font-size: 12px;
  }
  .payment-col {
    position: relative;
    font-family: "Open Sans";
    font-weight: 500;
    // left:-17px;
    color: #5f6068;
    font-size: 12px;
  }
  .font-col {
    color: #828386;
    font-size: 15px;
    position: relative;
    left: 19px;
    top: -3px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .payment-col-media {
    position: relative;
    font-family: "Open Sans";
    font-weight: 500;
    // left:-17px;
    color: #5f6068;
    font-size: 12px;
  }
  .payment-col {
    position: relative;
    font-family: "Open Sans";
    font-weight: 500;
    // left:-17px;
    color: #5f6068;
    font-size: 12px;
  }
  .font-col {
    color: #828386;
    font-size: 15px;
    position: relative;
    left: 19px;
    top: -3px;
  }
}
// .
@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .progress-col {
    position: relative;
    top: 6px;
    left: 5px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .progress-col {
    position: relative;
    top: 6px;
    left: 5px;
  }
}
@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .view_results {
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    position: relative !important;
    top: -48px !important;
    left: 8px !important;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1097px) {
  .view_results {
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    position: relative !important;
    top: -48px !important;
    left: 8px !important;
  }
}
//end
.view_results {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: -48px;
  left: 8px;
}
.complaints_status_font {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 14px;
  color: #1e265e;
  opacity: 0.8;
  position: relative;
  top: -2px;
  left: 10px;
}
.complaints_status_font_count {
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 14px;
  color: #1e265e;
  opacity: 0.8;
  position: relative;
  // top: -2px;
  left: 32px;
}
@media screen and (max-width: 1745px) and (min-width: 1536px) {
  .complaints_status_font {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    color: #1e265e;
    opacity: 0.8;
    position: relative;
    top: -2px;
    left: 10px;
  }
  .complaints_status_font_count {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    color: #1e265e;
    opacity: 0.8;
    position: relative;
    top: -2px;
    left: 32px;
  }
}

@media screen and (max-width: 1745px) and (min-width: 1536px) {
  .Britechart {
    position: relative;

    top: 18px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1745px) {
  .Britechart {
    position: relative;

    top: 18px;
  }
}

@media screen and (max-width: 1373px) and (min-width: 1279px) {
}

// customer details passowrd
.cust_pwd {
  border: 2px solid rgb(40, 82, 149);
  border-radius: 8px;
  height: 44px;
  position: relative;
}

.dLQYrt {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.complaint_count {
  font-size: 25px;
  text-align: left;
  position: relative;
  left: 15px;
}
.customer_count {
  font-size: 25px;
  text-align: left;
  position: relative;
  left: 3px;
}
#newbuuon {
  white-space: nowrap;
  font-size: medium;
  width: 93px;
  height: 40px;
  background-color: #285295 !important;
  border-radius: 6px;
}
.button-text {
  font-weight: 700;
  font-size: 16px;
}

.Table_columns {
  font-weight: 600;
  font-size: 12px;
  font-family: "Open Sans";
  // width: 131px !important;
  // width: max-content;
}

.First_Letter1 {
  text-transform: capitalize;
}

.First_Letter {
  text-transform: lowercase;
}

.First_Letter:first-letter {
  text-transform: uppercase;
}
.cust_table_columns {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  // padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 0px solid;
  // padding: 5px 16px;
}

.total_count {
  // font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  left: 10px;
}

.total_count_new {
  // font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  // position: relative;
  // margin-left: 57px;
}

.device_row {
  margin-left: 1pc;
  padding-top: 13px;
  font-weight: 500;
}   
.total_count_cust{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  position: relative;
  bottom: -6px;

}

.total_test {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #285295;
  position: relative;
  top: 4px;
  left: 10px;
}
.total_reports_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #285295;
  position: relative;
  top: 4px;
  left: 10px;
  // white-space: nowrap;
}
.total_test_cust{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  padding-left: 4px;
  padding-top: 4px;
  white-space: nowrap;
  // position: relative;
  // top: 4px;
  // left: 10px;
}

.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-left: 4px;
  white-space: nowrap;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
.count_cards {
  border-radius: 4px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  // padding: 15px;
  // padding: 22px 20px 15px 16px;
  background-color: rgba(228, 237, 247, 0.75) !important;
}
.count_cards_new {
  border-radius: 4px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  border: 3px solid #ec822f !important;
}
.count_capacity {
  border-radius: 4px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  border: 3px solid #00f !important;
}
.count_slots {
  border-radius: 4px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  border: 3px solid #ed5757 !important;
}
.count_image {
  border-radius: 4px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  border: 2px solid #a79e9e !important;
}
.device_centre{
  text-align: center;
}
// Sailaja fixed position for Service Plan -> Internet-> Edit-> Subplancard on 5th August Ref SP_29
.subplan_card {
  border-radius: 4px !important;
  border: 1px solid #cccccc;
  // padding: 15px;
  padding: 22px 20px 15px 16px;
  box-shadow: none;
  position: relative;
  left:9%;
  bottom:18%;
}

.subplan_card:hover {
  box-shadow: 0 0 10px #ccc;
  border: 1px solid #80beff;
  border-radius: 5px;
}
.payment_symbol {
  margin-right: 7px;
}
// .css-bhp9pd-MuiPaper-root-MuiCard-root{
//   background-color: rgba(228, 237, 247, 0.75) !important;

// }
//Sailaja decreased searchbar width from 400px to 325px on 11th July Ref LED-02
.mac_bar {
  padding: 2px -1px;
  display: flex;
  align-items: center;
  width: 276px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  height: 40px;
}

.search_bar {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 325px !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  height: 40px;
}
.session_report_search_bar {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 8rem !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  height: 40px;
  margin-top: 30px;
  margin-left: -1rem;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: #000000 !important;
}
#role_permission {
  background: white !important;
  color: #000000;
  border: 2px solid #285295 !important;
  height: 40px;
}

.more_actions {
  color: #377df6;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.more_actions1 {
  color: #b3b0b0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
// create button

#create_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 114px;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
}
.uploadimagekyc {
  position: relative;
  background-color: #285295;
  padding: 8px 23px;
  top: 18%;
  color: #fff;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  border-radius: 6px;
}
#captureimagekyc {
  position: relative;
  background-color: #285295;
  padding: 6px 19px;
  top: 7%;
  color: #fff;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  border-radius: 6px;
}
.assign {
  padding-left: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  position: relative;
  top: -25px;
}
// Sailaja fixed Service Plan -> Internet-> Edit-> Subplancard on 5th August Ref SP_29
#sub_plan_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  position: relative;
  bottom:36%;
}
#reset_olt{
  width: auto;
  position: relative;
  right: 20%
}
// Sailaja added submit button loader on 11th May 2023
#submit_button_loader{
  white-space: nowrap;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  height: 40px;
  background-color: #285295 !important;
  border-radius: 6px;
  color: white;

}

#submit_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 103px;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
  color: white;
}
#Add_money {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 136px;
  height: 38px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
}
#migrate_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 103px;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
  color: white;
  padding-left:22px
}
#sub_plan {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 145px;
  height: 38px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
}
// reset id
#resetid {
  background-color: white !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 2px solid #285295 !important;
}
#nas_id {
  background-color: white !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 2px solid #285295 !important;
  padding-left: 19px;
}
#network_resetid {
  background-color: white !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  width: 108px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 2px solid #285295 !important;
}

#new_complaints {
  background-color: white !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: 2px solid #285295 !important;
}
//update button
#update_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 109px;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
}
#accept_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 179px;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
}

#download_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
}
// css added by Marieya for disabled download btn 
#download_button1 {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
}
#download_button1:disabled{
  // button[disabled]{
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: white !important;
  }

// save button added by sailaja to apply for loader Save buttonson 11th May 2023
#save_button_loader {
  white-space: nowrap;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  height: 40px;
  background-color: #285295 !important;
  border-radius: 6px;
}
// save button

#save_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 95px;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
  color: white;
}
#yes_button {
  white-space: nowrap;
  // font-size: medium;
  // line-height: 22px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
  width: 95px;
  height: 40px;
  /* width: 52px; */
  /* height: 40px; */
  background-color: #285295 !important;
  border-radius: 6px;
  color: white;
}


.modal_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #000000;
}
//global header line
// .css-1480iag-MuiInputBase-root-MuiInput-root
// ::before{
//   border-bottom: none !important;
// }

.css-1480iag-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}
.css-a88p61-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}
//close icon
#icon_close {
  color: #000000 !important;
  // top: 18px;
  // right: 63px
}
// Sailaja added 9752,9753 Lines Ref LED-11 on 13th July
#edit_icon {
  color: #000000 !important;
  top: 18px !important;
  right: 63px !important;
}
// border bottom for sidepanel
.sidepanel_border {
  border-bottom: 1px solid #c4c4c4 !important;
  margin-left: 14px;
  margin-right: 0px;
  width: 268%;
  height: 14px;
  margin-bottom: 20px;
}
.sidepanel_border1 {
  border-bottom: 1px solid #c4c4c4 !important;
  margin-left: 14px;
  margin-right: -17px;
  width: 268%;
  height: 14px;
  margin-bottom: 20px;
}
.sign_border {
  border-bottom: 1px solid #c4c4c4 !important;
  margin-left: -5px;
  margin-right: -39%;
  width: 268%;
  height: 61px;
  margin-bottom: 26px;
}
.list_email {
  color: #285295;
}
.last_typography {
  color: #000000;
  font-size: 14px !important;
  font-family: "Open Sans" !important;
  font-style: "normal" !important;
  font-weight: 400 !important;
}
.navigate_icon {
  color: #a6a6a6;
}
.css-jpln7h-MuiTabs-scroller {
  margin-bottom: 33px !important;
}
// .sc-ezWOiH{

//   position: relative;

//   left: -64%;

//   }

//   .sc-cxabCf{

//   position: relative;

//   left: -64%;

//   }

//   .sc-llJcti{

//   position: relative;

//   top: 82px;

//   border: none !important;

//   }

// .fFWdem{
//   position: relative;
//   left: -75%;
//   top: 65px;
// }
// .eoAgOz{
//   position: relative;
//   left: -74.5%;
//   top: 65px;
//   border: 1px solid #CCCCCC;
//   border-radius: 5px;
// }
// .cOgvKH{
//   position: relative;
//   top: 67px;
// }
// .kBZuEM{
//   position: relative;
//   top: 67px;
// }
.table_details {
  border-bottom: 1px solid #e5e5e5 !important;
}

.table_header {
  border-top: none !important;
  border-bottom: 2px solid #e5e5e5 !important;
}
.label_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  position: relative;
  left: 3px;
}
.react-datepicker-wrapper {
  margin-top: 10px;
}
.kNhmTq:hover:not(:disabled) {
  fill: white !important;

  background-color: #285295 !important;
}
.form_label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  bottom: 64px;
  left: 4px;
}
.desc_label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 4px;
}
.kyc_label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  // bottom: 64px;
  left: 4px;
}
.macIdInput{
  width: 25px;
  border: 1px solid #000 !important;
  outline: none !important;
  box-shadow: 0 0 2px #007bff;
}
.nas_label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  bottom: 0px;
  left: 4px;
}
.cust_label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  bottom: 64px;
  left: 4px;
  bottom: 25px;
}
.notes {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  bottom: 64px;
  left: 4px;
  bottom: 115px;
}
.nas_field_strength {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  left: 5px;
  bottom: 7px;
}
#notes_moveup {
  position: relative;
  top: -63px;
}
.pass_notes {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
}

// franchise tabs css -sudha
.border-tab.nav-tabs .nav-item .nav-link.active {
  background: #e4edf7 !important;
  border: 1px solid rgba(40, 82, 149, 0.5) !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  min-height: 30px !important;
}

.border-tab.nav-tabs .nav-item .nav-link {
  border: 1px solid rgba(40, 82, 149, 0.5) !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  min-height: 30px !important;
}
.customer_details {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.cust_Header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

// .MUpkg {
//   background-color: rgba(196, 196, 196, 0.15) !important;
// }

// invoice lis

.invoice_list {
  .rdt_TableHeadRow {
    .rdt_TableCol:nth-child(-n + 8) {
      background-color: rgba(196, 196, 196, 0.15) !important;
      // z-index: 1;
    }
  }
}

// .rdt_TableHeadRow * {
//   font-family: "Roboto","Helvetica","Arial",sans-serif !important;
//   font-weight: 500 !important;
//   font-size: 0.875rem !important;
//   }

// Sailaja changed Font-size as 12px on 19th July
.CPETable_columns {
  font-weight: 600;
  font-size: 12px;
  font-family: "Open Sans";
  width: 667px;
}
.CustomerTable_columns {
  font-weight: 600;
  // font-size: 14px;
  font-family: "Open Sans";
  // white-space: nowrap;
  // width: 570px;
}

#table_coulmns_width .ReportsTable_columns {
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans";
  width: 804px;
}
.Reports_AccTable_columns {
  font-weight: 600;
  font-size: 14px;
  font-family: "Open Sans";
  width: 227px;
}
// Sailaja Changed  font-size as 12px on 19th July
.NASTable_columns {
  font-weight: 600;
  font-size: 12px;
  font-family: "Open Sans";
  width: 175px;
}
#roles_select {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #377df6;
}

// css for radio checkboxes

// body {
//   background-color: #f1f2f3;
//   -webkit-box-align: center;
//       -ms-flex-align: center;
//           align-items: center;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
// }

// .container {
//   margin: 0 auto;
// }

// .round {
//   position: relative;
// }

// .round label {
//   background-color: #fff;
//   border: 1px solid #285295 ;
//   border-radius: 50%;
//   cursor: pointer;
//   height: 28px;
//   left: 0;
//   position: absolute;
//   top: 0;
//   width: 28px;
// }

// .round label:after {
//   border: 2px solid #fff;
//   border-top: none;
//   border-right: none;
//   content: "";
//   height: 6px;
//   left: 7px;
//   opacity: 0;
//   position: absolute;
//   top: 8px;
//   transform: rotate(-45deg);
//   width: 12px;
// }

// .round input[type="checkbox"] {
//   visibility: hidden;
// }

// .round input[type="checkbox"]:checked + label {
//   background-color: #377DF6;
//   border-color: #377DF6;
// }

// .round input[type="checkbox"]:checked + label:after {
//   opacity: 1;
// }

//
.checkbox_header {
  // position: relative;
  //   left: 1px;
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  white-space: nowrap !important;
  left: 0px !important;
}
.rounded-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #285295;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.payment-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #000000;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: #285295;
  font-family: "Open Sans";
}

.checked_paymentgateway:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: #4ecb71;
  font-family: "Open Sans";
}
.new-checkbox {
  width: 15px;
  height: 15px;
  // border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #285295;
  // appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.new-checkbox:checked {
  appearance: auto;
  // clip-path: circle(50% at 50% 50%);
  background-color: #285295;
  font-family: "Open Sans";
}
.input[type="checkbox"] {
  background-color: red !important;
}
.bynnNu {
  display: none !important;
}

// css for New KYC Form by Marieya
.MuiAppBar-colorPrimary {
  // color: black !important;
  background-color: white !important;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.MuiTab-textColorInherit {
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  /* identical to box height */

  text-align: center !important;
}
.PrivateTabIndicator-colorSecondary-3 {
  background-color: #285295 !important;
}

.PrivateTabIndicator-colorSecondary-7 {
  background-color: #285295 !important;
}
// .button:not(:disabled),[type="button"]:not(:disabled) {
// color: #333333 !important;
// }
.personalDetails {
  color: #000000;
}
.ServiceDetails {
  color: #d20000;
}
.PaymentDetails {
  color: #0019d2;
}
.add_cust {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 27px;
  margin-left: -2%;

  color: #000000;
}
.info_cust {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 27px;
  margin-left: 1px;
  top: -19px;
  position: relative;
  color: #000000;
}
.permanent_address {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 27px;
  margin-left: -6px;
  // top: 403%;
  position: relative;
  color: #000000;
}
.form-icon-container {
  position: relative;
  /* border: 2px dotted gray; */
  border-radius: 50%;
  height: 160px;
  // width: 200px;
  // left: 45%;
  // transform: translate(-50%);
  // border: 5px solid #A6A6A6;
}

.photo_box {
  position: absolute;
  width: 267px;
  height: 66px;
  left: -1px;
  top: 204px;
  background: #ecf0f2;
  border: 1px dashed #b9c2cb;
}

.photo_box_id_proof {
  width: 288px;
  height: 150px;
  background: #ecf0f2;
  border: 1px dashed #b9c2cb;
}

.add_customer {
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  margin-left: 9px;
}
// .MuiTabs-indicator{
//   left: 0px !important;
//   width: 163px !important;
//   height: 3px !important;
// }
#new_add {
  background-color: #ecf0f2 !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 3px solid #285295 !important;
  position: relative;
  left: 9%;
  top: 19%;
  padding-left: 7%;
  padding-right: 7%;
  padding-left: 11%;
}
#new_add_1 {
  background-color: #ecf0f2 !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 3px solid #285295 !important;
  position: relative;
  left: -3%;
  top: 45%;
  padding-right: 10%;
  padding-left: 10%;
}
#new_add_2 {
  background-color: #ecf0f2 !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 3px solid #285295 !important;
  position: relative;
  top: 45%;
  padding-right: 10%;
  padding-left: 10%;
}
.Add_btn {
  margin-top: 20px;
  position: relative;
  left: -7px;
}
.Add_btn_1 {
  margin-top: 21px;
  position: relative;
  top: 2%;
  left: 1%;
}
.Add_btn_2 {
  margin-top: 20px;
  position: relative;
  left: 49%;
}
.signature_box {
  position: relative;
  width: 431px;
  height: 160px;
  top: 5.4em;
  background: #ecf0f2;
  border: 1px dashed #b9c2cb;
  // left: -14%;
}
#kyc_radio {
  // display: contents;
  position: relative;
  left: 25%;
  top: -15px;
}
#kyc_radio1 {
  position: relative;
  left: 10px;
  top: -15px;
}
#radio_btn {
  position: relative;
  left: -11px;
}

.adhaar_input {
  // width: 89%;
  height: 151px;
  position: relative;
}
.address_input {
  width: 401%;
  height: 173px;
  position: relative;
  margin-left: 5%;
}
.adhaar_box {
  position: absolute;
  width: 431px;
  height: 160px;
  left: -13px;
  top: 31px;
  background: #ecf0f2;
  border: 1px dashed #b9c2cb;
}
.address_box {
  left: 3.5%;
  margin-top: -2%;
  position: absolute;
  width: 91.5%;
  height: 12%;
  background: #ecf0f2;
  border: 1px dashed #b9c2cb;
}
.Add_btn_3 {
  margin-top: 37px;
  position: relative;
  left: 6.6%;
}
.PrivateTabIndicator-colorSecondary-8 {
  background-color: #285295 !important;
}
#new_add_3 {
  background-color: #ecf0f2 !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border: 3px solid #285295 !important;
  position: relative;
  left: 0%;
  top: 45%;
}
#billing_toggle {
  margin-left: -40%;
  position: relative;
  top: -29%;
}

.identityProof_input {
  // margin-top: 20px;
  position: relative;
  left: -2.5%;
  top: -18px;
  width: 119%;
}
.terms_conditions {
  width: 212px;
  height: 27px;
  left: -27px;
  top: -22px;
  position: relative;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}
.sign_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.rdt_TableRow:hover {
  background: #e4edf7 !important;
  cursor: pointer !important;
}
.dGaxxA {
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
}

// .rdt_TableCell{
//   background-color: white !important;
//   z-index: 1;
// position: sticky !important;
// left: 0px !important;
// }

// sudha

.plan_name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #000000;
}

.plan_cost {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* or 250% */

  font-feature-settings: "rclt" off;

  color: #000000;
}

// buttons
.hwvYGl {
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  white-space: nowrap !important;
  left: 0px !important;
}

.dJWFGW {
  background-color: white !important;
  position: sticky !important;
  z-index: 1 !important;
  white-space: nowrap !important;
  left: 0px !important;
}
.addhaar-input {
  height: 32px;
  width: 287px;
  left: 0px;
  top: 0px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}

.pay_online {
  position: relative;
  top: 165px;
  left: 140px;
  z-index: 1;
}

.pay_offline {
  top: 165px;
  position: relative;
  right: 86%;
  z-index: 1;
}

// .input_search{
//   background-color: #495057 !important;
//   }
.reports_text {
  text-align: left !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  font-feature-settings: "rclt" off;
  color: #333333;
  margin-left: 17%;
}
.reports_count {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  font-feature-settings: "rclt" off;

  color: #333333;
}

.ant-select-selector {
  height: 37px;
  border-radius: 3px;
  width: 100%;
}

// ticket filter design
.css-159ulw2-MuiPaper-root {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.PaddingStyle {
  padding-left: 30px !important;
 
}

.chartdata{
  position: relative;
  top: -30px;
}
.complaints_status {
  padding: 10px;
}

.complaints_font {
  // position: relative;
  // left: 30px;
  // bottom: 23px;
  font-size: 19px;
  font-weight: 400;
  color: #7c7676;
  // top: -27px;
}
// 1366 496

// @media screen and (max-width: 1580px) and (min-width: 1200px) {

// }

.documentsCustomerProofs {
  max-width: 100%;
}
.avatarProfilePicture {
  border-radius: 50%;
}
#columns_width1 {
  position: relative;
  right: 100px;
}
#columns_width2 {
  position: relative;
  right: 120px;
}

#columns_width {
  position: relative;
  right: 50px;
}
// lead
.lead_columns {
  right: 150px !important;
}
// global action

.action_Drop {
  position: relative;
  right: 25px;
}

#columns_alignment {
  position: relative;
  // right: 50px;
}
#columns_right {
  position: relative;
  right: 50px;
}
#columns_left {
  position: relative;
  left: 7px;
}
#columns_left_1 {
  position: relative;
  left: -4px;
}
#columns_right_zone{
  position: relative;
  left: -4px;
}
// #columns_alignment2{
//   position: relative;
// right: 100px;
// }
// #columns_alignment3{
//   position: relative;
//   right: 150px;
// }
// #columns_alignment4{
//   position: relative;
//   right: 150px;
// }
// #columns_alignment5{
//   position: relative;
//   right: 200px;
// }
// #columns_alignment6{
//   position: relative;
//   right: 250px;
// }
.ComplaintsChartLabels {
  font-size: 14px;
}

.bar_Chart_label tspan {
  font-size: 0px;
}

// body {
//   font-size: calc(0.75em + 1vmin) !important;
// }

@-ms-viewport {
  width: device-width;
}

// media queris for images

@media screen and (min-width: 992px) {
  //For Large Laptops
  .Header_img {
    width: 20px;
  }
}

@media screen and (min-width: 1280px) {
  //For Big TV's (HD Screens)
  .Header_img {
    width: 20px;
  }
}

@media screen and (min-width: 1920px) {
  //For Projectors or Higher Resolution Screens (Full HD)
  .Header_img {
    width: 20px;
  }
}
@media screen and (min-width: 3840px) {
  //For 4K Displays (Ultra HD)
  .Header_img {
    width: 20px;
  }

  // css for backdrop by Marieya
  // .backdrop{
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 50%;
  // // z-index: 10;
  // background-color: rgba(0,0,0,0.75);
  // }
}

// franchise columns

.franchise_columns {
  position: relative;
  right: 75px;
}
.franchise_columns1 {
  position: relative;
  right: 100px;
}

.adminuser_columns {
  position:relative;right:100px;
}

.form_layout{
  margin-top: 20px;
}
.form_heading{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
}

.user_moveup{
  position: relative;
  margin-top: -40px;
}
// Sailaja Data_Table_columns_Proper_Alignments on  Reports_Tickets _Screen line NO: 10742 to 10750
.align_left_column{
  position: relative;
  left:18%;
}

.align_right_column{
  position: relative;
  right:18%;
}

.switch_icon{
  position:"relative"!important;
  color:"#a6a6a6"!important;
}



// Sailaja's Modifications done
.tab-content{
  ~ .nav-tabs{
    &.border-tab1 {
      margin-bottom: 0;
      margin-top: 30px;
    }
  }
}
// Material style css
.border-tab1.nav-tabs{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  .nav-item{
    .nav-link{
      font-family: "Open Sans"!important;
      font-style: normal!important;
      font-weight: 600!important;
      font-size: 14px!important;
      line-height: 19px!important;
      color: #333333;
      text-align: center;
      padding:10px 25px;
      border: none;
      border-bottom: 2px solid $transparent-color;
      transition: 0.3s;
      &.active, &.show, &:focus {
        border-bottom: 3px solid #285295;
      }
    }
  }
}

#lead_dot{
  padding-top: 2px;
  padding-left: 19px;
}

.changePassword_modal{
margin-top: -12%;
}
.changePassword_modalBody{
  padding: 30px;
}
.avatar{
  position: relative;
  left: 20px;
  top: 15px;
}
#act_cols{
  padding: 5px;
  background-color: #eff4fb ;
  border-radius: 4px;
  width: 120%;
  position: relative;
  left: -10px;
}


@media screen and (max-width: 1800px) and (min-width: 1700px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
}

@media screen and (max-width: 1700px) and (min-width: 1600px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
}
@media screen and (max-width: 1600px) and (min-width: 1500px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
}
@media screen and (max-width: 1500px) and (min-width: 1479px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}
}
@media screen and (max-width: 1500px) and (min-width: 1479px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}
}
@media screen and (max-width: 1479px) and (min-width: 1440px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}
}
@media screen and (max-width: 1440px) and (min-width: 1400px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}
}
@media screen and (max-width: 1380px) and (min-width: 1360px) {
  .total_test_cust {
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
}
.total_test_cust_1{
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 10px !important;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}
}
@media screen and (max-width: 1400px) and (min-width: 1300px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    white-space: nowrap;
    padding-left: 2px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  white-space: nowrap;

  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    display: flex;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  display: flex;

  // position: relative;
  // top: 4px;
  // left: 10px;
}
.act_cols{
  padding: 4px;
}

}
@media screen and (max-width: 1000px) and (min-width: 900px) {
  .total_test_cust {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
}
.total_test_cust_1{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  // position: relative;
  // top: 4px;
  // left: 10px;
}
}
// @media  screen and (max-width: 1200px) and  (min-width: 992px){
// .col-new {
//   padding: 0px;
// }
// }


.col_new{
  padding: 4px;
}
.custom-card1{ 
  // padding-right: 30px;
  // padding-left: 40px;
  padding-top: 24px;
  padding-left: 19px;
  padding-right: 4px;

}

element.style {
  border-radius: 10px;
  flex: 0 0 100%;
  height: 100%;
}
@media (max-width: 1366px){
.custom-card1 {
  padding-left: 19px;
  padding-right: 4px;
}
}
@media (max-width: 1280px){
  .custom-card1 {
    padding-left: 19px;
    padding-right: 4px;
  }
  }
  @media (max-width: 1536px){
    .custom-card1 {
      padding-left: 19px;
      padding-right: 4px;
    }
    }

    .mt1{
    margin-top: 2%;
    }
    #mt10{
      margin-left: 8px;
    }
    .report-tabs{
    .css-1aquho2-MuiTabs-indicator{
      background-color: transparent;
    }
    }
    .btn-primary{
      background-color: #285295 !important;
    }

    .cust_input_mt10{
      margin-top: 10%;
    }

    #mt9{
      margin-top: 9px;
    }
    #mt20{
      margin-top: 20px;
    }

    #leads_filter{
    position: relative;
    top: 25px;
    left: -14%;
    }
    #cust_details{
      height: 38px;
    }
    .cust_details_pwd{
      height:35px;
      width: 200px;
    }
    // #franchise_filter{
    // position: relative;
    // left: -188%;
    // top: 31px;
    // }
// css by Marieya
#breadcrumb_table{
  margin-top: -18px !important;
}

#breadcrumb_margin{
  position: relative !important;
  top:6px !important;
}
//changed the color for qualified lead color by Marieya 
.figma_circle {
  height: 14px;
  width: 14px;
  background-color: #07f12e;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 5px;
}
#error_pwd{
  position: relative;
  left: -22%;
}
#error_pwd1{
  position: relative;
  left: -26%;
}
#eyeicon_alignment{
  margin-top: -2%;
}

.search_kyc{
  display: flex;
  align-items: center;
  padding: 0px;
  height: 38px;
  border: 1px solid #ced4da;
  box-shadow: none !important;
  position: relative ;
  left: 298%;
  top: 75px;
  z-index: 1;
  width:62%;
}
@media screen and (max-width: 1500px) and (min-width: 1200px) {
  .search_kyc{
    display: flex;
    align-items: center;
    padding: 0px;
    height: 38px;
    border: 1px solid #ced4da;
    box-shadow: none !important;
    position: relative ;
    left: 298%;
    top: 75px;
    z-index: 1;
    width:93%;
  }
}

#error_cust_photo{
position: relative;
top:-42px;
left:4%;
}
#error_id{
  margin-left:-30%;
  position:relative;
   top:6px;
   left:-19%;
}
// Sailaja fixed signature validation msg position on 3rd August
#error_sign{
position:relative;
top:24px;
left: -27%;
}
#error_address{
position:relative;
 right:25%;
 top:-15px;
}
#breadcrum_reports{
margin-top: 15px;
}
#breadcrum_reports1{
  margin-top: 15px;
  // margin-left: -15px;
  }
#report_fields{
  margin-top: 10px;
}
#customer_kpi{
  margin-top: 10px;
  margin-bottom: 17px;
}
#newSearch{
border-right: 1px solid #e0e0e0;
border-left: 1px solid #e0e0e0;
height: 38px;
padding-left: 10px;
}

#custom_dates_new{
  position: relative;
  top:23px;
}
#watchlist{
margin-top: -5%;
}
#watchlist1{
  margin-bottom: -7%;
  }
//end


// search drop down
.css-a88p61-MuiInputBase-root-MuiInput-root:after{
  border:none !important
}

.css-1480iag-MuiInputBase-root-MuiInput-root:after{
  border:none !important
}
.css-1wt0ykv::after{
  border:none !important
}
.css-ercmji::before{
  border:none !important
}
#network_margin{
  margin-left:21%;
  margin-top:-5%

}
.act_count{
  margin-left: 4%;
}
.act_count1{
  margin-left: 3%;
  z-index: 1;
}
.act_count2{
  margin-left: 7%;
  z-index: 1;

}
.act_count3{
  margin-left: 6%;
  z-index: 1;

}
.act_count5{
  margin-left: 5%;
  z-index: 1;
}
.act_count4{
  margin-left: 9%;
  z-index: 1;

}

.grid_container_align{
  text-align: center !important;
  padding: 0px !important;
}

#branchEdit{
  position:absolute !important;
  left: 7% !important;
  top:7px !important;
}
@media screen and(max-width: 1800px) and (min-width: 1700px){
  #network_margin{
    margin-left:24%;
  }
  .act_count3{
    margin-left: 6% !important;
  z-index: 1;
  } 
   .act_count2{
    margin-left: 9%;
  z-index: 1;
  }
  .act_count5{
    margin-left: 4%;
    z-index: 1;
  }
  .act_count4{
    margin-left: 10%;
    z-index: 1;
  }
}
//added media query for network counts by marieya
@media screen and(max-width: 1700px) and (min-width: 1600px){
  #network_margin{
    margin-left:26%;
  }
  .act_count3{
    margin-left: 12%;
  z-index: 1;
  }  .act_count2{
    margin-left: 9%;
  z-index: 1;
  }
  .act_count4{
    margin-left: 12%;
    z-index: 1;
  }
}
@media screen and(max-width: 1500px) and(min-width: 1200px){
  #branchEdit{
    position:absolute !important;
    left: 4% !important;
    top: 7px !important;;
  }
  .grid_container_align {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0px !important;
    position: relative;
    left: -35px;
  }
  #network_margin{
    margin-left:31%;
  }
  .act_count1 {
    margin-left: -6%;
    z-index: 1;
}
.act_count5 {
    margin-left: 5%;
    z-index: 1;
}
  .act_count3{
    margin-left: 7%;
  z-index: 1;
  }  .act_count2{
    margin-left: 8%;
  z-index: 1;
  }
  .act_count4{
    margin-left: 5%;
    z-index: 1;
  }
}
@media screen and(max-width: 2133.33px) and (min-width: 1920px){
  #network_margin{
    margin-left:20%;
  }
  .act_count3{
    margin-left: 5%;
  z-index: 1;
  }  .act_count2{
    margin-left: 7%;
  z-index: 1;
  }
  .act_count3{
    margin-left: 7%;
  z-index: 1;
  }  
  .act_count4{
    margin-left: 7%;
    z-index: 1;
  }
}
@media screen and(max-width: 1930px) and (min-width: 1900px){
  #network_margin{
    margin-left:21%;
  }
  .act_count3{
    margin-left: 5%;
  z-index: 1;
  }  .act_count2{
    margin-left: 6%;
  z-index: 1;
  }
  .act_count3{
    margin-left:5%;
  z-index: 1;
  }  
  .act_count5 {
    margin-left: 3%;
    z-index: 1;
}
  .act_count4{
    margin-left: 5%;
    z-index: 1;
  }
}
@media screen and(max-width: 1800px) and (min-width: 1700px){
  #network_margin{
    margin-left:24%;
  }
  .act_count3{
    margin-left: 9%;
  z-index: 1;
  }  .act_count2{
    margin-left: 6%;
    // background-color: red;
  z-index: 1;
  }
  .act_count4{
    margin-left: 5%;
    z-index: 1;
  }
}
@media screen and(max-width: 1700px) and (min-width: 1600px){
  #network_margin{
    margin-left:25%;
  }
  .act_count3{
    margin-left: 10%;
  z-index: 1;
  }  .act_count2{
    margin-left: 9%;
  z-index: 1;
  }
  .act_count4{
    margin-left: 10%;
    z-index: 1;
  }
}
//added css for network counts by Marieya
#network_counts{
margin-left: 24%;
margin-top: -5%;
}
.nas_count{
  margin-left: 4%;
  z-index: 1;
}
.css-ercmji::after{
  border:none !important 
}


.css-ttwr4n{
  background-color: white !important
}
//css by Marieya 
#colmoveup{
position: relative;
top: -20px;
}
#rowmoveup{
  position: relative;
  top: -10px;
}
#searchrow{
  margin-top: 10px;
  position: relative;
  top: -2px;
}
#searchnewbilling{
margin-left: 59%;
}
#daterange_newbilling{
  justify-content: unset;
  position: relative;
  top: -43px;
}
//css 
#nas_field{
margin-bottom: 2%;
}

@media screen and (max-width: 1350px) and (min-width: 1150px) {
#Nas_reset{
  margin-left: -12px;
}
}

// Sailaja CSS on 19th July
.sort_icon{
  position: relative;
  left:-72%
}

//css added by marieya for radius health check cancel btn
#radius_cancel{
  background-color: white !important;
  font-family: "Open Sans";
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  position: relative;
  left: -8px;
}

.total_kpi{
margin-left: 3px;
}
// css added by Marieya for communications module
#breadcrumb_margin_com{
position: relative !important;
top: 17px !important;
}
.comm_main{
  margin-top: 1%;
}
.comp_li{
  padding: 10px;
}
#tabs_alingment{
  margin-top: -44px !important;
}
#table_alignment{
  margin-top: -3%;
}
.broadcast{
color: #0052ac;
}

#comm_checkbox{
  position: relative;
  top: 33px;
}

#branchwallet{
  margin-top: 3%;
}
.css-dj18df.Mui-selected{
  background:#e4edf7;
}
.css-1aquho2-MuiTabs-indicator{
  background-color: none !important;
}
.network_reports{
  position: relative;
  top:-30px
}

#addingbutton_tab{
  white-space: nowrap;
  position: absolute;
  top: 75px;
  left: 80%;
}
//css added by Marieya for addsubplan
#new_modal{
  margin-top: 10px;
  position: relative;
  top: -31px;
}
#new_hr{
  position: relative;
  top: -35px;
}
// Sailaja added class  styles foe cancel icon on 10th August REF US-07(Globally)

// Sailaja added class  styles for cancel icon on 10th August REF US-07(Globally)

.cancel_icon{
  margin-top: 11px;
  margin-right: -3px;
}


// Sailaja added class  styles for New Button  on 12th August REF US-02 (Globally)
.new_btn{
  font-size: 16px;
   position: relative;
   left: -27%;
}
#cust_info{
  height:20rem !important;
}
#dashboard_offline{
  z-index: 1;
  margin-top: 2px;
}

.Edit_subplan{
  position: relative;
  top: -30px;
}
.form_panCard{
  position: absolute;
  margin-left: 100px !important;
  align-items: center !important;
  width: max-content;
}
//spinner css by Marieya on 25.8.22
#spinner{
  margin-left: -12px;
}
#activation_card{
margin-top: -7px;
}
//css added for leadsource details field by Marieya
#leadsource_details{
margin-top: 0%;
margin-bottom: -2%;
}

#becomeExpiry{
  color: rgb(24, 144, 255);
    cursor: pointer;
    position: relative;
    // left: 10px;
    background-color: #ffdf80;
    text-align: center;
    height: 21px;
    padding-top: 0px;
}

#alredyExpiry{
  color: rgb(24, 144, 255);
  cursor: pointer;
  float: right;
  position: relative;
  // right: 10px;
  background-color: #ffc6b3;
  text-align: center;
  height: 21px;
  padding-top: 0px;

}

.without::-webkit-datetime-edit-ampm-field {
  display: none !important;
}

input[type=time]::-webkit-clear-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0; 
}

 input[type=time]::-webkit-inner-spin-button,
 input[type=time]::-webkit-outer-spin-button {
 -webkit-appearance: none !important;
 -moz-appearance: none !important;
  appearance: none !important;
  margin: 0; 
 }

 
 #watchlist_size{
  margin-top:7%;
   }
   #soruce_close{
    text-align: end;
    font-size: larger;
    font-weight: 300;
   }
   #source_row{
    position: relative;
    top: -7px;
   }
   #table_id{
    padding-left: 41px;
   }
   #source_plans{
   position: relative !important;
   top: 15px !important;
   margin-left: 26px !important;
   }
   #text_accordion{
    position: relative; 
    top: -2px;
    margin-left: -2%;
   }
   
  
   h1{
    font-size: 42px;
    color: blue;
    margin: 0px;
    margin-bottom: 30px;
    }
    .accordion-simple > .active {  
   display :block
    }
    .according__faq .inactive{
      display: none;
    }
    .accordion__faq > div{
      background-color: white;
      margin-bottom: 20px;
      padding: 0px 15px;
      border-radius: 5px;
      cursor: pointer;
    }
    .accordion__title{
      color: #1565c0;
      padding-bottom:20px;
      font-weight: 500;
    }
    .accordion__faq-heading{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .accordion__faq-heading .active{
      color: beige;
    }
    .according__faq-heading h3{
      color: #1e265e;
      font-weight: 600;
    }
  

    //work notes css
    .call-chat-body {
      .chat-box {
        .people-list {
          ul {
            max-height: 550px;
            overflow: auto;
          }
        }
  
        overflow: hidden;
        height: 625px;
      }
    }

    .call-chat-body {
      .chat-box {
        overflow: hidden;
        height: 300px;
      }
    }

    
    .call-chat-body {
      .chat-box {
        overflow: auto;
        height: auto;
      }
    }


    .call-chat-body {
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-history {
              .caller-img {
                &.pl-0 {
                  padding-left: 15px !important;
                }
              }
            }
          }
        }
      }
    }
    #assign_to{
      position: relative;
      top:-36px;
    }

    .loadercenter{
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .text-overlap{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .nasBorder{
      border: 1px solid #e8e8e8 !important;
      }
      .ant-card-head {
        min-height: 48px;
        margin-bottom: -1px;
        padding: 0 24px;
        color: rgba(0,0,0,.85);
        font-weight: 500;
        font-size: 16px;
        background: transparent;
        // border-bottom: 1px solid #e8e8e8;
        border: none !important;
        border-radius: 2px 2px 0 0;
        zoom: 1;

    }
    .standardCard .ant-card-head {
      min-height: 25px;
      text-align: left;
      padding: 0 15px;
  }

  .standardCard .ant-card-head-title {
    font-size: 13px;
    padding: 5px 0;
    text-transform: capitalize;
    height: 30px;
    font-weight: 500;
}
.ant-card-body {
  min-height: 140px;
}
.card_color_background{
  background-color: #285295;
}
// map view 
.MapView_button{
  white-space: nowrap;
  font-size: medium;
  background-color: #285295 !important;
  border-radius: 6px;
}
.delete-font{
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}
.custom-modal-header .close {
  position: relative;
  top: -43px;
}
.advancedmaps-placepicker-marker {
  display: none !important;
}

.ant-select-selection-overflow{
  flex-wrap: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
@media only screen and (max-width: 600px) {
  .responsiveHeader{
    flex-direction:column !important;
  }
  .viewResult_responsive{
    flex-basis: 100% !important;
    max-width: 100% !important;
    padding: 20px 0px 0px !important;
  }
  .header-wrapper{
    height: 100px !important;
  }
  .page-wrapper.compact-wrapper .nav-right .nav-menus{
    margin-left: -25px !important
  }
  .profile-media .media-body {
    display: block !important;
}
// .page-header .header-wrapper .nav-right.right-header ul.profile-dropdown, .page-header .header-wrapper .nav-right.right-header ul.chat-dropdown, .page-header .header-wrapper .nav-right.right-header ul.notification-dropdown {
//   width: calc(100vw - 30px);
//   position: absolute !important;
//   top: 29px !important;
//   left: -37px !important;
// }
.nav-right .nav-menus {
  width: 75% !important;
}
.onhover-dropdown:hover .onhover-show-div {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  visibility: visible !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}
.customizer-contain.open, .customizer-contain-adminuser.open{
  width: 100% !important;
}
#headerheading {
  font-size: 16px !important;
}
.page-wrapper.compact-wrapper .left-header .mega-menu {
  top: 0px !important;
  right: 10px !important;
  padding-top: 0;
  width: 101% !important;
  // margin-top: -1px !important;
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar{
  font-size: 50px !important;
}
.new_btn{
  padding: 35px !important;
}
// .icofont-plus{
//   margin-left: -40px !important;
// }
.responsive_dashboard_card{
flex-direction:column !important;
}
.network_card_res{
  height: 93% !important;
}
.network_card_res_data{
  margin-top: -1px !important;
}
.total_test_cust{
  font-size: 9px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li svg {
  width: 19px !important;
  height: 19px !important;
  margin-top: 10px !important;
}
.global-search-dropdown{
  left: 18% !important;
}
#breadcrumb_margin{
  top:19px !important;
}
.customers_responsive_filters{
  flex-flow: wrap !important;
  margin-left: -8px !important;
  justify-content: flex-start !important;
}
}
.payment-gateway-options {
  margin-top: 20px;
}

.pcard-container {
  display: flex;
  flex-wrap: wrap;
}

.pcard {
  position: relative;
  border: 4px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  -webkit-transition: border-color 0.3s ease;
  box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.1);
  // transition: box-shadow 0.3s ease; /* Standard syntax */
  // -webkit-transition: box-shadow 0.3s ease; /* Safari and Chrome */
  // -moz-transition: box-shadow 0.3s ease; /* Firefox */
}

.pcard img {
  max-width: 100px;
  margin-bottom: 10px;
}

.pcard:hover {
  border-color: #285295; /* Highlight color for hovered card */
  border-width: 4px;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.2); 
}

.pcard.selected {
  border-color: #285295; /* Highlight color for selected card */
  border-width: 4px;
}
.ptick-mark {
  position: absolute;
  top: -6px;
  left: 2px;
  font-size: 24px;
  color: #285295;
}